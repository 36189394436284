import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Stack, Avatar, Box } from '@mui/material';

const baseURL = process.env.REACT_APP_HOST_API

DriverInformation.propTypes = {
    pickupDriversInformation: PropTypes.array.isRequired
}

export default function DriverInformation({pickupDriversInformation=[]}){
    const name = pickupDriversInformation[0]?.name || "";
    return(
        <Stack direction="column" spacing={4} sx={{p: 1, pt: 3 }}>

            {
                name === ""  ? (
                    <Typography variant="subtitle1">
                        This delivery does not yet have a driver assigned
                    </Typography> 
                ) : (
                    pickupDriversInformation.map((pickupDriverInformation)=>{
                        const { photos } = pickupDriverInformation;
                        const photoUrl = (!photos || photos.length < 1)? '': `${baseURL}${photos[0]}`;
                        return (
                            <React.Fragment key={pickupDriverInformation.id}>
    
                                <Stack direction="row" spacing={2}>
                                    <Avatar alt={pickupDriverInformation?.name || ""} sx={{ width: 48, height: 48 }} src={photoUrl}/>
                                    <Box sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}>
                                        <Typography variant="subtitle2">
                                            {pickupDriverInformation?.name || "-"}
                                        </Typography>
                                    </Box>
                                </Stack>
    
                                <Stack direction="row" spacing={8}>
                                    <Stack direction="column">
                                        <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                                            Phone no 
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            {pickupDriverInformation?.phoneNumber || "-"}
                                        </Typography>
                                    </Stack>
    
                                    <Stack direction="column">
                                        <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                                            ID NO
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            {pickupDriverInformation?.nationalId || "-"}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </React.Fragment>
                        )
                    })
                )
            }

        </Stack>
    )
}