import PropTypes from 'prop-types';
import { useState } from 'react';
import { deserialize } from "deserialize-json-api";
// import parse from 'autosuggest-highlight/parse';
// import match from 'autosuggest-highlight/match';

// @mui
import { InputAdornment, Popper, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
// @mui
// @form
import { useFormContext, Controller } from 'react-hook-form';
// @form
// @utils
import axios from '../../../../../../utils/axios';
// utils

// @components
import InputStyle from '../../../../../../components/InputStyle';
import Iconify from '../../../../../../components/Iconify';
import SkeletonInventory from '../components/SkelectonInventory';
// @components

const PopperStyle = styled((props) => <Popper placement="bottom-start" {...props} />)({
    width: '500px !important',
});

AutocompleteFreeSolo.propTypes = {
    label: PropTypes.string.isRequired,
    shipperId: PropTypes.string.isRequired,
    error: PropTypes.object.isRequired,
    focusstretch: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    field: PropTypes.object
}

let timeout;

export function AutocompleteFreeSolo({ field, label = "", width = 230,
    shipperId, focusstretch = 0, error = null
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const handleInputChange = async (value) => {
        if (value && value.trim().length > 0) {
            const pathName = `/api/v1/shipper/inventories/${shipperId}?search_val=${value}`;
            clearTimeout(timeout);
            setIsLoading(true);
            timeout = setTimeout(async () => {
                const response = await axios.get(pathName);
                const result = deserialize(response.data).data;
                setSearchResults(result);
                setIsLoading(false);
            })
        }
    }

    return (
        <Autocomplete {...field}
            id="free-solo-demo"
            freeSolo
            autoHighlight
            selectOnFocus
            PopperComponent={PopperStyle}
            options={searchResults}
            onChange={(event, value) => {
                field.onChange(value);
            }}
            onInputChange={(event, value) => {
                handleInputChange(value)
            }}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.description;
            }}
            filterOptions={(options, params) => {
                const { inputValue } = params;
                if (inputValue.trim() !== '') {
                    options.unshift({
                        description: `Add new "${inputValue}"`,
                        inputValue,
                        id: inputValue
                    });
                }
                return options;
            }}
            renderInput={(params) => (
                <InputStyle
                    {...params}
                    stretchStart={width}
                    focusstretch={focusstretch}
                    label={label}
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify icon={'eva:search-fill'}
                                    sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }}
                                />
                            </InputAdornment>
                        ),
                        size: "small"
                    }}
                />
            )}
            renderOption={(props, option) => {
                const { description, id, } = option;
                // const { inputValue } = params;
                // const matches = match(description, inputValue);
                // const parts = parse(description, matches);
                return (
                    <li {...props} key={id || description}>
                        {
                            isLoading ? (
                                [...Array(5)].map((row, index) => (
                                    <SkeletonInventory key={index} />
                                ))
                            ) : (
                                <Typography
                                    component="span"
                                    variant="subtitle2"
                                >
                                    {description}
                                </Typography>
                            )
                        }
                    </li>
                )
            }}
        />
    )
}

RHFInventoriesSolo.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    shipperId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    focusstretch: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    options: PropTypes.array.isRequired
}

export function RHFInventoriesSolo({ name, label, shipperId,
    width = 230, focusstretch = 0, ...other
}) {
    const methods = useFormContext();
    const { control } = methods;
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <AutocompleteFreeSolo
                    width={width} focusstretch={focusstretch}
                    label={label} field={field} error={error}
                    shipperId={shipperId}
                    {...other}
                />
            )}
        />
    )
}