import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Divider, Typography, Stack } from '@mui/material';

// components
import Iconify from '../../../../components/Iconify';
import { DialogAnimate } from '../../../../components/animate';

// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';

// ----------------------------------------------------------------------

const DialogStyle = styled(DialogAnimate)(({ theme }) => ({
  '& .MuiDialog-paper': {
    margin: 0,
    // [theme.breakpoints.up('md')]: {
    //   maxWidth: 'calc(100% - 48px)',
    //   maxHeight: 'calc(100% - 48px)',
    // },
  },
}));

ReturnToCustomerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
};

// ----------------------------------------------------------------------

export default function ReturnToCustomerModal({ open, handleClose, ...other }) {
  const navigate = useNavigate();
  return (
    <DialogStyle maxWidth="sm" {...other} open={open}>
      <Box sx={{ p: 4, maxWidth: 480, margin: 'auto' }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4" paragraph>
            Thank you for shipping with Senga!
          </Typography>

          {/* <OrderCompleteIllustration sx={{ height: 150, my: 10 }} /> */}

          <Typography align="left" paragraph>
            Your items in your delivery to Naivas Likoni have been scheduled for return. &nbsp;
          </Typography>

          <Typography align="left" sx={{ color: 'text.secondary' }}>
            {/* The original delivery will be modified to reflect the new change. You can view all the items marked for */}
            {/* return in the Return items section. */}
            {/* <br /> */}
            {/* You will be notified when a new return trip to your premises is scheduled. */}
            {/* <br /> <br /> If you have any questions or queries then feel free to contact us. <br /> <br /> All the best, */}
            <br /> Senga. Ship Happy!
          </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Stack direction={{ xs: 'column-reverse', sm: 'row' }} justifyContent="space-between" spacing={2}>
          <Button variant="contained" onClick={handleClose} color="inherit">
            Close
          </Button>
        </Stack>
      </Box>
    </DialogStyle>
  );
}
