import PropTypes from 'prop-types';
import { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Stack } from '@mui/material';
import BetaChipStyle from './BetaChipStyle';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  version: PropTypes.string,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, version = '', sx }) {

  const logoPath = `/logo/logo_full.png`;
  const logo =
    version === 'beta' ? (
      <Stack direction="row" spacing={1} sx={{ display: 'flex' }}>
        <Box component="img" src={logoPath} sx={{ width: 120, height: 60, cursor: 'pointer', ...sx }} />
        <Box>
          <BetaChipStyle
            label="Beta"
            size="small"
            variant="outlined"
            sx={{
              '& .MuiChip-label': {
                overflow: 'visible',
                fontSize: '1rem',
              },
            }}
          />
        </Box>
      </Stack>
    ) : (
      <Box component="img" src={logoPath} sx={{ width: 120, height: 60, cursor: 'pointer', ...sx }} />
    );
  return disabledLink ? logo : <RouterLink to="/">{logo}</RouterLink>;
}
