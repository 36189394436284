
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent, TimelineOppositeContent } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { getReturnDocReferenceStatusTimeline } from '../../redux/slices/returnStatusTimeline'
import { fDateTime } from '../../utils/formatTime'
import { returnStatusColor } from '../../utils/getReturnStatusColour'

/**
 * 
 * @returns ReturnItemTimelineView
 * 
 * Captures the different status changes a return item undergoes
 * 
*/
export default function ReturnItemTimelineView() {
    const { id } = useParams()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getReturnDocReferenceStatusTimeline({ returnDocumentReferenceID: id }))
    }, [dispatch, id])

    const { returnDocumentReferenceStatusTimeline } = useSelector(state => state.returnDocumentReferenceStatusTimeline);

    return (
        <Box
            display={"flex"}
            justifyContent={"start"}
        >
            <Timeline position='alternate'>
                {
                    returnDocumentReferenceStatusTimeline.map((st) => (
                        <TimelineItem key={st.timeLineID}>
                            <TimelineOppositeContent color="text.secondary">
                                <Typography noWrap variant="caption">
                                    {fDateTime(st.updateTime)}
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color={returnStatusColor(st.status)} />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography paragraph variant="body2">
                                    {st.status}
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                    ))
                }
            </Timeline>
        </Box>
    )
}
