/*
 *
 * 
 * Actions & Reducers for the customer graph
 * 
 *  
*/
import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios"
import { dispatch } from "../store";
import { fDateForPersistence} from  "../../utils/formatTime";


// Initial state for the shipper analytics
const initialState = {
    isLoading: false,
    error: null,
    customerTreeData: [],
    offloadingMetrics: []
};

// Shipper Returns slice
const slice = createSlice({
    name: 'shipperAnalytics', 
    initialState,
    reducers: {
        // Start Loading
        startLoading(state) {
            state.isLoading = true
        },

        // Check for errors
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        
        getCustomerTreeDataSuccess(state, action) {
            state.isLoading = false
            state.customerTreeData= action.payload
        },

        getOffloadingMetricsSuccess(state, action){
            state.isLoading = false
            state.offloadingMetrics = action.payload
        }
    }
})

// Reducer for the shipper analytics
export default slice.reducer

// Action used to fetch shipper customer data
export function getCustomerTreeData({fromDate, toDate, shipperID }) {
    return async () => {
        dispatch(slice.actions.startLoading())
        
        // Initialize the date regardless
        // Updte the from date if necessary
        if (fromDate === undefined) {
            const _fromDate = new Date()
            const daysDifference = 90
            _fromDate.setDate(_fromDate.getDate() - daysDifference)
            fromDate = fDateForPersistence(_fromDate)
            
        }
        
        if (toDate === undefined) {
            const _toDate = Date.now()
            toDate = fDateForPersistence(_toDate)
            
        }

     
        try {
            
            // Path to return docs
            // const pathName = `api/v1/shipper/analytics/customer_tree/${shipperID}`
            const pathName = `api/v1/shipper/analytics/customer_tree/${shipperID}?start_date=${fromDate}&end_date=${toDate}`
            // Execute the get request
            const response = await axiosInstance.get(pathName)
            // Reshape the response
            // Dispatch the response
            dispatch(slice.actions.getCustomerTreeDataSuccess(response.data))
            
          
        } catch(err) {
           dispatch(slice.actions.hasError(err))
        }
    }
}

export function getOffloadingMetrics({shipperId, fromDate, toDate }) {
    return async () => {
        dispatch(slice.actions.startLoading())
     
        try {

            const pathName = `api/v1/shipper/analytics/offloading_metrics/${shipperId}?start_date=${fromDate}&end_date=${toDate}`
            // Execute the get request
            const response = await axiosInstance.get(pathName)
            dispatch(slice.actions.getOffloadingMetricsSuccess(response.data))
        } catch(err) {
           dispatch(slice.actions.hasError(err))
        }
    }
}
