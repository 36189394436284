import PropTypes from 'prop-types';
import { useMemo } from 'react';
// @mui
import { Box, Typography, Link } from '@mui/material';
// ------------
// hooks
import useAuth from '../hooks/useAuth';
// ------
// Components
import Breadcrumbs from './Breadcrumbs';
import ShipperSearch from './input/ShipperSearch'
// ----------------------------------------------------------------------

HeaderBreadcrumbs.propTypes = {
  links: PropTypes.array,
  action: PropTypes.node,
  heading: PropTypes.string.isRequired,
  moreLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sx: PropTypes.object,
};

export default function HeaderBreadcrumbs({ links, action, heading, moreLink = '' || [], sx, ...other }) {
  const auth = useAuth();
  const { setSelectedShipper, user, formatShipper } = auth;
  const showSearchShipper = useMemo(() => user?.roles?.find((role) => role.name === 'Admin'), [user?.roles]);

  const recentShipper = useMemo(()=>({
    shipperId: user.shipperId, 
    shipperNo: user.shipperNo,
    shipperName: user.companyName
  }), [user]);

  return (
    <Box sx={{ mb: 5, ...sx }}>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap'  }}>

        <Box>
          <Typography variant="h4" gutterBottom>
            {heading}
          </Typography>
          <Breadcrumbs links={links} {...other} />
        </Box>

        <Box>
          {
            showSearchShipper && (
              <ShipperSearch recentShipper={recentShipper} setShipper={setSelectedShipper} formatShipper={formatShipper}/>
            )
          }
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>

      <Box sx={{ mt: 2 }}>
        {typeof moreLink === 'string' ? (
          <Link href={moreLink} target="_blank" rel="noopener" variant="body2">
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}
