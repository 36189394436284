
import { createSlice } from '@reduxjs/toolkit';
import { deserialize } from 'deserialize-json-api';
// utils
import axios from '../../utils/axios';

import { dispatch } from '../store';


// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  bannerMsgs: [],
};

const slice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BANNER
    getBannerSuccess(state, action) {
      state.isLoading = false;
      state.bannerMsgs = action.payload
    },
  },
});


// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;



// create feedback
export function getBannerMessages( shipperId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const path = `banners_list/${shipperId}`;
      const response = await axios.get(path);
      const bannerObjs = deserialize(response.data).broadcasts.map((broadcast) => ({
        id: broadcast.id,
        message: broadcast.message,
      }));
      dispatch(slice.actions.getBannerSuccess(bannerObjs))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error();
    }
  };
}
