import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Table, TableBody, TableContainer, TablePagination } from '@mui/material'
import { useNavigate } from 'react-router'
import Scrollbar from '../../components/Scrollbar'
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSkeleton } from '../../components/table'
import useTable, { emptyRows, getComparator } from '../../hooks/useTable'
import useTabs from '../../hooks/useTabs'
import { PATH_DASHBOARD } from '../../routes/paths'
import { fDateTime } from '../../utils/formatTime'
import { ReturnItemRow } from '../../sections/@dashboard/tms/returns'


/**
 * 
 * @returns ReturnItemsTableView 
 * 
*/
export default function ReturnItemsTableView() {
    const navigate = useNavigate()

    // Get the related return items
    const { returnItems, isLoading } = useSelector(state => state.returnItems)
    // Table related functions
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        //
        selected,
        setSelected,
        //
        onSort,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable({ defaultOrderBy: 'createdAt', defaultRowsPerPage: 5 })

    const TABLE_HEAD = [
        { id: 'id', label: 'ID', align: 'left' },
        { id: 'Description', label: 'Description', align: 'left' },
        { id: 'Quantity', label: 'Quantity', align: 'left' },
        { id: 'Unit', label: 'Unit', align: 'left' },
        { id: 'Return Reason', label: 'Reason', align: 'left' },
        { id: 'createdAt', label: 'Date of Entry', align: 'left' },
        { id: '', label: 'Status', align: 'center' },
    ];

    // Dummy Table Data
    const [tableData, setTableData] = useState(returnItems)

    useEffect(() => {
        setTableData(returnItems)
    }, [returnItems])

    const [filterName] = useState('');

    const [filterStartDate] = useState(null);

    const [filterEndDate] = useState(null);

    const { currentTab: filterStatus } = useTabs('all');

    const handleDeleteRow = (orderId) => {
        const deleteRow = tableData.filter((row) => row.orderId !== orderId);
        setSelected([]);
        setTableData(deleteRow);
    };

    const handleEditRow = (orderId) => {
        navigate(PATH_DASHBOARD.tms.dnote.edit(orderId));
    };

    const handleViewRow = (orderId) => {
        navigate(PATH_DASHBOARD.tms.dnote.view(orderId));
    };

    const dataFiltered = applySortFilter({
        tableData,
        comparator: getComparator(order, orderBy),
        filterName,
        filterStatus,
        filterStartDate,
        filterEndDate,
    });

    const isNotFound =
        (!dataFiltered.length && !!filterName) ||
        (!dataFiltered.length && !!filterStatus) ||
        (!dataFiltered.length && !!filterEndDate) ||
        (!dataFiltered.length && !!filterStartDate);

    const denseHeight = dense ? 56 : 76;

    return (
        <Box
            sx={{
                width: "100%",
                marginTop: "16px",
            }}
        >
            <Scrollbar>
                <TableContainer sx={{ width: "100%", position: 'relative' }}>
                    <Table>
                        {/** The Table Header */}
                        <TableHeadCustom
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={tableData.length}
                            numSelected={selected.length}
                            onSort={onSort}
                        />

                        {/** Return Items Table Body */}
                        <TableBody>
                            {
                                (isLoading ? [...Array(5)] : dataFiltered)
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => (
                                        row ? (
                                            <ReturnItemRow
                                                key={row.id}
                                                row={row}
                                                onViewRow={() => handleViewRow(row.orderId)}
                                                onEditRow={() => handleEditRow(row.orderId)}
                                                onDeleteRow={() => handleDeleteRow(row.orderId)}
                                            />
                                        ) : (
                                            <TableSkeleton key={index} sx={{ height: denseHeight }} />
                                        )
                                    ))
                            }

                            <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />

                            <TableNoData isNotFound={isNotFound} title={"No Return Items"} />
                        </TableBody>
                    </Table>
                </TableContainer>
            </Scrollbar>

            {/** Pagination */}
            <Box sx={{ position: 'relative' }}>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={dataFiltered.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                    style={{ display: "flex" }}
                />
            </Box>
        </Box>
    )
}


function applySortFilter({ tableData, comparator, filterName, filterStatus, filterStartDate, filterEndDate }) {
    const stabilizedThis = tableData.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    tableData = stabilizedThis.map((el) => el[0]);

    if (filterName) {
        tableData = tableData.filter(
            (item) =>
                item.customer.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
                item.dnoteNo.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
        );
    }

    if (filterStatus !== 'all') {
        tableData = tableData.filter((item) => item.dnoteStatus === filterStatus);
    }

    if (filterStartDate && filterEndDate) {
        if (filterStartDate > filterEndDate) {
            const temp = filterStartDate;
            filterStartDate = filterEndDate;
            filterEndDate = temp;
        }
        if (filterStartDate === filterEndDate) {
            // known bug: Doesn't filter well if both start and end are the same
            tableData = tableData.filter((item) => item.pickupDate === filterStartDate);
        }
        if (filterStartDate < filterEndDate) {
            tableData = tableData.filter(
                (item) =>
                    fDateTime(item.pickupDate) >= fDateTime(filterStartDate) &&
                    fDateTime(item.deliveryDate) <= fDateTime(filterEndDate)
            );
        }
    }

    return tableData;
}
