import _mock from './_mock';

export const _returnsOverview = [
  {
    status: 'Collected by a representative',
    description: 'routed',
    quantity: 23,
    value: 23,
  },
  {
    status: 'Returned to your premises',
    description: 'returned',
    quantity: 12,
    value: 12,
  },
  {
    status: 'Completed Successfully',
    description: 'completed',
    quantity: 94,
    value: 94,
  },
];

export const _returnsItems = [
  {
    item_id: 1,
    description: 'CRISPS - HEROES - CHEESE & ONION 40G PKT',
    quantity: 7,
    unit: 'PKTS',
    customer: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
      name: 'Total Narumoru',
      address: 'Total Naro Moru Service Station, A2, Naro Moru, Kenya',
    },
    pickupDate: 'Thu Feb 14 2023 12:35:46 GMT+0300 (East Africa Time)',
    deliveryDate: 'Thu Feb 16 2023 12:35:46 GMT+0300 (East Africa Time)',
    shipperId: '61',
    orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
    dnoteNo: 'S0150918',
    returnReason: 'Damaged',
    isInOffice: false,
    // switch custodian: if isInOffice is true then senga office else use customer object
    currentCustodian: 'Total Narumoru',
    custodianType: 'customer',
    grnId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  },
  {
    item_id: 2,
    description: 'CRISPS-SALT & VINEGAR 100 GMS PKT',
    quantity: 6,
    unit: 'PKTS',
    customer: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
      name: 'Naivas Likoni',
      address: 'Naivas Likoni, Likoni, Kenya',
    },
    pickupDate: 'Thu Feb 14 2023 12:35:46 GMT+0300 (East Africa Time)',
    deliveryDate: 'Thu Feb 18 2023 12:35:46 GMT+0300 (East Africa Time)',
    shipperId: '61',
    orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
    dnoteNo: 'S0150923',
    returnReason: 'Oversupply',
    currentCustodian: 'Naivas Likoni',
    custodianType: 'customer',
    grnId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  },
  // {
  //   item_id: 3,
  //   description: 'POTATO STICKS - MASALA STICKS 200 GMS PKT',
  //   quantity: 45,
  //   unit: 'PKTS',
  //   customer: {
  //     id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
  //     name: 'Naivas Embu Pearl Center',
  //     address: 'Naivas Embu Pearl Center, Embu, Kenya',
  //   },
  //   pickupDate: 'Thu Feb 14 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   deliveryDate: 'Thu Feb 19 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   shipperId: '61',
  //   orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  //   dnoteNo: 'S0150965',
  //   returnReason: 'Expired LPO',
  //   currentCustodian: 'Senga Office',
  //   custodianType: 'Senga Office',
  //   grnId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  // },
  // {
  //   item_id: 4,
  //   description: 'CRISPS TOMATO 200 GMS PKT',
  //   quantity: 36,
  //   unit: 'PKTS',
  //   customer: {
  //     id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
  //     name: 'Naivas Embu Pearl Center',
  //     address: 'Naivas Embu Pearl Center, Embu, Kenya',
  //   },
  //   pickupDate: 'Thu Feb 14 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   deliveryDate: 'Thu Feb 19 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   shipperId: '61',
  //   orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  //   dnoteNo: 'S0150965',
  //   returnReason: 'Expired LPO',
  //   currentCustodian: 'Senga Office',
  //   custodianType: 'Senga Office',
  //   grnId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  // },
  // {
  //   item_id: 5,
  //   description: 'CRISPS - SALTED 200 GMS PKT',
  //   quantity: 24,
  //   unit: 'PKTS',
  //   customer: {
  //     id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
  //     name: 'Naivas Embu Pearl Center',
  //     address: 'Naivas Embu Pearl Center, Embu, Kenya',
  //   },
  //   pickupDate: 'Thu Feb 14 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   deliveryDate: 'Thu Feb 19 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   shipperId: '61',
  //   orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  //   dnoteNo: 'S0150965',
  //   returnReason: 'Expired LPO',
  //   currentCustodian: 'Senga Office',
  //   custodianType: 'Senga Office',
  //   grnId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  // },
  // {
  //   item_id: 6,
  //   description: 'CRISPS - SALT & VINEGAR 200 GMS PKT',
  //   quantity: 48,
  //   unit: 'PKTS',
  //   customer: {
  //     id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
  //     name: 'Naivas Embu Pearl Center',
  //     address: 'Naivas Embu Pearl Center, Embu, Kenya',
  //   },
  //   pickupDate: 'Thu Feb 14 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   deliveryDate: 'Thu Feb 19 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   shipperId: '61',
  //   orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  //   dnoteNo: 'S0150965',
  //   returnReason: 'Expired LPO',
  //   currentCustodian: 'Senga Office',
  //   custodianType: 'Senga Office',
  //   grnId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  // },
  // {
  //   item_id: 7,
  //   description: 'CRISPS - HEROES- SALTED 200 GMS PKT',
  //   quantity: 48,
  //   unit: 'PKTS',
  //   customer: {
  //     id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
  //     name: 'Naivas Embu Pearl Center',
  //     address: 'Naivas Embu Pearl Center, Embu, Kenya',
  //   },
  //   pickupDate: 'Thu Feb 14 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   deliveryDate: 'Thu Feb 19 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   shipperId: '61',
  //   orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  //   dnoteNo: 'S0150965',
  //   returnReason: 'Expired LPO',
  //   currentCustodian: 'Senga Office',
  //   custodianType: 'Senga Office',
  //   grnId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  // },
  // {
  //   item_id: 8,
  //   description: 'POTATO STICKS - MASALA STICKS 200 GMS PKT',
  //   quantity: 45,
  //   unit: 'PKTS',
  //   customer: {
  //     id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
  //     name: 'Naivas Nakuru DownTown',
  //     address: 'Naivas Supermarket Nakuru Downtown, BENKO FRESH &JUICY, Oginga Odinga Ave, Nakuru, Kenya',
  //   },
  //   pickupDate: 'Thu Feb 14 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   deliveryDate: 'Thu Feb 22 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   shipperId: '61',
  //   orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  //   dnoteNo: 'S0150965',
  //   returnReason: 'Damaged',
  //   currentCustodian: 'Senga Office',
  //   custodianType: 'Senga Office',
  //   grnId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  // },
  // {
  //   item_id: 9,
  //   description: 'CRISPS TOMATO 200 GMS PKT',
  //   quantity: 48,
  //   unit: 'PKTS',
  //   customer: {
  //     id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
  //     name: 'Naivas Nakuru DownTown',
  //     address: 'Naivas Supermarket Nakuru Downtown, BENKO FRESH &JUICY, Oginga Odinga Ave, Nakuru, Kenya',
  //   },
  //   pickupDate: 'Thu Feb 14 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   deliveryDate: 'Thu Feb 22 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   shipperId: '61',
  //   orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  //   dnoteNo: 'S0150965',
  //   returnReason: 'Expired LPO',
  //   currentCustodian: 'Senga Office',
  //   custodianType: 'Senga Office',
  //   grnId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  // },
  // {
  //   item_id: 10,
  //   description: 'POTATO STICKS - MASALA STICKS 200 GMS PKT',
  //   quantity: 45,
  //   unit: 'PKTS',
  //   customer: {
  //     id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
  //     name: 'Soc Maua',
  //     address: 'Naivas Embu Pearl Center, Embu, Kenya',
  //   },
  //   pickupDate: 'Thu Feb 14 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   deliveryDate: 'Thu Feb 16 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   shipperId: '61',
  //   orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  //   dnoteNo: 'S0150965',
  //   returnReason: 'Expired LPO',
  //   currentCustodian: 'Senga Office',
  //   custodianType: 'Senga Office',
  //   grnId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  // },
  // {
  //   item_id: 11,
  //   description: 'POTATO STICKS - MASALA STICKS 200 GMS PKT',
  //   quantity: 48,
  //   unit: 'PKTS',
  //   customer: {
  //     id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
  //     name: 'Season Energy',
  //     address: 'Naivas Embu Pearl Center, Embu, Kenya',
  //   },
  //   pickupDate: 'Thu Feb 14 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   deliveryDate: 'Thu Feb 22 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   shipperId: '61',
  //   orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  //   dnoteNo: 'S0150965',
  //   returnReason: 'Oversupply',
  //   currentCustodian: 'Season Energy',
  //   custodianType: 'Senga Office',
  //   grnId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  // },
  // {
  //   item_id: 12,
  //   description: 'CRISPS - HEROES- SALTED 200 GMS PKT',
  //   quantity: 45,
  //   unit: 'PKTS',
  //   customer: {
  //     id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
  //     name: 'Season Energy',
  //     address: 'Naivas Embu Pearl Center, Embu, Kenya',
  //   },
  //   pickupDate: 'Thu Feb 14 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   deliveryDate: 'Thu Feb 22 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   shipperId: '61',
  //   orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  //   dnoteNo: 'S0150965',
  //   returnReason: 'Oversupply',
  //   currentCustodian: 'Season Energy',
  //   custodianType: 'Senga Office',
  //   grnId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  // },
  // {
  //   item_id: 14,
  //   description: 'POTATO STICKS - MASALA STICKS 200 GMS PKT',
  //   quantity: 45,
  //   unit: 'PKTS',
  //   customer: {
  //     id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
  //     name: 'Majid Diani',
  //     address: 'Naivas Embu Pearl Center, Embu, Kenya',
  //   },
  //   pickupDate: 'Thu Feb 14 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   deliveryDate: 'Thu Feb 23 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   shipperId: '61',
  //   orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  //   dnoteNo: 'S0150965',
  //   returnReason: 'Invalid LPO',
  //   currentCustodian: 'Majid Diani',
  //   custodianType: 'Senga Office',
  //   grnId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  // },
  // {
  //   item_id: 15,
  //   description: 'CRISPS - SALTED 200 GMS PKT',
  //   quantity: 45,
  //   unit: 'PKTS',
  //   customer: {
  //     id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
  //     name: 'Majid Diani',
  //     address: 'Naivas Embu Pearl Center, Embu, Kenya',
  //   },
  //   pickupDate: 'Thu Feb 14 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   deliveryDate: 'Thu Feb 23 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   shipperId: '61',
  //   orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  //   dnoteNo: 'S0150965',
  //   returnReason: 'Invalid LPO',
  //   currentCustodian: 'Majid Diani',
  //   custodianType: 'Senga Office',
  //   grnId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  // },
  // {
  //   item_id: 16,
  //   description: 'CRISPS - SALT & VINEGAR 200 GMS PKT',
  //   quantity: 45,
  //   unit: 'PKTS',
  //   customer: {
  //     id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
  //     name: 'Majid Diani',
  //     address: 'Naivas Embu Pearl Center, Embu, Kenya',
  //   },
  //   pickupDate: 'Thu Feb 14 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   deliveryDate: 'Thu Feb 23 2023 12:35:46 GMT+0300 (East Africa Time)',
  //   shipperId: '61',
  //   orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  //   dnoteNo: 'S0150965',
  //   returnReason: 'Invalid LPO',
  //   currentCustodian: 'Majid Diani',
  //   custodianType: 'Senga Office',
  //   grnId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
  // },
];

export const _returnTrips = [
  {
    id: 'r99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
    deliveryDate: 'Tue Feb 28 2023 12:35:46 GMT+0300 (East Africa Time)',
    status: 'Approved',
    rnoteNo: 'R0150918',
    images: [
      {
        path: 'Total Narumoru - Signed Delivery note',
        preview: 'https://i.postimg.cc/ZRjv17qP/IMG-20230104-123732-551.jpg',
      },
      {
        path: 'Naivas Likoni - Goods Returned Note',
        preview: 'https://i.postimg.cc/FzXsh5wk/IMG-20230307-103133-521.jpg',
      },
    ],
    outlets: [
      {
        outletName: 'Total Narumoru',
        address: 'Total Naro Moru Service Station, A2, Naro Moru, Kenya',
        items: [
          {
            item_id: 1,
            description: 'CRISPS - HEROES - CHEESE & ONION 40G PKT',
            quantity: 34,
            unit: 'PKTS',
            returnReason: 'Damaged',
            deliveryDate: 'Thu Feb 16 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
          {
            item_id: 2,
            description: 'CRISPS-SALT & VINEGAR 100 GMS PKT',
            quantity: 36,
            unit: 'PKTS',
            returnReason: 'Oversupply',
            deliveryDate: 'Thu Feb 18 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
      {
        outletName: 'Naivas Likoni',
        address: 'Naivas Likoni, Likoni, Kenya',
        items: [
          {
            item_id: 3,
            description: 'POTATO STICKS - MASALA STICKS 200 GMS PKT',
            quantity: 45,
            unit: 'PKTS',
            returnReason: 'Expired LPO',
            deliveryDate: 'Thu Feb 19 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
    ],
  },
  {
    id: 'r99f09a7-dd88-49d5-b1c8-1daf80c2d7b33',
    deliveryDate: 'Tue Mar 02 2023 12:35:46 GMT+0300 (East Africa Time)',
    status: 'Completed',
    rnoteNo: 'R0150919',
    images: [
      {
        path: 'Total Narumoru - Signed Delivery note',
        preview: 'https://i.postimg.cc/ZRjv17qP/IMG-20230104-123732-551.jpg',
      },
      {
        path: 'Naivas Likoni - Goods Returned Note',
        preview: 'https://i.postimg.cc/FzXsh5wk/IMG-20230307-103133-521.jpg',
      },
    ],
    outlets: [
      {
        outletName: 'Total Narumoru',
        address: 'Total Naro Moru Service Station, A2, Naro Moru, Kenya',
        items: [
          {
            item_id: 1,
            description: 'CRISPS - HEROES - CHEESE & ONION 40G PKT',
            quantity: 34,
            unit: 'PKTS',
            returnReason: 'Damaged',
            deliveryDate: 'Thu Feb 16 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
          {
            item_id: 2,
            description: 'CRISPS-SALT & VINEGAR 100 GMS PKT',
            quantity: 36,
            unit: 'PKTS',
            returnReason: 'Oversupply',
            deliveryDate: 'Thu Feb 18 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
      {
        outletName: 'Naivas Likoni',
        address: 'Naivas Likoni, Likoni, Kenya',
        items: [
          {
            item_id: 3,
            description: 'POTATO STICKS - MASALA STICKS 200 GMS PKT',
            quantity: 45,
            unit: 'PKTS',
            returnReason: 'Expired LPO',
            deliveryDate: 'Thu Feb 19 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
    ],
  },
  {
    id: 'r99f09a7-dd88-49d5-b1c8-1daf80c2d7b34',
    deliveryDate: 'Tue Feb 26 2023 12:35:46 GMT+0300 (East Africa Time)',
    status: 'Approved',
    rnoteNo: 'R0150418',
    images: [
      {
        path: 'Total Narumoru - Signed Delivery note',
        preview: 'https://i.postimg.cc/ZRjv17qP/IMG-20230104-123732-551.jpg',
      },
      {
        path: 'Naivas Likoni - Goods Returned Note',
        preview: 'https://i.postimg.cc/FzXsh5wk/IMG-20230307-103133-521.jpg',
      },
    ],
    outlets: [
      {
        outletName: 'Total Narumoru',
        address: 'Total Naro Moru Service Station, A2, Naro Moru, Kenya',
        items: [
          {
            item_id: 1,
            description: 'CRISPS - HEROES - CHEESE & ONION 40G PKT',
            quantity: 34,
            unit: 'PKTS',
            returnReason: 'Damaged',
            deliveryDate: 'Thu Feb 16 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
          {
            item_id: 2,
            description: 'CRISPS-SALT & VINEGAR 100 GMS PKT',
            quantity: 36,
            unit: 'PKTS',
            returnReason: 'Oversupply',
            deliveryDate: 'Thu Feb 18 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
      {
        outletName: 'Naivas Likoni',
        address: 'Naivas Likoni, Likoni, Kenya',
        items: [
          {
            item_id: 3,
            description: 'POTATO STICKS - MASALA STICKS 200 GMS PKT',
            quantity: 45,
            unit: 'PKTS',
            returnReason: 'Expired LPO',
            deliveryDate: 'Thu Feb 19 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
    ],
  },
  {
    id: 'r99f09a7-dd88-49d5-b1c8-1daf80c2d7b35',
    deliveryDate: 'Tue Feb 25 2023 12:35:46 GMT+0300 (East Africa Time)',
    status: 'Approved',
    rnoteNo: 'R0150437',
    images: [
      {
        path: 'Total Narumoru - Signed Delivery note',
        preview: 'https://i.postimg.cc/ZRjv17qP/IMG-20230104-123732-551.jpg',
      },
      {
        path: 'Naivas Likoni - Goods Returned Note',
        preview: 'https://i.postimg.cc/FzXsh5wk/IMG-20230307-103133-521.jpg',
      },
    ],
    outlets: [
      {
        outletName: 'Total Narumoru',
        address: 'Total Naro Moru Service Station, A2, Naro Moru, Kenya',
        items: [
          {
            item_id: 1,
            description: 'CRISPS - HEROES - CHEESE & ONION 40G PKT',
            quantity: 34,
            unit: 'PKTS',
            returnReason: 'Damaged',
            deliveryDate: 'Thu Feb 16 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
          {
            item_id: 2,
            description: 'CRISPS-SALT & VINEGAR 100 GMS PKT',
            quantity: 36,
            unit: 'PKTS',
            returnReason: 'Oversupply',
            deliveryDate: 'Thu Feb 18 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
      {
        outletName: 'Naivas Likoni',
        address: 'Naivas Likoni, Likoni, Kenya',
        items: [
          {
            item_id: 3,
            description: 'POTATO STICKS - MASALA STICKS 200 GMS PKT',
            quantity: 45,
            unit: 'PKTS',
            returnReason: 'Expired LPO',
            deliveryDate: 'Thu Feb 19 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
    ],
  },
  {
    id: 'r99f09a7-dd88-49d5-b1c8-1daf80c2d7b36',
    deliveryDate: 'Tue Feb 24 2023 12:35:46 GMT+0300 (East Africa Time)',
    status: 'Approved',
    rnoteNo: 'R015634',
    images: [
      {
        path: 'Total Narumoru - Signed Delivery note',
        preview: 'https://i.postimg.cc/ZRjv17qP/IMG-20230104-123732-551.jpg',
      },
      {
        path: 'Naivas Likoni - Goods Returned Note',
        preview: 'https://i.postimg.cc/FzXsh5wk/IMG-20230307-103133-521.jpg',
      },
    ],
    outlets: [
      {
        outletName: 'Total Narumoru',
        address: 'Total Naro Moru Service Station, A2, Naro Moru, Kenya',
        items: [
          {
            item_id: 1,
            description: 'CRISPS - HEROES - CHEESE & ONION 40G PKT',
            quantity: 34,
            unit: 'PKTS',
            returnReason: 'Damaged',
            deliveryDate: 'Thu Feb 16 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
          {
            item_id: 2,
            description: 'CRISPS-SALT & VINEGAR 100 GMS PKT',
            quantity: 36,
            unit: 'PKTS',
            returnReason: 'Oversupply',
            deliveryDate: 'Thu Feb 18 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
      {
        outletName: 'Naivas Likoni',
        address: 'Naivas Likoni, Likoni, Kenya',
        items: [
          {
            item_id: 3,
            description: 'POTATO STICKS - MASALA STICKS 200 GMS PKT',
            quantity: 45,
            unit: 'PKTS',
            returnReason: 'Expired LPO',
            deliveryDate: 'Thu Feb 19 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
    ],
  },
  {
    id: 'r99f09a7-dd88-49d5-b1c8-1daf80c2d7b37',
    deliveryDate: 'Tue Feb 22 2023 12:35:46 GMT+0300 (East Africa Time)',
    status: 'Approved',
    rnoteNo: 'R0150896',
    images: [
      {
        path: 'Total Narumoru - Signed Delivery note',
        preview: 'https://i.postimg.cc/ZRjv17qP/IMG-20230104-123732-551.jpg',
      },
      {
        path: 'Naivas Likoni - Goods Returned Note',
        preview: 'https://i.postimg.cc/FzXsh5wk/IMG-20230307-103133-521.jpg',
      },
    ],
    outlets: [
      {
        outletName: 'Total Narumoru',
        address: 'Total Naro Moru Service Station, A2, Naro Moru, Kenya',
        items: [
          {
            item_id: 1,
            description: 'CRISPS - HEROES - CHEESE & ONION 40G PKT',
            quantity: 34,
            unit: 'PKTS',
            returnReason: 'Damaged',
            deliveryDate: 'Thu Feb 16 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
          {
            item_id: 2,
            description: 'CRISPS-SALT & VINEGAR 100 GMS PKT',
            quantity: 36,
            unit: 'PKTS',
            returnReason: 'Oversupply',
            deliveryDate: 'Thu Feb 18 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
      {
        outletName: 'Naivas Likoni',
        address: 'Naivas Likoni, Likoni, Kenya',
        items: [
          {
            item_id: 3,
            description: 'POTATO STICKS - MASALA STICKS 200 GMS PKT',
            quantity: 45,
            unit: 'PKTS',
            returnReason: 'Expired LPO',
            deliveryDate: 'Thu Feb 19 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
    ],
  },
  {
    id: 'r99f09a7-dd88-49d5-b1c8-1daf80c2d7b38',
    deliveryDate: 'Tue Feb 20 2023 12:35:46 GMT+0300 (East Africa Time)',
    status: 'Approved',
    rnoteNo: 'R0150234',
    images: [
      {
        path: 'Total Narumoru - Signed Delivery note',
        preview: 'https://i.postimg.cc/ZRjv17qP/IMG-20230104-123732-551.jpg',
      },
      {
        path: 'Naivas Likoni - Goods Returned Note',
        preview: 'https://i.postimg.cc/FzXsh5wk/IMG-20230307-103133-521.jpg',
      },
    ],
    outlets: [
      {
        outletName: 'Total Narumoru',
        address: 'Total Naro Moru Service Station, A2, Naro Moru, Kenya',
        items: [
          {
            item_id: 1,
            description: 'CRISPS - HEROES - CHEESE & ONION 40G PKT',
            quantity: 34,
            unit: 'PKTS',
            returnReason: 'Damaged',
            deliveryDate: 'Thu Feb 16 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
          {
            item_id: 2,
            description: 'CRISPS-SALT & VINEGAR 100 GMS PKT',
            quantity: 36,
            unit: 'PKTS',
            returnReason: 'Oversupply',
            deliveryDate: 'Thu Feb 18 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
      {
        outletName: 'Naivas Likoni',
        address: 'Naivas Likoni, Likoni, Kenya',
        items: [
          {
            item_id: 3,
            description: 'POTATO STICKS - MASALA STICKS 200 GMS PKT',
            quantity: 45,
            unit: 'PKTS',
            returnReason: 'Expired LPO',
            deliveryDate: 'Thu Feb 19 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
    ],
  },
  {
    id: 'r99f09a7-dd88-49d5-b1c8-1daf80c2d7b39',
    deliveryDate: 'Tue Mar 01 2023 12:35:46 GMT+0300 (East Africa Time)',
    status: 'Approved',
    rnoteNo: 'R0150112',
    images: [
      {
        path: 'Total Narumoru - Signed Delivery note',
        preview: 'https://i.postimg.cc/ZRjv17qP/IMG-20230104-123732-551.jpg',
      },
      {
        path: 'Naivas Likoni - Goods Returned Note',
        preview: 'https://i.postimg.cc/FzXsh5wk/IMG-20230307-103133-521.jpg',
      },
    ],
    outlets: [
      {
        outletName: 'Total Narumoru',
        address: 'Total Naro Moru Service Station, A2, Naro Moru, Kenya',
        items: [
          {
            item_id: 1,
            description: 'CRISPS - HEROES - CHEESE & ONION 40G PKT',
            quantity: 34,
            unit: 'PKTS',
            returnReason: 'Damaged',
            deliveryDate: 'Thu Feb 16 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
          {
            item_id: 2,
            description: 'CRISPS-SALT & VINEGAR 100 GMS PKT',
            quantity: 36,
            unit: 'PKTS',
            returnReason: 'Oversupply',
            deliveryDate: 'Thu Feb 18 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
      {
        outletName: 'Naivas Likoni',
        address: 'Naivas Likoni, Likoni, Kenya',
        items: [
          {
            item_id: 3,
            description: 'POTATO STICKS - MASALA STICKS 200 GMS PKT',
            quantity: 45,
            unit: 'PKTS',
            returnReason: 'Expired LPO',
            deliveryDate: 'Thu Feb 19 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
    ],
  },
  {
    id: 'r99f09a7-dd88-49d5-b1c8-1daf80c2d7b40',
    deliveryDate: 'Tue Feb 19 2023 12:35:46 GMT+0300 (East Africa Time)',
    status: 'Approved',
    rnoteNo: 'R0150786',
    images: [
      {
        path: 'Total Narumoru - Signed Delivery note',
        preview: 'https://i.postimg.cc/ZRjv17qP/IMG-20230104-123732-551.jpg',
      },
      {
        path: 'Naivas Likoni - Goods Returned Note',
        preview: 'https://i.postimg.cc/FzXsh5wk/IMG-20230307-103133-521.jpg',
      },
    ],
    outlets: [
      {
        outletName: 'Total Narumoru',
        address: 'Total Naro Moru Service Station, A2, Naro Moru, Kenya',
        items: [
          {
            item_id: 1,
            description: 'CRISPS - HEROES - CHEESE & ONION 40G PKT',
            quantity: 34,
            unit: 'PKTS',
            returnReason: 'Damaged',
            deliveryDate: 'Thu Feb 16 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
          {
            item_id: 2,
            description: 'CRISPS-SALT & VINEGAR 100 GMS PKT',
            quantity: 36,
            unit: 'PKTS',
            returnReason: 'Oversupply',
            deliveryDate: 'Thu Feb 18 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
      {
        outletName: 'Naivas Likoni',
        address: 'Naivas Likoni, Likoni, Kenya',
        items: [
          {
            item_id: 3,
            description: 'POTATO STICKS - MASALA STICKS 200 GMS PKT',
            quantity: 45,
            unit: 'PKTS',
            returnReason: 'Expired LPO',
            deliveryDate: 'Thu Feb 19 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
    ],
  },
  {
    id: 'r99f09a7-dd88-49d5-b1c8-1daf80c2d7b41',
    deliveryDate: 'Tue Feb 16 2023 12:35:46 GMT+0300 (East Africa Time)',
    status: 'Approved',
    rnoteNo: 'R0150999',
    images: [
      {
        path: 'Total Narumoru - Signed Delivery note',
        preview: 'https://i.postimg.cc/ZRjv17qP/IMG-20230104-123732-551.jpg',
      },
      {
        path: 'Naivas Likoni - Goods Returned Note',
        preview: 'https://i.postimg.cc/FzXsh5wk/IMG-20230307-103133-521.jpg',
      },
    ],
    outlets: [
      {
        outletName: 'Total Narumoru',
        address: 'Total Naro Moru Service Station, A2, Naro Moru, Kenya',
        items: [
          {
            item_id: 1,
            description: 'CRISPS - HEROES - CHEESE & ONION 40G PKT',
            quantity: 34,
            unit: 'PKTS',
            returnReason: 'Damaged',
            deliveryDate: 'Thu Feb 16 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
          {
            item_id: 2,
            description: 'CRISPS-SALT & VINEGAR 100 GMS PKT',
            quantity: 36,
            unit: 'PKTS',
            returnReason: 'Oversupply',
            deliveryDate: 'Thu Feb 18 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
      {
        outletName: 'Naivas Likoni',
        address: 'Naivas Likoni, Likoni, Kenya',
        items: [
          {
            item_id: 3,
            description: 'POTATO STICKS - MASALA STICKS 200 GMS PKT',
            quantity: 45,
            unit: 'PKTS',
            returnReason: 'Expired LPO',
            deliveryDate: 'Thu Feb 19 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
    ],
  },
  {
    id: 'r99f09a7-dd88-49d5-b1c8-1daf80c2d7b42',
    deliveryDate: 'Tue Feb 14 2023 12:35:46 GMT+0300 (East Africa Time)',
    status: 'Approved',
    rnoteNo: 'R0150002',
    images: [
      {
        path: 'Total Narumoru - Signed Delivery note',
        preview: 'https://i.postimg.cc/ZRjv17qP/IMG-20230104-123732-551.jpg',
      },
      {
        path: 'Naivas Likoni - Goods Returned Note',
        preview: 'https://i.postimg.cc/FzXsh5wk/IMG-20230307-103133-521.jpg',
      },
    ],
    outlets: [
      {
        outletName: 'Total Narumoru',
        address: 'Total Naro Moru Service Station, A2, Naro Moru, Kenya',
        items: [
          {
            item_id: 1,
            description: 'CRISPS - HEROES - CHEESE & ONION 40G PKT',
            quantity: 34,
            unit: 'PKTS',
            returnReason: 'Damaged',
            deliveryDate: 'Thu Feb 16 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
          {
            item_id: 2,
            description: 'CRISPS-SALT & VINEGAR 100 GMS PKT',
            quantity: 36,
            unit: 'PKTS',
            returnReason: 'Oversupply',
            deliveryDate: 'Thu Feb 18 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
      {
        outletName: 'Naivas Likoni',
        address: 'Naivas Likoni, Likoni, Kenya',
        items: [
          {
            item_id: 3,
            description: 'POTATO STICKS - MASALA STICKS 200 GMS PKT',
            quantity: 45,
            unit: 'PKTS',
            returnReason: 'Expired LPO',
            deliveryDate: 'Thu Feb 19 2023 12:35:46 GMT+0300 (East Africa Time)',
            orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
          },
        ],
      },
    ],
  },
];
