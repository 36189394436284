import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();

  const initPasswordChanged = useAuth()?.user?.initPasswordChanged;


  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);


  if (!isInitialized) {
    return <LoadingScreen />;
  }
  

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (!initPasswordChanged) {
    if (pathname !== requestedLocation) {

      setRequestedLocation(pathname);
    }
    return <Navigate to={"/auth/new-password"} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
