import dayjs from 'dayjs';
import { deserialize } from 'deserialize-json-api';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { dispatch } from '../store';

import { fDateCompact } from '../../utils/formatTime';

const initialState = {
  isLoading: false,
  error: null,
  sortBy: 'newestRequest',
  salesRepOrdersData: [],
  pickupDateRange: [],
  
  
};

const slice = createSlice({
  name: 'salesRepOrders',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    }, 
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    
    // GET ORDERS
    getSalesRepOrdersSuccess(state, action) {
      state.isLoading = false;
      state.salesRepOrdersData = state.isUpdated ? state.salesRepOrdersData : action.payload;
    },
    // SORT
    sortByDeliveries(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  sortByDeliveries,
  getSalesRepOrdersSuccess,
} = slice.actions;

// get order but with a different date filter
export function getSalesRepsOrders({ token }){ // filterPickupDateFrom, filterPickupDateEnd , searchVal}) {
  return async () => {
    try {
      let pathName = `/api/v1/sales_reps/deliveries_tracking?token=${token}`;
      const pickupStartDate = dayjs(new Date(new Date().setDate(new Date().getDate() - 30))).format('YYYY-MM-DD');
      const pickupEndDate = dayjs(new Date()).format('YYYY-MM-DD');
      pathName = `${pathName}&pickup_start_date=${pickupStartDate}&pickup_end_date=${pickupEndDate}`;
      dispatch(slice.actions.startLoading());
      const response = await axios.get(pathName);
      const { data } = response;

      const receivedData = deserialize(data).data.map((order) => ({
        customer: {
          name: order.customer_name,
          id: order.customer_id,
          address: order.customer_address,
        },
        numberOfItems: order.total,
        pickupDate: order.created_at,
        deliveryDate: order.expected_arrival_date,
        orderId: order.id.toString(),
        invoiceNo: order.invoice_no,
        lpoNo: order.lpo_no,
        dnoteNo: order.dnote_id,
        total: order.total,
        status: order.order_status[0] || 'scheduled',
        lpoDate: order.lpo_date || order.expected_arrival_date,
        orderlineItems: order.orderline_items,
        contactPerson: {
          name: order?.contact_person?.name || null,
          email: order?.contact_person?.email || null,
          phoneNumber: order?.contact_person?.phone_number || null,
        },
        orderNotes: order?.order_notes || [],
        totalWeightKg: order.total_weight_kg,
      }));

      dispatch(slice.actions.getSalesRepOrdersSuccess(receivedData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


