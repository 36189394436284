import axios from  '../../utils/axios'
import fileDownload from 'js-file-download'
import useAuth from '../../hooks/useAuth'
import moment from 'moment'
import { HOST_API } from '../../config'

export const downloadDocuments = async (fileUrl, user, ref, setLoading = () => {}) => {
    event.stopPropagation();
    // setLoading(true);
    const fileURLArray = fileUrl.split('.')
    const ext = fileURLArray[fileURLArray.length - 1]
    const name = `${user.companyName.replaceAll(' ', '_')}_${ref.replaceAll(' ', '_')}_(${moment().format('YYYY-D-M H:m:s')}).${ext}`
    try {
      const base_url = `${HOST_API}/api/v1/documents/download`;
  
      const response = await axios.post(base_url,
        { url: fileUrl },
        {responseType: "blob"},
      );
  
      const blob = new Blob([response.data], { type: response.headers["content-type"] });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = name || fileUrl.split("/").pop(); // Use provided name or extract from URL
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  }