import { createSlice } from '@reduxjs/toolkit';
import { deserialize } from 'deserialize-json-api';
import dayjs from 'dayjs';
// utils
import axios from '../../utils/axios';
import { HOST_API } from '../../config';
import { dispatch } from '../store';


const initialState = {
  isLoading: false,
  error: null,
  isUdpated: false,
  sortBy: 'newest',
  dnoteData: [],
  paperworkData: [],
  singleDnote: [],
};

// The is Updated field is only used to force the table to refresh when a dnote is updated,
// when making server calls for getting dnotes, this field will not be required since the data will be fetched from the server

const slice = createSlice({
  name: 'dnote',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DNOTES
    getDnotesSuccess(state, action) {
      state.isLoading = false;
      state.dnoteData = action.payload;
    },

    // GET DNOTE
    getDnoteSuccess(state, action) {
      state.isLoading = false;
      state.singleDnote = action.payload;
    },
    // sortByDnotes
    sortByDnotes(state, action) {
      state.sortBy = action.payload;
    },

    // ADD DNOTE
    addDnoteSuccess(state, action) {
      state.isLoading = false;
      state.dnoteData.push(action.payload);
    },
    // ADD DNOTE
    addOrderDocumentSuccess(state, action) {
      state.isLoading = false;
      state.dnoteData.push(action.payload);
    },
    // UPDATE DNOTE
    uploadPaperworkSuccess(state, action) {
      state.isLoading = false;
      state.isUdpated = true;
      state.paperworkData.push(action.payload)
      // console.log('state.dnoteData', state.dnoteData);
    },
    // UPDATE DNOTE
    updateDnoteSuccess(state, action) {
      state.isLoading = false;
      state.isUdpated = true;
      const newItem = { ...action.payload, status: action.payload.dnoteStatus };
      state.dnoteData = state.dnoteData.map((item) => (item.id === action.payload.id ? newItem : item));
      // console.log('state.dnoteData', state.dnoteData);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent, sortByDnotes } = slice.actions;

// ----------------------------------------------------------------------

export function getDnotes({ pickupDateFrom, pickupDateEnd, searchVal, shipperId }) {
  return async () => {
    try {
      let pathName = `/api/v1/dnotes/shipper?shipper_id=${shipperId}`;
      if (searchVal && searchVal.trim().length > 0) {
        pathName = `${pathName}&search_val=${searchVal}`
      } else if (pickupDateFrom) {
        const pickupStartDate = dayjs(pickupDateFrom).format('YYYY-MM-DD');
        const pickupEndDate = dayjs(pickupDateEnd).format('YYYY-MM-DD');
        pathName = `${pathName}&pickup_start_date=${pickupStartDate}&pickup_end_date=${pickupEndDate}`;
      } else {
        // minus four days. Default to current date minus four days
        const pickupStartDate = dayjs(new Date(new Date().setDate(new Date().getDate() - 4))).format('YYYY-MM-DD');
        const pickupEndDate = dayjs(new Date()).format('YYYY-MM-DD');
        pathName = `${pathName}&pickup_start_date=${pickupStartDate}&pickup_end_date=${pickupEndDate}`;
      }
      dispatch(slice.actions.startLoading());
      const response = await axios.get(pathName);
      const dnotes = deserialize(response.data, { transformKeys: 'camelCase' });
      const formattedDnotes = dnotes.data.map((dnote) => ({
        orderId: dnote.id,
        dnoteId: dnote.dnoteId,
        physicalReceivedOffice: dnote.physicalReceivedOffice,
        dropoffDnoteImages: dnote.dropoffDnoteImages,
        pickupDnoteImages: dnote.pickupDnoteImages,
        customer: {
          name: dnote.customerName,
          id: dnote.customerId,
        },
        dnoteNo: dnote.dnoteNumber,
        pickupDate: dnote.createdAt,
        deliveryDate: dnote.expectedArrivalDate,
        dnoteStatus: dnote.dnoteStatus,
        orderStatus: dnote.orderStatus,
      }));
      dispatch(slice.actions.getDnotesSuccess(formattedDnotes));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getShipperDnotes({ fromDate, toDate }) {
  return async () => {
    try {
      let pathName = '/api/v1/dnotes/shipper';
      // if the date_range is set
      if (fromDate && toDate) {
        const startDate = dayjs(fromDate).format('YYYY-MM-DD');
        const endDate = dayjs(toDate).format('YYYY-MM-DD');
        pathName = `${pathName}?range_start_date=${startDate}&range_end_date=${endDate}`;
      } else {
        // minus seven days. Default to current date minus four days
        const startDate = dayjs(new Date(new Date().setDate(new Date().getDate() - 7))).format('YYYY-MM-DD');
        const endDate = dayjs(new Date()).format('YYYY-MM-DD');
        pathName = `${pathName}?range_start_date=${startDate}&range_end_date=${endDate}`;
      }
      dispatch(slice.actions.startLoading());
      const response = await axios.get(pathName);
      const dnotes = deserialize(response.data, { transformKeys: 'camelCase' });
      const formattedDnotes = dnotes.data.map((dnote) => ({
        orderId: dnote.id,
        dnoteId: dnote.dnoteId,
        physicalReceivedOffice: dnote.physicalReceivedOffice,
        dropoffDnoteImages: dnote.dropoffDnoteImages,
        pickupDnoteImages: dnote.pickupDnoteImages,
        customer: {
          name: dnote.customerName,
          id: dnote.customerId,
        },
        dnoteNo: dnote.dnoteNumber,
        pickupDate: dnote.createdAt,
        deliveryDate: dnote.expectedArrivalDate,
        dnoteStatus: dnote.dnoteStatus,
        orderStatus: dnote.orderStatus,
      }));

      dispatch(slice.actions.getDnotesSuccess(formattedDnotes));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getDnote(orderId) {
  return async () => {
    try {
      const pathName = `/api/v1/orders_that_should_have_dnotes?order_id=${orderId}`;
      const response = await axios.get(pathName);
      const dnotes = deserialize(response.data, { transformKeys: "camelCase" });
      const formattedDnotes = dnotes.data.map((dnote) => ({
        orderId: dnote.id,
        dnoteId: dnote.dnoteId,
        physicalReceivedOffice: dnote.physicalReceivedOffice,
        dropoffDnoteImages: dnote.dropoffDnoteImages,
        images: dnote.pickupDnoteImages.concat(dnote.dropoffDnoteImages).map((image) => ({
          preview: `${HOST_API}${image?.url}`,
          path: dnote.dnoteNumber,
        })),
        pickupDnoteImages: dnote.pickupDnoteImages,
        customer: {
          name: dnote.customerName,
          id: dnote.customerId,
          address: dnote.customerAddress,
        },
        dnoteNo: dnote.dnoteNumber,
        dnoteStatus: dnote.dnoteStatus,
        pickupDate: dnote.createdAt,
        deliveryDate: dnote.expectedArrivalDate,
      }));
      dispatch(slice.actions.getDnoteSuccess(formattedDnotes));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addDnote(dnote) {
  return async () => {
    try {
      // const response = await axios.get('/api/calendar/events');
      const receivedData = dnote;
      dispatch(slice.actions.addDnoteSuccess(receivedData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function attachDnoteImages(orderId, dnoteData) {
  // eslint-disable-next-line consistent-return
  return async () => {
    try {
      const pathName = `/api/v1/dnotes/${orderId}`;
      const formDataDnotes = new FormData();
      dnoteData.dnote_images.forEach((blobImage) => {
        formDataDnotes.append('dnote_images[]', blobImage);
      });
      formDataDnotes.append('dnote_number', dnoteData.dnote_number);
      formDataDnotes.append('dnote_type', dnoteData.dnote_type);
      return axios.post(pathName, formDataDnotes, { headers: { 'content-type': 'multipart/form-data' } });
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateDnote(dnote) {
  return async () => {
    try {
      const receivedData = dnote;
      dispatch(slice.actions.updateDnoteSuccess(receivedData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}