import * as Yup from 'yup';

import PropTypes from 'prop-types';
import { useMemo } from 'react';

import {
    Dialog, IconButton, Box, Stack,
    Typography, DialogContent, DialogActions, Button
} from '@mui/material';
// hooks
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
// hooks
// components
import { FormProvider } from '../../../../../../components/hook-form';
import Iconify from '../../../../../../components/Iconify';
import EditAuthorizationFilesUpload from './EditAuthorizationFilesUpload';
// components

AuthorizationFilesUploadDialog.propTypes = {
    isOpenModal: PropTypes.bool,
    handleClose: PropTypes.func,
    handleSubmitAuthorizationFiles: PropTypes.func.isRequired
}
export default function AuthorizationFilesUploadDialog({
    isOpenModal, handleClose, handleSubmitAuthorizationFiles
}) {
    const { enqueueSnackbar } = useSnackbar();

    const AuthorizationFilesUploadSchema = Yup.object().shape({
        files: Yup.array()
            .min(1, 'Upload at least one document')
            .required('Please upload at least one file'),
        document: Yup.string().required("Please specify document name")
    })
    const defaultValues = useMemo(
        () => ({
            files: [],
            document: ""
        }),
        []
    );

    const methods = useForm({
        resolver: yupResolver(AuthorizationFilesUploadSchema),
        defaultValues,
    });

    const { handleSubmit, setValue, watch, reset } = methods;

    const onSubmit = async (data) => {
        try {
            handleSubmitAuthorizationFiles(data);
            handleClose();
            reset(defaultValues);
        } catch (error) {
            console.log(error);
            enqueueSnackbar('There was problem uploading authorization documents!', { variant: 'error' });
        }
    }

    const values = watch();

    const handleRemoveAll = () => {
        setValue('files', []);
    };

    const handleUploadFiles = () => {
        handleSubmit(onSubmit)();
    }

    const closeDialog = () => {
        handleClose();
        reset(defaultValues);
    }

    return (
        <Dialog open={isOpenModal} maxWidth="sm">
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                    <IconButton onClick={closeDialog}>
                        <Iconify icon={'eva:close-fill'} width={24} height={24} />
                    </IconButton>
                </Box>

                <Stack direction="column" justifyContent="space-between" sx={{ pb: 1, px: 3 }}>
                    <Typography variant="h6">Upload</Typography>
                </Stack>

                <DialogContent sx={{ maxHeight: '70vh' }}>
                    <Stack direction="row">
                        <EditAuthorizationFilesUpload />
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Stack direction="row" spacing={.5}>
                        <Button size="small" variant="contained" onClick={handleUploadFiles}>
                            Upload Documents
                        </Button>
                        {
                            values.files.length > 0 && (
                                <Button color="inherit" size="small" onClick={handleRemoveAll}>
                                    Remove all
                                </Button>
                            )
                        }
                        <Button size="small" color="error" onClick={closeDialog}>
                            Cancel
                        </Button>
                    </Stack>
                </DialogActions>
            </FormProvider>
        </Dialog>
    )
}