
import { Stack, Box, Typography } from '@mui/material';


export default function FreeTrial(){
    return (
      <Stack direction="row" alignItems="center" sx={{ mb: '5px' }}>
        <Box>
          <Typography sx={{ color: '#EB2F96' }}>This module is currently under Free Trial</Typography>
        </Box>
      </Stack>
    );
}