import Analytics from 'analytics';
import googleAnalyticsPlugin from '@analytics/google-analytics';

const isDev = process.env.NODE_ENV === 'development';

const analyticsGa = Analytics({
  app: isDev ? 'staging.senga.co' : 'shipper.senga.co',
  plugins: [
    googleAnalyticsPlugin({
      measurementIds: [process.env.REACT_APP_GOOGLE_ANALYTICS_ID],
    }),
  ],
});

export default analyticsGa;
