import _mock from './_mock';

export const _orders = [
  {
    orderId: '98095',
    customer: 'Naivas Ndogo',
    status: 'completed',
    customerAddress: 'Carrefour City Mall, Nyali, Malindi Rd, Mombasa, Kenya',
    numberOfItems: 8,
    lpoNo: 'PO13817872',
    timelines: [
      { stepType: 'Pickup Date', dateTime: _mock.time(2) },
      { stepType: 'Expected Arrival Date', dateTime: _mock.time('2') },
    ],
    driverInformation: {
      phone_no: '(+254)722-989-417',
      id_no: '13538275',
      avatar: _mock.image.avatar('3'),
      name: _mock.name.fullName(1),
    },
    vehicleInformation: {
      registration_no: 'KBK 797Q',
    },
    contactPersonInformation: {
      name: _mock.name.fullName(2),
      phone_no: '(+254)722-890-417',
      email: _mock.email(2),
    },
  },
  {
    orderId: '98096',
    customer: 'Naivas Ndogo',
    lpoNo: 'PO1680907872',
    status: 'In transit',
    numberOfItems: 8,
    customerAddress: 'Carrefour City Mall, Nyali, Malindi Rd, Mombasa, Kenya',
    contactPerson: {
      name: _mock.name.fullName(1),
      phone_no: '(+254)722-912-617',
    },
    timelines: [
      { stepType: 'Pickup Date', dateTime: _mock.time('2') },
      { stepType: 'Expected Arrival Date', dateTime: _mock.time('2') },
    ],
    contactPersonInformation: {
      name: _mock.name.fullName(3),
      phone_no: '(+254)722-700-414',
      email: _mock.email(3),
    },
    driverInformation: {
      phone_no: '(+254)722-989-417',
      id_no: '13538275',
      avatar: _mock.image.avatar('3'),
      name: _mock.name.fullName(1),
    },
    vehicleInformation: {
      registration_no: 'KBK 797Q',
    },
  },
  {
    orderId: '98097',
    customer: 'Naivas Ndogo',
    status: 'In transit',
    customerAddress: 'Carrefour City Mall, Nyali, Malindi Rd, Mombasa, Kenya',
    numberOfItems: 8,
    lpoNo: 'PO168907872',
    timelines: [
      { stepType: 'Pickup Date', dateTime: _mock.time('2') },
      { stepType: 'Expected Arrival Date', dateTime: _mock.time('2') },
    ],
    contactPersonInformation: {
      name: _mock.name.fullName(3),
      phone_no: '(+254)722-810-515',
      email: _mock.email(3),
    },
    driverInformation: {
      phone_no: '(+254)722-989-417',
      id_no: '13538275',
      avatar: _mock.image.avatar('3'),
      name: _mock.name.fullName(1),
    },
    vehicleInformation: {
      registration_no: 'KBK 797Q',
    },
  },
  {
    orderId: '98098',
    customer: 'Majid Nyali',
    status: 'In transit',
    customerAddress: 'Carrefour City Mall, Nyali, Malindi Rd, Mombasa, Kenya',
    numberOfItems: 20,
    contactPersonInformation: {
      name: _mock.name.fullName(3),
      phone_no: '(+254)722-520-295',
      email: _mock.email(4),
    },
    lpoNo: 'PO839137872',
    timelines: [
      { stepType: 'Pickup Date', dateTime: _mock.time('4') },
      { stepType: 'Expected Arrival Date', dateTime: _mock.time('1') },
    ],
    driverInformation: {
      phone_no: '(+254)721-970-419',
      id_no: '13538375',
      avatar: _mock.image.avatar('4'),
      name: _mock.name.fullName(2),
    },
    vehicleInformation: {
      registration_no: 'KBK 876Q',
    },
  },
  {
    orderId: '98099',
    customer: 'Naivas Nakuru DownTown',
    status: 'In transit',
    customerAddress: 'Naivas Supermarket Nakuru Downtown, Oginga Odinga Ave, Nakuru, Kenya',
    numberOfItems: 30,
    lpoNo: 'PO11317872',
    timelines: [
      { stepType: 'Pickup Date', dateTime: _mock.time('2') },
      { stepType: 'Expected Arrival Date', dateTime: _mock.time('6') },
    ],
    contactPersonInformation: {
      name: _mock.name.fullName(3),
      phone_no: '(+254)701-140-275',
      email: _mock.email(4),
    },
    driverInformation: {
      phone_no: '(+254)721-170-519',
      id_no: '13536975',
      avatar: _mock.image.avatar('5'),
      name: _mock.name.fullName(3),
    },
    vehicleInformation: {
      registration_no: 'KDH 876Q',
    },
  },
  {
    orderId: '98100',
    customer: 'Total New Mumias',
    status: 'In transit',
    customerAddress: 'Total New Mumias Service Station, Mumias - Bungoma Rd, Mumias, Kenya',
    numberOfItems: 45,
    lpoNo: 'P1331390907872',
    timelines: [
      { stepType: 'Pickup Date', dateTime: _mock.time('1') },
      { stepType: 'Expected Arrival Date', dateTime: _mock.time('6') },
    ],
    contactPersonInformation: {
      name: _mock.name.fullName(3),
      phone_no: '(+254)702-890-145',
      email: _mock.email(4),
    },
    driverInformation: {
      phone_no: '(+254)721-970-419',
      id_no: '23537275',
      avatar: _mock.image.avatar('6'),
      name: _mock.name.fullName(4),
    },
    vehicleInformation: {
      registration_no: 'KBK 876Q',
    },
  },
  {
    orderId: '98101',
    customer: 'Season Energy',
    status: 'In transit',
    customerAddress: 'Season Energy Limited, Molem Next Shell, Kenya',
    numberOfItems: 50,
    lpoNo: 'PO31832107872',
    timelines: [
      { stepType: 'Pickup Date', dateTime: _mock.time('3') },
      { stepType: 'Expected Arrival Date', dateTime: _mock.time('5') },
    ],
    contactPersonInformation: {
      name: _mock.name.fullName(3),
      phone_no: '(+254)701-120-475',
      email: _mock.email(4),
    },
    driverInformation: {
      phone_no: '(+254)721-970-419',
      id_no: '53568275',
      avatar: _mock.image.avatar('7'),
      name: _mock.name.fullName(5),
    },
    vehicleInformation: {
      registration_no: 'KBK 876Q',
    },
  },
  {
    orderId: '98102',
    customer: 'Naivas Embu',
    status: 'picked',
    customerAddress: 'Naivas Supermarket- Embu, Kubukubu Road Moi Stadium, Embu, Kenya',
    numberOfItems: 10,
    lpoNo: 'P31312307872',
    timelines: [
      { stepType: 'Pickup Date', dateTime: _mock.time('1') },
      { stepType: 'Expected Arrival Date', dateTime: _mock.time('4') },
    ],
    driverInformation: {
      phone_no: '(+254)720-470-419',
      id_no: '53535275',
      avatar: _mock.image.avatar('8'),
      name: _mock.name.fullName(6),
    },
    contactPersonInformation: {
      name: _mock.name.fullName(3),
      phone_no: '(+254)701-901-275',
      email: _mock.email(4),
    },
    vehicleInformation: {
      registration_no: 'KDC 880Q',
    },
  },
  {
    orderId: '98103',
    customer: 'Total Kerugoya',
    status: 'picked',
    customerAddress: 'Total Kerugoya Service Station, C74 Kerugoya Road, Kerugoya, Kenya',
    numberOfItems: 30,
    lpoNo: 'PO313107872',
    timelines: [
      { stepType: 'Pickup Date', dateTime: _mock.time(6) },
      { stepType: 'Expected Arrival Date', dateTime: _mock.time(4) },
    ],
    contactPersonInformation: {
      name: _mock.name.fullName(3),
      phone_no: '(+254)701-291-175',
      email: _mock.email(4),
    },
    driverInformation: {
      phone_no: '(+254)721-650-419',
      id_no: '43538875',
      avatar: _mock.image.avatar('9'),
      name: _mock.name.fullName(7),
    },
    vehicleInformation: {
      registration_no: 'KBK 458Q',
    },
  },
  {
    orderId: '98104',
    customer: 'Naivas Ndogo',
    status: 'picked',
    numberOfItems: 8,
    lpoNo: 'P3132107872',
    customerAddress: 'Carrefour City Mall, Nyali, Malindi Rd, Mombasa, Kenya',
    timelines: [
      { stepType: 'Pickup Date', dateTime: _mock.time('2') },
      { stepType: 'Expected Arrival Date', dateTime: _mock.time('2') },
    ],
    contactPersonInformation: {
      name: _mock.name.fullName(3),
      phone_no: '(+254)704-201-875',
      email: _mock.email(4),
    },
    driverInformation: {
      phone_no: '(+254)722-989-417',
      id_no: '13538275',
      avatar: _mock.image.avatar('3'),
      name: _mock.name.fullName(1),
    },
    vehicleInformation: {
      registration_no: 'KBK 797Q',
    },
  },
];
