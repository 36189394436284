import PropTypes from 'prop-types';
import { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom';

import { Box, Stack } from '@mui/material';
import { PATH_DASHBOARD } from '../routes/paths';
import BetaChipStyle from './BetaChipStyle';
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  isFull: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx, isFull = true }) {

  const logo = (
    <Stack direction="row" spacing={1}>
      <Box
        component="img"
        src={isFull ? '/logo/logo_full.png' : '/logo/logo_single.png'}
        sx={{ width: 120, height: 60, cursor: 'pointer', ...sx }}
      />
      <Box>
        <BetaChipStyle
          label="Beta"
          size="small"
          variant="outlined"
          sx={{
            '& .MuiChip-label': {
              fontSize: '1rem',
            },
          }}
        />
      </Box>
    </Stack>
  );

  if (disabledLink) {
    return logo;
  }

  return <RouterLink to={ PATH_DASHBOARD.general.app }>{logo}</RouterLink>;
}
