export default function defaultReturn(singleOrder = null) {
    if (!singleOrder || singleOrder.length < 1) {
        return {
            pickup_point: null,
            order: null,
            returns_items: [{
                units: null,
                return_reason: null,
                item_description: null
            }],
            supportingDocuments: []
        }
    }
    return {
        pickup_point: null,
        order: {
            invoice_no: singleOrder[0]?.invoiceNo,
            customer_name: singleOrder[0]?.customer.name,
            dnote_number: singleOrder[0]?.dnoteNo,
            id: singleOrder[0]?.id
        },
        returns_items: [{
            units: "",
            return_reason: null,
            item_description: null
        }],
        supportingDocuments: []
    }
}