// @mui
import { Skeleton } from '@mui/material';

export default function SkeletonInventory() {
    return (
        <Skeleton
            animation="wave"
            height={20}
            width="80%"
            style={{ marginBottom: 6 }}
        />
    )
}