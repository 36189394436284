/**
 *
 * 
 * Return the color of a return status 
 * 
 * 
 *
*/
export function returnStatusColor(returnStatus) {
    switch (returnStatus) {
        case "Reported":
            return "secondary"
        case "In-Office":
            return "warning"
        case "Dispatched":
            return "primary"
        case "Completed":
            return "success"
        case "Received By Shipper":
            return "success"
        default:
            return "error"
    }
}