import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  isUpdated: false,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
});

export default slice.reducer;

export function resetPasswordRequest(emailAddress = '') {
  return async () => {
    try {
      const payload = {
        email: emailAddress,
        url: window?.location?.origin || 'https://staging.senga.co',
      };
      const pathName = `/api/v1/user/forgot_password`;
      const response = await axios.post(pathName, payload);
      return response;
    } catch (error) {
      const errorMessage = error?.data?.errors[0];
      throw new Error(errorMessage);
    }
  };
}

export function changePassword(data) {
  return async () => {
    try {
      const payload = {
        user: {
          reset_password_token: data?.token || '',
          new_password: data?.password || '',
          confirm_password: data?.confirmPassword || '',
        },
      };
      const pathName = `/api/v1/user/reset_password`;
      const response = await axios.patch(pathName, payload);
      return response;
    } catch (error) {
      const errorMessage = error?.data?.errors[0];
      throw new Error(errorMessage);
    }
  };
}

