import PropTypes from 'prop-types';

// @mui
import { InputAdornment, Popper } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
// @mui
// @form
import { useFormContext, Controller } from 'react-hook-form';
// @form

// @components
import InputStyle from '../InputStyle';
import Iconify from '../Iconify';
// @components

const PopperStyle = styled((props) => <Popper placement="bottom-start" {...props} />)({
    width: '280px !important',
});

const filter = createFilterOptions();

AutocompleteFreeSolo.propTypes = {
    options: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    error: PropTypes.object.isRequired,
    focusstretch: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    field: PropTypes.object
}

export function AutocompleteFreeSolo({ field,
    options = [], label = "", width = 230, focusstretch = 0, error = null
}) {
    return (
        <Autocomplete {...field}
            id="free-solo-demo"
            freeSolo
            autoHighlight
            selectOnFocus
            PopperComponent={PopperStyle}
            options={options}
            onChange={(event, value) => {
                field.onChange(value);
            }}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.label;
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => (
                    inputValue.toLowerCase() === option.label.toLowerCase()
                ));
                if (inputValue !== '' && !isExisting) {
                    filtered.unshift({
                        inputValue,
                        label: `Add new "${inputValue}"`,
                        id: inputValue,
                    });
                }

                return filtered;
            }}
            renderInput={(params) => (
                <InputStyle
                    {...params}
                    stretchStart={width}
                    focusstretch={focusstretch}
                    label={label}
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify icon={'eva:search-fill'}
                                    sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }}
                                />
                            </InputAdornment>
                        ),
                        size: "small"
                    }}
                />
            )}
            renderOption={(props, option) => {
                const { label, id } = option;
                return (
                    <li {...props} key={id || label}>
                        {label}
                    </li>
                )
            }}
        />
    )
}

RHFAutocompleteFreeSolo.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    focusstretch: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    options: PropTypes.array.isRequired
}

export function RHFAutocompleteFreeSolo({ name, label,
    width = 230, focusstretch = 0, options = [], ...other
}) {
    const methods = useFormContext();
    const { control } = methods;
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <AutocompleteFreeSolo
                    width={width} focusstretch={focusstretch}
                    label={label} field={field} error={error}
                    {...other} options={options}
                />
            )}
        />
    )
}