import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { deserialize } from "deserialize-json-api";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
// @mui
import { Autocomplete, Popper, Paper, Typography, Stack, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
// --------------
// utils
import axios from '../../utils/axios';
// utils
// Components
import InputStyle from '../InputStyle';
import Iconify from '../Iconify';
// ------------------

SearchNotFound.propTypes = {
    searchQuery: PropTypes.string,
    initialText: PropTypes.string,
    searchResults: PropTypes.array
};

//  No results found for &nbsp;
function SearchNotFound({ searchQuery = '', searchResults, initialText = "Please enter keywords", ...other }) {
    return searchQuery && searchResults.length < 1 ? (
        <Paper {...other}>
            <Typography gutterBottom align="center" variant="subtitle1">
                Not found
            </Typography>
            <Typography variant="body2" align="center">
                We don’t have this shipper &nbsp;
                <strong>&quot;{searchQuery}&quot;</strong>. Please check typos.
            </Typography>
        </Paper>
    ) : (
        <Typography variant="body2">
            {initialText}
        </Typography>
    );
}

const PopperStyle = styled((props) => <Popper placement="bottom-start" {...props} />)({
    width: '280px !important',
});

let timeout;

ShipperSearch.propTypes = {
    recentShipper: PropTypes.object,
    setShipper: PropTypes.func.isRequired,
    formatShipper: PropTypes.func.isRequired
}
export default function ShipperSearch({ recentShipper = {}, setShipper, formatShipper }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([recentShipper]);

    const handleChangeSearch = async (value) => {
        try {
            setSearchQuery(value);
            if (value && value.trim().length > 0) {
                const pathName = `/api/v1/search/shippers?search_val=${value.trim()}`;
                clearTimeout(timeout);
                timeout = setTimeout(async () => {
                    const response = await axios.get(pathName);
                    const result = deserialize(response.data, { transformKeys: "camelCase" }).data;
                    if (result.length > 0) {
                        setSearchResults(result.map((shipper) => (
                            formatShipper(shipper)
                        )));
                    } else {
                        setSearchResults([]);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Autocomplete
            value={recentShipper}
            autoHighlight
            popupIcon={null}
            PopperComponent={PopperStyle}
            options={searchResults}
            filterOptions={(x) => x}
            disableClearable
            onInputChange={(event, value) => handleChangeSearch(value)}
            getOptionLabel={(shipper) => shipper?.shipperName || ""}
            noOptionsText={
                <SearchNotFound searchQuery={searchQuery} initialText={"Search for Shipper"} searchResults={searchResults} />
            }
            isOptionEqualToValue={(option, value) => (option.shipperId === value.shipperId)}
            onChange={(event, newValue) => setShipper(newValue)}
            renderInput={(params) => (
                <InputStyle
                    {...params}
                    stretchStart={360}
                    focusstretch={30}
                    label="Search Shipper"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
                            </InputAdornment>
                        )
                    }}
                />
            )}
            renderOption={(props, shipper, { inputValue }) => {
                const { shipperName, shipperNo } = shipper;
                const nameWithShipperNo = `${shipperNo} ${shipperName}`;
                const matches = match(nameWithShipperNo, inputValue);
                const parts = parse(nameWithShipperNo, matches);
                return (
                    <li {...props} key={shipper.id}>
                        <Stack direction="column">
                            <Stack direction="row" spacing={0.1}>
                                {parts.map((part, index) => (
                                    <Typography
                                        key={index}
                                        component="span"
                                        variant="subtitle2"
                                        color={part.highlight ? 'primary' : 'textPrimary'}
                                        sx={{ pr: 0.07 }}
                                    >
                                        {`${part.text}  `}
                                    </Typography>

                                ))}
                            </Stack>
                        </Stack>
                    </li>
                )
            }}
        />
    )
}