import dayjs from 'dayjs';
import { deserialize } from 'deserialize-json-api';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { dispatch } from '../store';

import { fDateCompact } from '../../utils/formatTime';

const initialState = {
  isLoading: false,
  error: null,
  sortBy: 'newestRequest',
  guestOrdersData: [],
  guestOrderData: [],
  pickupDateRange: [],
  
  
};

const slice = createSlice({
  name: 'guestOrders',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    }, 
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    
    // GET ORDERS
    getGuestOrdersSuccess(state, action) {
      state.isLoading = false;
      state.guestOrdersData = state.isUpdated ? state.guestOrdersData : action.payload;
    },

    getGuestOrderSuccess(state, action) {
      state.isLoading = false;
      const payload = action.payload.map((order) => ({
        id: order.id,
        customer: {
          address: order.customer_address,
          id: order.customer_id,
          name: order.customer_name,
        },
        contactPerson: {
          name: order?.contact_person?.name || null,
          email: order?.contact_person?.email || null,
          phoneNumber: order?.contact_person?.phone_number || null,
        },
        invoiceNo: order.invoice_no || '',
        lpoNo: order.lpo_no || '',
        dnoteNo: order.dnote_number || '',
        pickupDate: new Date(order.pickup_date),
        expectedDeliveryDate: new Date(order.expected_arrival_date),
        total: order.total,
        status: order.order_status[0] || 'scheduled',
        orderlineItems: order.orderline_items.map((orderLineItem) => ({
          quantity: orderLineItem.quantity,
          package_id: orderLineItem.item_id,
          package_name: orderLineItem.item_description,
          total_weight: parseFloat(orderLineItem.weight) || 0,
          weight_unit: orderLineItem.weight_unit,
          id: orderLineItem.id,
        })),
        actualDeliveryDate: order?.actual_delivery_date?.created_at || '',
        hasDnoteImages: order?.has_dnote_images || false,
        hasMissingItems: order?.has_missing_items || false,
        hasReturns: order?.has_returns || false,
        orderNotes: order?.order_notes || [],


      }));

      state.guestOrderData = payload;
    },
    // SORT
    sortByDeliveries(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  sortByDeliveries,
  getGuestOrdersSuccess,
  getGuestOrderSuccess,
} = slice.actions;

// get order but with a different date filter
export function getGuestOrders({ token }){ // filterPickupDateFrom, filterPickupDateEnd , searchVal}) {
  return async () => {
    try {
      let pathName = `/shipper_app/api/v1/guest_orders?token=${token}`;
      const pickupStartDate = dayjs(new Date(new Date().setDate(new Date().getDate() - 30))).format('YYYY-MM-DD');
      const pickupEndDate = dayjs(new Date()).format('YYYY-MM-DD');
      pathName = `${pathName}&pickup_start_date=${pickupStartDate}&pickup_end_date=${pickupEndDate}`;
      dispatch(slice.actions.startLoading());
      const response = await axios.get(pathName);
      const { data } = response;

      const receivedData = deserialize(data).data.map((order) => ({
        customer: {
          name: order.customer_name,
          id: order.customer_id,
          address: order.customer_address,
        },
        numberOfItems: order.total,
        pickupDate: order.created_at,
        deliveryDate: order.expected_arrival_date,
        orderId: order.id.toString(),
        invoiceNo: order.invoice_no,
        lpoNo: order.lpo_no,
        dnoteNo: order.dnote_id,
        total: order.total,
        status: order.order_status[0] || 'scheduled',
        lpoDate: order.expected_arrival_date,
        orderlineItems: order.orderline_items,
        contactPerson: {
          name: order?.contact_person?.name || null,
          email: order?.contact_person?.email || null,
          phoneNumber: order?.contact_person?.phone_number || null,
        },
        orderNotes: order?.order_notes || [],
        totalWeightKg: order.total_weight_kg,
      }));

      dispatch(slice.actions.getGuestOrdersSuccess(receivedData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGuestOrder({ token }) {
  return async () => {
    try {
      const pathName = `shipper_app/api/v1/guest_order/${token}`;
      dispatch(slice.actions.startLoading());
      const response = await axios.get(pathName);
      const receivedData = response.data;
      const order = deserialize(receivedData).data;
      dispatch(slice.actions.getGuestOrderSuccess(order));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



