/**
 * 
 * Reducer for a single return document reference
 * 
*/

import { createSlice } from "@reduxjs/toolkit"
import { dispatch } from "../store"
import axiosInstance from "../../utils/axios";
import { formatReturnPayload } from './returns';

const initialState = {
    isLoading: false,
    error: null,
    activeReturnDocumentReference: {}
}

const slice = createSlice({
    name: 'activeReturnDocumentReference',
    initialState,
    reducers: {
        // Start Loading
        startLoading(state) {
            state.isLoading = true
        },
        // STOP LOADING
        stopLoading(state) {
            state.isLoading = false;
        },
        //  the active return state
        getActiveReturnDocumentReferenceSuccess(state, action) {
            state.activeReturnDocumentReference = action.payload
        },
        // get return document reference
        getReturnDocumentReferenceSuccess(state, action) {
            state.activeReturnDocumentReference = action.payload
        }
    }
})

export default slice.reducer;

export function getActiveReturnDocumentReference({ returnDocumentReference }) {
    return dispatch(slice.actions.getActiveReturnDocumentReferenceSuccess(returnDocumentReference))
}

export function getReturnDocumentReference(returnDocReferenceId) {
    return async () => {
        dispatch(slice.actions.startLoading())
        try {
            const pathName = `/api/v1/shipper/returns/${returnDocReferenceId}/show`;
            const response = await axiosInstance.get(pathName);
            const returnPayload = formatReturnPayload(response.data.data);
            dispatch(slice.actions.getReturnDocumentReferenceSuccess(returnPayload));
            dispatch(slice.actions.stopLoading())
        } catch (err) {
            console.error(err);
        }
    }
}