import PropTypes from 'prop-types';

import { useState, useMemo } from 'react';

// form
import { useFormContext, useFieldArray } from 'react-hook-form';
// form

// @mui
import { Grid, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// @mui

// component
import ScheduleReturn from './ScheduleReturn';
import Iconify from '../../../../../components/Iconify';
import AuthorizationFilesUploadDialog from './components/AuthorizationFilesUploadDialog';
// component
import { defaultReturn } from './utils';

// hooks
import { useSelector } from '../../../../../redux/store';
// hooks

ScheduleReturnMultiple.propTypes = {
    editMode: PropTypes.bool
}

export default function
    ScheduleReturnMultiple({ editMode = false }) {
    const methods = useFormContext();

    const [authReturnIndexUpload, setAuthReturnIndexUpload] = useState(null);
    const [isOpenNewCustomerDialog, setOpenNewCustomerDialog] = useState(null);

    const { control, setValue, getValues } = methods;

    const {
        fields: returnsFields,
        append: appendReturns,
        remove: removeReturn
    } = useFieldArray({
        control,
        name: 'returns',
    });

    const authFilesModalOpen = useMemo(() => (
        typeof authReturnIndexUpload === 'number' || typeof authReturnIndexUpload === 'string'
    ), [authReturnIndexUpload])

    const { isLoading } = useSelector((state) => state.returns);

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={11}>
                {/* Schedule Returns Cards */}
                <Grid container rowSpacing={3}>
                    {
                        returnsFields.map((returnField, returnIndex) => (
                            <ScheduleReturn key={returnField.id}
                                returnIndex={returnIndex}
                                returnsFields={returnsFields}
                                removeReturn={removeReturn}
                                editMode={editMode}
                                setAuthReturnIndexUpload={setAuthReturnIndexUpload}
                                setOpenNewCustomerDialog={setOpenNewCustomerDialog}
                            />
                        ))
                    }
                </Grid>
                {/* Schedule Returns Cards */}

                {/* Add Return */}
                {!editMode && (
                    <Grid container item md={12} sx={{ p: 3 }}>
                        <Grid>
                            <Button
                                startIcon={<Iconify icon="eva:plus-fill" />}
                                onClick={() => appendReturns(defaultReturn())}
                                id="addDropoffPointButton"
                            >
                                Add Return
                            </Button>
                        </Grid>
                    </Grid>
                )}
                {/* Add Return */}

                {/* AuthorizationFilesUploadDialog */}
                <AuthorizationFilesUploadDialog
                    isOpenModal={authFilesModalOpen}
                    handleClose={() => {
                        setAuthReturnIndexUpload(null);
                    }}
                    handleSubmitAuthorizationFiles={(supportingDocData) => {
                        let newSupportingDocuments = [...getValues(`returns[${authReturnIndexUpload}].supportingDocuments`)] || [];
                        const existingSupportingDocument = newSupportingDocuments.find((supportingDocument) => (
                            supportingDocument.document === supportingDocData.document
                        ));
                        if (existingSupportingDocument) {
                            existingSupportingDocument.files = [...existingSupportingDocument.files, ...supportingDocData.files]
                        } else {
                            newSupportingDocuments = [...newSupportingDocuments, supportingDocData]
                        }
                        setValue(`returns[${authReturnIndexUpload}].supportingDocuments`, newSupportingDocuments);
                    }}
                />

                {/* Submit Return Pickup */}
                <Grid container>
                    <Grid item>
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            loading={isLoading}
                            startIcon={<Iconify icon={'mdi:truck-fast-outline'} />}
                            sx={{ textTransform: 'none' }}
                            id="submitPickupRequest"
                        >
                            {editMode ? "Save Changes" : "Submit Return Request"}
                        </LoadingButton>
                    </Grid>
                </Grid>
                {/* Submit Return Pickup */}
            </Grid>
        </Grid>
    )
}