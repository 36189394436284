import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
// @mui
import { useTheme, alpha } from '@mui/material/styles';
import { Typography, GlobalStyles, DialogActions, DialogContentText, DialogTitle, Button, DialogContent } from '@mui/material';
import { Document as MyDocument, Page, pdfjs } from 'react-pdf';
import { DialogAnimate } from './animate';
import './LightboxModal.css'

// ----------------------------------------------------------------------

function LightboxModalStyles() {
  const theme = useTheme();

  const isRTL = theme.direction === 'rtl';

  const ICON_SIZE = 32;

  const ICON_COLOR = theme.palette.grey[600].replace('#', '');

  const getIcon = (icon) =>
    `url(https://api.iconify.design/carbon/${icon}.svg?color=%23${ICON_COLOR}&width=${ICON_SIZE}&height=${ICON_SIZE})`;

  const Icon = (icon) => ({
    opacity: 1,
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    backgroundImage: `unset`,
    backgroundColor: 'transparent',
    transition: theme.transitions.create('opacity'),
    '&:before': {
      display: 'block',
      width: ICON_SIZE,
      height: ICON_SIZE,
      content: getIcon(icon),
    },
    '&:hover': {
      opacity: 0.72,
    },
  });

  return (
    <GlobalStyles
      styles={{
        '& .ReactModalPortal': {
          '& .ril__outer': {
            backgroundColor: alpha(theme.palette.grey[900], 0.96),
          },

          // Toolbar
          '& .ril__toolbar': {
            height: 'auto !important',
            padding: theme.spacing(2, 3),
            backgroundColor: 'transparent',
          },
          '& .ril__toolbarLeftSide': { display: 'none' },
          '& .ril__toolbarRightSide': {
            height: 'auto !important',
            padding: 0,
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            '& li': {
              display: 'flex',
              alignItems: 'center',
            },
            '& li:first-of-type': {
              flexGrow: 1,
            },
            '& li:not(:first-of-type)': {
              width: 40,
              height: 40,
              justifyContent: 'center',
              marginLeft: theme.spacing(2),
            },
          },

          // Button
          '& button:focus': { outline: 'none' },
          '& .ril__toolbarRightSide button': {
            width: '100%',
            height: '100%',
            '&.ril__zoomInButton': Icon('zoom-in'),
            '&.ril__zoomOutButton': Icon('zoom-out'),
            '&.ril__closeButton': Icon('close'),
          },
          '& .ril__navButtons': {
            padding: theme.spacing(3),
            '&.ril__navButtonPrev': {
              right: 'auto',
              left: theme.spacing(2),
              ...Icon(isRTL ? 'arrow-right' : 'arrow-left'),
            },
            '&.ril__navButtonNext': {
              left: 'auto',
              right: theme.spacing(2),
              ...Icon(isRTL ? 'arrow-left' : 'arrow-right'),
            },
          },
        },
      }}
    />
  );
}

// ----------------------------------------------------------------------

LightboxModal.propTypes = {
  images: PropTypes.array.isRequired,
  photoIndex: PropTypes.number,
  setPhotoIndex: PropTypes.func,
  isOpen: PropTypes.bool,
  setOpenLightbox: PropTypes.any,
};

export default function LightboxModal({ images, photoIndex, setPhotoIndex,setOpenLightbox, isOpen, ...other }) {
  const [numPages, setNumPages] = useState();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isOpen]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if(isOpen === true){
      setOpen(true)
    }
  }, [isOpen])


  const handleClose = () => {
    setOpen(false);
    setOpenLightbox(false)
  };

  const showIndex = <Typography variant="subtitle2">{`${photoIndex + 1} / ${images.length}`}</Typography>;

  const toolbarButtons = [showIndex];

  const customStyles = {
    overlay: {
      zIndex: 9999,
    },
  };

  const handleDownload = async (fileURL) => {
    try {
      // Replace 'your-file-url' with the actual URL of the file to be downloaded
      const fileUrl = fileURL;

      // Fetch the file data
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = 'delivery_note';
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);

      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
  
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  return (
    <>
      <LightboxModalStyles />

      {isOpen && (
        images[photoIndex].endsWith(".pdf") ?
        (
          <DialogAnimate fullWidth maxWidth="md" open={open} onClose={handleClose}>
            <DialogTitle>Uploaded delivery note</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <MyDocument file={images[photoIndex]} onLoadSuccess={({ numPages }) => { setNumPages(numPages) }}>
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        className="pdf-page"
                    />
                  ))}
                </MyDocument>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleDownload(images[photoIndex])} color="primary">
                Download
              </Button>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </DialogAnimate>
        )
        :
        (<Lightbox
          animationDuration={160}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          toolbarButtons={toolbarButtons}
          reactModalStyle={customStyles}
          {...other}
        />)
      )}
    </>
  );
}
