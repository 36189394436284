import React, { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Button, Card, CardContent, Chip, Container, Grid, Link, Stack, Typography, Skeleton } from '@mui/material'
import ReturnConfirmationImages from '../../sections/@dashboard/tms/returns/ReturnConfirmationImages';
import Page from '../../components/Page'
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs'
import { PATH_DASHBOARD } from '../../routes/paths'
import ReturnItemsTableView from './ReturnItemsTableView'
import ReturnItemTimelineView from './ReturnItemTimelineView'
import ReturnSupportingDocuments from '../../sections/@dashboard/tms/returns/ReturnSupportingDocuments'
import { fDate } from '../../utils/formatTime'
import { getReturnDocumentReference } from '../../redux/slices/activeReturnDocument';
import { getReturnDocumentReferenceReturnItems } from '../../redux/slices/returnItems';
import { returnStatusColor } from '../../utils/getReturnStatusColour';
import FreeTrial from '../../components/FreeTrial';
// hooks
import useSettings from '../../hooks/useSettings';
import './ReturnItemView.css'
import { HOST_API } from '../../config';
// ---------------

const formatDocumentName = (documentShortForm) => {
    switch (documentShortForm) {
        case "dnote":
            return "Delivery Note"
        case "grn":
            return "Good Return Note"
        case "invoice":
            return "Invoice"
        default:
            return "Document"
    }
}

const refNoName = (document) => {
    switch (document) {
        case "dnote":
            return "dnoteNumber"
        case "invoice":
            return "invoiceNo"
        case "grn":
            return "grnNo"
        default:
            return ""
    }
}
/**
 * 
 * @returns ReturnItemView 
 */
export default function ReturnItemView() {
    // Fetch the return doc id
    const { id } = useParams()

    const dispatch = useDispatch()

    const { returnDocumentReferenceStatusTimeline } = useSelector(state => state.returnDocumentReferenceStatusTimeline)

    const { themeStretch } = useSettings();
    // const [images, setImages] = useState([])

    // Fetch the active return document
    const { activeReturnDocumentReference } = useSelector(state => state.activeReturnDocumentReference)

    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getReturnDocumentReferenceReturnItems({ returnDocumentReferenceID: id }))
    }, [dispatch, id])

    useEffect(() => {
        // also if the returnDocReferenceId from the state and the query string are different then fetch
        if (Object.keys(activeReturnDocumentReference).length < 1 || activeReturnDocumentReference.id.toString() !== id.toString()) {
            dispatch(getReturnDocumentReference(id));
        }
    }, [activeReturnDocumentReference, dispatch, id]);

    const images = useMemo(() => {
        const imagesList = []
        if (Object.keys(activeReturnDocumentReference).length >= 1 || activeReturnDocumentReference?.returnConfirmationImages) {
            activeReturnDocumentReference.returnConfirmationImages.forEach((item) => {
                const image = item.attributes.return_confirmation_images.data.attributes.return_confirmation_images;
                image.forEach(imageFile => {
                    imagesList.push({ preview: `${HOST_API}${imageFile}`, path: "Return Confirmation" })
                })
            })
        }

        return imagesList;
    }, [activeReturnDocumentReference]);

    // Navigate to the PDF
    const handleViewReturnNote = (id) => {
        navigate(PATH_DASHBOARD.tms.returns.view(id));
    }

    return (
        <Page>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <FreeTrial />
                <HeaderBreadcrumbs
                    heading="Return Note Details"
                    links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root },
                    { name: 'Returns', href: PATH_DASHBOARD.tms.returns.deliveries },
                    { name: `RD-${id}` }]}
                />

                <Card>
                    <CardContent>

                        <Grid container spacing={2} direction={'column'} sx={{ p: 2 }}>

                            {/** Header container */}
                            <Box width={"100%"} minHeight={200} backgroundColor="#F5F5F5" sx={{ p: 3, borderRadius: 2 }} flexDirection={'row'}>
                                <Grid container spacing={2} direction={'row'}>

                                    <Grid item xs={12} md={4}>
                                        {/** Return Doc ID with the Status */}
                                        <Stack>
                                            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                                                Return Reference ID
                                            </Typography>

                                            <Typography variant="subtitle1">
                                                {`RD-${id}`}
                                            </Typography>

                                            {/** Return Status */}
                                            <Box width={"200px"} sx={{ py: 1 }}>
                                                {
                                                    activeReturnDocumentReference?.status ? (
                                                        activeReturnDocumentReference?.status.map((s) => (
                                                            <Chip key={s} label={s} color={returnStatusColor(s)} />
                                                        ))
                                                    ) : (
                                                        <Skeleton variant="rectangular">
                                                            <Chip />
                                                        </Skeleton>
                                                    )
                                                }
                                            </Box>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        {/** Customer Details */}
                                        <Stack direction="column">
                                            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                                                Customer
                                            </Typography>

                                            {
                                                activeReturnDocumentReference.customerName ? (
                                                    <Typography variant="subtitle1">
                                                        {activeReturnDocumentReference?.customerName}
                                                    </Typography>
                                                ) : (
                                                    <Skeleton variant='rectangle'>
                                                        <Typography variant='subtitle1' />
                                                    </Skeleton>
                                                )
                                            }

                                            {
                                                activeReturnDocumentReference.townName ? (
                                                    <Typography variant="caption" sx={{ color: 'text.disabled', my: 0.5, fontWeight: 'fontWeightMedium' }}>
                                                        {activeReturnDocumentReference?.townName}
                                                    </Typography>
                                                ) : (
                                                    <Skeleton variant='rectangle'>
                                                        <Typography variant='subtitle1' />
                                                    </Skeleton>
                                                )
                                            }



                                            <div>
                                                <Stack direction={'row'} spacing={1} paddingTop={1}>
                                                    <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                                                        Order ID:
                                                    </Typography>

                                                    <Link noWrap variant="overline"
                                                        onClick={() => { }}
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        {activeReturnDocumentReference?.orderID}
                                                    </Link>
                                                </Stack>
                                            </div>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        {/** */}
                                        <Stack>
                                            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                                                Extra Details
                                            </Typography>

                                            {/** Request Time */}
                                            <div>
                                                <Stack direction={"row"} spacing={1}>
                                                    <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                                                        Reported Date:
                                                    </Typography>

                                                    {
                                                        activeReturnDocumentReference.reportDate ? (
                                                            <Typography noWrap variant="caption" sx={{ cursor: 'pointer' }}>
                                                                {fDate(activeReturnDocumentReference?.reportDate)}
                                                            </Typography>
                                                        ) : (
                                                            <Skeleton variant='rectangle'>
                                                                <Typography variant='subtitle1' />
                                                            </Skeleton>
                                                        )
                                                    }
                                                </Stack>
                                            </div>

                                            {/** Order Delivery Date */}
                                            <div>
                                                <Stack direction={"row"} spacing={1}>
                                                    <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                                                        Delivery Note Number:
                                                    </Typography>

                                                    <Typography noWrap variant="caption">
                                                        {activeReturnDocumentReference?.dnoteNumber}
                                                    </Typography>
                                                </Stack>
                                            </div>

                                            {/** Number of Items */}
                                            <div>
                                                <Stack direction={"row"} spacing={1}>
                                                    <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                                                        No. of Items:
                                                    </Typography>
                                                    <Typography noWrap variant="caption">
                                                        {activeReturnDocumentReference?.numberOfReturns}
                                                    </Typography>
                                                </Stack>
                                            </div>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>

                            {/** Body Container */}
                            <Box alignItems={'center'} alignContent={'center'} sx={{ pt: 2 }}>
                                {/** Header and an option to view the return note */}
                                <Grid container spacing={2} direction={'row'} justifyContent="space-between" alignItems="center">
                                    {/** Table Header */}
                                    <Grid item xs={2} alignSelf={'center'}>
                                        <Typography paragraph variant="h6" sx={{ color: 'text.disabled' }}>
                                            Return Items
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} display={'flex'} justifyContent={'end'}>
                                        <Button
                                            variant='outlined'
                                            onClick={() => handleViewReturnNote(id)}
                                        >
                                            View Return Note
                                        </Button>
                                    </Grid>
                                </Grid>

                                {/** Return Items Table */}
                                <ReturnItemsTableView />
                            </Box>
                            {/** Body Container */}

                            {/* Return Status Timeline */}
                            {
                                (returnDocumentReferenceStatusTimeline && returnDocumentReferenceStatusTimeline.length >= 1) ? (
                                    <Box>
                                        {/** Content Header */}
                                        <Typography paragraph variant="h6" sx={{ color: 'text.disabled' }}>
                                            Return Status Timeline
                                        </Typography>

                                        {/** Timeline Entries */}
                                        <ReturnItemTimelineView />
                                    </Box>
                                ) : (null)
                            }
                            {/* Return Status Timeline */}

                            {/* Return Supporting Files */}
                            {
                                (activeReturnDocumentReference.returnSupportingDocuments && activeReturnDocumentReference?.returnSupportingDocuments.length >= 1) ? (
                                    <Box>
                                        <Typography paragraph variant="h6" sx={{ color: 'text.disabled' }}>
                                            Authorization Documents
                                        </Typography>

                                        <Grid container sx={{ bgcolor: 'background.neutral', p: 2, borderRadius: 1 }}>
                                            {
                                                activeReturnDocumentReference?.returnSupportingDocuments.map((returnSupportingDocument, index) => {
                                                    
                                                   return  <Grid item xs={12} md={12} key={index} sx={{ my: 1 }}>
                                                        <ReturnSupportingDocuments gallery={returnSupportingDocument.file}
                                                            refNo={activeReturnDocumentReference[refNoName(returnSupportingDocument.document || "", activeReturnDocumentReference)] || ""}
                                                            document={formatDocumentName(returnSupportingDocument.document || "")}
                                                        />
                                                    </Grid>
                                                })
                                            }
                                        </Grid>
                                    </Box>
                                ) : (null)
                            }
                            {/* Return Supporting Files */}

                            {/* Return Confirmation Documents  */}
                            <Box className='returnConfirmtionImageContainer' sx={{ my: 2 }}>
                                {/** Content Header */}
                                <Typography paragraph variant="h6" sx={{ color: 'text.disabled' }}>
                                    Return Confirmation Documents
                                </Typography>

                                {/** Timeline Entries */}
                                <Grid container sx={{ bgcolor: 'background.neutral', p: 2, borderRadius: 1 }}>
                                    <Grid item xs={12} md={12}>
                                        <ReturnConfirmationImages gallery={images} />
                                    </Grid>
                                </Grid>
                            </Box>
                            {/* Return Confirmation Documents  */}

                        </Grid>
                    </CardContent>
                </Card>
                {/** Card Container View */}
            </Container>
        </Page>
    )
}
