import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isDecision1Open: true,
  isDecision2Open: true,
};

const slice = createSlice({
  name: 'decision',
  initialState,
  reducers: {
    // make decision by setting isDecisionOpen to false
    makeDecision1(state, action) {
      state.isDecision1Open = false;
    },
    makeDecision2(state, action) {
      state.isDecision2Open = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { makeDecision1, makeDecision2 } = slice.actions;

// ----------------------------------------------------------------------

export function makeTheDecision1() {
  return async () => {
    try {
      dispatch(slice.actions.makeDecision1());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error();
    }
  };
}

export function makeTheDecision2() {
  return async () => {
    try {
      dispatch(slice.actions.makeDecision2());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error();
    }
  };
}
