// ----------------------------------------------------------------------

export const fullName = [
  'George Mbugua',
  'Lewis Kinyanjui',
  'Moses Mwangi',
  'John Mwangi',
  'Peter Mbugua',
  'James Mwangi',
  'Walter Otieno',
  'Samuel Ochieng',
  'Chase Day',
  'Shawn Manning',
  'Soren Durham',
  'Cortez Herring',
  'Brycen Jimenez',
  'Giana Brandt',
  'Aspen Schmitt',
  'Colten Aguilar',
  'Angelique Morse',
  'Selina Boyer',
  'Lawson Bass',
  'Ariana Lang',
  'Amiah Pruitt',
  'Harold Mcgrath',
  'Esperanza Mcintyre',
  'Mireya Conner',
  'Jamie Kline',
  'Laney Vazquez',
  'Tiffany May',
  'Dexter Shepherd',
  'Jaqueline Spencer',
  'Londyn Jarvis',
  'Yesenia Butler',
  'Jayvon Hull',
  'Izayah Pope',
  'Ayana Hunter',
  'Isabell Bender',
  'Desiree Schmidt',
  'Aidan Stout',
  'Jace Bush',
  'Janiya Williamson',
  'Hudson Alvarez',
];

export const firstName = [
  'Mossie',
  'David',
  'Ebba',
  'Chester',
  'Eula',
  'Jaren',
  'Boyd',
  'Brady',
  'Aida',
  'Anastasia',
  'Gregoria',
  'Julianne',
  'Ila',
  'Elyssa',
  'Lucio',
  'Lewis',
  'Jacinthe',
  'Molly',
  'Brown',
  'Fritz',
  'Keon',
  'Ella',
  'Ken',
  'Whitney',
  'Monte',
  'Rose',
  'Shana',
  'Devon',
  'Jaleel',
  'Laury',
  'Brooks',
  'Bruce',
  'Avery',
  'Esperanza',
  'Helene',
  'Heloise',
  'Elinor',
  'Adeline',
  'Haley',
  'Anabelle',
];

export const lastName = [
  'Carroll',
  'Simonis',
  'Yost',
  'Hand',
  'Emmerich',
  'Wilderman',
  'Howell',
  'Sporer',
  'Boehm',
  'Morar',
  'Koch',
  'Reynolds',
  'Padberg',
  'Watsica',
  'Upton',
  'Yundt',
  'Pfeffer',
  'Parker',
  'Zulauf',
  'Treutel',
  'McDermott',
  'McDermott',
  'Cruickshank',
  'Parisian',
  'Auer',
  'Turner',
  'Dooley',
  'Wiegand',
  'Abbott',
  'Wisoky',
  'Fahey',
  'Satterfield',
  'Bahringer',
  'Schulist',
  'Durgan',
  'Carroll',
  'Jones',
  'Leffler',
  'Gutkowski',
  'Homenick',
];
