import { useMemo } from 'react';
import PropTypes from 'prop-types';
// @mui
import { List, Box, Chip, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import useLocales from '../../../hooks/useLocales';
//
import { ListSubheaderStyle } from './style';
import NavList from './NavList';

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse, ...other }) {
  const { translate } = useLocales();
  const trial = useMemo(() => ['Delivery Notes', 'Returns', 'Reports'], []);

  const TrialChipStyle = styled(Chip)(() => ({
    backgroundColor: '#EB2F96',
    color: 'white',
    fontWeight: 'normal',
    textTransform: 'none',
  }));

  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              {translate(group.subheader)}
              {trial.includes(group.subheader) && <TrialChipStyle label="Free Trial" size="small" />}
            </Stack>
          </ListSubheaderStyle>

          {group.items.map((list) => (
            <NavList
              key={list.title + list.path}
              data={list}
              depth={1}
              hasChildren={!!list.children}
              isCollapse={isCollapse}
            />
          ))}
        </List>
      ))}
    </Box>
  );
}
