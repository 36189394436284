import { Stack, Grid, MenuItem } from '@mui/material';

// hooks
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { RHFUploadMultiFile, RHFSelect } from '../../../../../../components/hook-form';
// hooks

// utils
import { ReturnDocumentTypes } from '../utils';
// utils

export default function EditAuthorizationFilesUpload() {
    const methods = useFormContext();
    const {
        watch,
        setValue,
    } = methods;

    const values = watch();

    const handleDrop = useCallback((acceptedFiles) => {
        const files = values.files || [];

        setValue('files', [
            ...files,
            ...acceptedFiles.map((file) => (
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            ))
        ])
    }, [setValue, values.files])

    const handleRemove = (file) => {
        const filteredItems = values.files && values.files?.filter((_file) => _file !== file);
        setValue('files', filteredItems);
    }

    return (
        <Grid container spacing={3} sx={{ py: 1 }}>

            <Grid item xs={12} md={12}>
                <Grid item md={12}>
                    <Stack direction="column" spacing={3}>

                        <RHFSelect
                            name={"document"}
                            size="small"
                            label="Document Name"
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                            fullWidth={false}
                        >
                            {ReturnDocumentTypes.map((ReturnDocumentType) => (
                                <MenuItem
                                    key={ReturnDocumentType.id}
                                    value={ReturnDocumentType.id}
                                    sx={{
                                        mx: 1,
                                        my: 0.5,
                                        borderRadius: 0.75,
                                        typography: 'body2',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {ReturnDocumentType.name}
                                </MenuItem>
                            ))}
                        </RHFSelect>

                        <RHFUploadMultiFile
                            showPreview
                            name="files"
                            maxSize={50000000}
                            onDrop={handleDrop}
                            onRemove={handleRemove}
                            showButtons={false}
                            otherFileFormats={{ 'application/pdf': [] }}
                        />
                    </Stack>
                </Grid>
            </Grid>

        </Grid>
    )
}