import PropTypes from 'prop-types';
import { useEffect, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// @mui
import {
    Grid, Card, Stack, Button, Box, Typography,
    FormHelperText, Divider, Switch, FormGroup,
    FormControlLabel, Tooltip, IconButton
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
// mui
// form
import { useFormContext, useFieldArray } from 'react-hook-form';
// form
// @hooks
import useResponsive from '../../../../../hooks/useResponsive';
import { useSelector } from '../../../../../redux/store';
// @hooks
// @components
import Iconify from '../../../../../components/Iconify';
import { RHFCustomersSearch } from '../../pickups/new/CustomersSearch';
import ScheduleReturnItem from './ScheduleReturnItem';
// import ScheduleReturnContactPerson from './ScheduleReturnContactPerson';
import { RHFTextField } from '../../../../../components/hook-form';
// @components
// utils
import { defaultReturnItem, ReturnDocumentTypes } from './utils';
import { RHFOrderSearch } from './components/RHFSearchOrder';
import { MultiFilePreview } from '../../../../../components/upload';
// utils

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
}));

const LabelStyleSecond = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle1,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
}))

ScheduleReturn.propTypes = {
    returnIndex: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
    returnsFields: PropTypes.array.isRequired,
    removeReturn: PropTypes.func.isRequired,
    setOpenNewCustomerDialog: PropTypes.func.isRequired,
    setAuthReturnIndexUpload: PropTypes.func.isRequired
}
export default function ScheduleReturn({ returnIndex, returnsFields,
    removeReturn, setOpenNewCustomerDialog, setAuthReturnIndexUpload
}) {
    const theme = useTheme();

    const methods = useFormContext();
    const isMdDown = useResponsive('down', 'md');
    const { control, setValue, watch, formState: { errors } } = methods;
    const [queryParameters] = useSearchParams();

    const [connectPreviousDelivery, setConnectPreviousDelivery] = useState(() => {
        const orderId = queryParameters.get('orderId');
        if (orderId && returnIndex === 0) {
            return true
        }
        return false
    });

    const {
        fields: returnItemsFields,
        append: appendReturnItem,
        remove: removeReturnItems,
    } = useFieldArray({
        control,
        name: `returns[${returnIndex}].returns_items`,
    });

    const { singleOrder } = useSelector((state) => state.order);

    const values = watch();

    const handleRemoveReturnItems = useCallback((index) => {
        removeReturnItems(index);
    }, [removeReturnItems])

    const handleAddReturnItem = useCallback(() => {
        appendReturnItem(defaultReturnItem());
    }, [appendReturnItem]);

    const supportingDocumentsErrors = errors.returns && errors?.returns[returnIndex]?.supportingDocuments?.message;

    // Effect that sets the initial value of the inputs when the singleOrder has changed because of having
    useEffect(() => {
        if (returnIndex === 0 && Array.isArray(singleOrder) && singleOrder.length > 0) {

            const order = {
                invoice_no: singleOrder[0]?.invoiceNo,
                customer_name: singleOrder[0]?.customer.name,
                dnote_number: singleOrder[0].dnoteNo,
                id: singleOrder[0].id
            }
            const customer = {
                id: singleOrder[0]?.customer.id,
                address: singleOrder[0]?.customer.address,
                name: singleOrder[0]?.customer.name
            }
            setValue(`returns[${returnIndex}].order`, order);
            setValue(`returns[${returnIndex}].pickup_point`, customer);
            setValue(`returns[${returnIndex}].dnote_no`, singleOrder[0].dnoteNo);
            setValue(`returns[${returnIndex}].invoice_no`, singleOrder[0]?.invoiceNo);
        }
    }, [returnIndex, setValue, singleOrder]);

    const order = watch(`returns[${returnIndex}].order`);

    const onChangPreDelivery = useCallback((e) => {
        setConnectPreviousDelivery(e.target.checked);
    }, []);

    // When the order changes the dnote_no and invoice_no should also change
    useEffect(() => {
        if (order && order.customer_id) {
            const customer = {
                id: order.customer_id,
                name: order.customer_name,
                address: order.customer_address
            }
            setValue(`returns[${returnIndex}].dnote_no`, order.dnote_number);
            setValue(`returns[${returnIndex}].invoice_no`, order.invoice_no);
            setValue(`returns[${returnIndex}].pickup_point`, customer);
        }
    }, [order, returnIndex, setValue]);
    // When the order changes the dnote_no and invoice_no should also change

    // when they connect to previous delivery turns to false then nullify the order form state
    useEffect(() => {
        if (!connectPreviousDelivery) {
            setValue(`returns[${returnIndex}].order`, null);
        }
    }, [connectPreviousDelivery, returnIndex, setValue]);

    return (
        <Grid item xs={12}>
            <Card variant="outline">
                <Grid container>
                    {/* Left Pannel */}
                    <Grid item xs={12} md={3}
                        sx={{ px: 1, bgcolor: 'background.neutral', ...(!isMdDown && { py: 3 }) }}
                    >
                        {isMdDown && (
                            <Stack direction="row" justifyContent="flex-end" sx={{ my: 1 }}>
                                <Button
                                    variant="text"
                                    startIcon={<Iconify icon={'eva:trash-2-outline'} />}
                                    onClick={() => {
                                        removeReturn(returnIndex);
                                    }}
                                    color="error"
                                    disabled={returnsFields.length <= 1}
                                >
                                    Remove Return
                                </Button>
                            </Stack>
                        )}

                        {/*  Pickup Point, Deliver By, Destination */}
                        <Stack direction="column" spacing={5} sx={{ width: 1 }}>

                            <FormGroup>
                                <FormControlLabel
                                    label={
                                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                                            Previous Delivery
                                            <Tooltip
                                                title="With this setting enabled, you can search for your previous Senga delivery associated with this return order and autofill the fields below."
                                                placement="top"
                                            >
                                                <IconButton>
                                                    <Iconify icon={'ph:info-fill'} width={22} height={24}
                                                        color={theme.palette.primary.main}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    }
                                    control={<Switch checked={connectPreviousDelivery} onChange={onChangPreDelivery} />}
                                />
                                {/* 
                                    <FormHelperText>
                                        With this setting enabled, you can search for your previous Senga delivery associated with this return order and autofill the fields below
                                    </FormHelperText> 
                                */}
                            </FormGroup>

                            {/* Previous Order */}
                            {
                                connectPreviousDelivery && (
                                    <Box>
                                        <RHFOrderSearch
                                            label="Search Previous Delivery"
                                            width={'100%'}
                                            focusstretch={0}
                                            name={`returns[${returnIndex}].order`}
                                        />
                                    </Box>
                                )
                            }
                            {/* Previous Order */}

                            {/* Search Pickup Point */}
                            <Box id={'dropoffPointSearchInput'}>
                                <RHFCustomersSearch
                                    label="Pickup Point"
                                    width={'100%'}
                                    focusstretch={0}
                                    name={`returns[${returnIndex}].pickup_point`}
                                    openAddNewCustomerDialog={(searchQuery) => {
                                        setOpenNewCustomerDialog({ index: `${returnIndex}`, searchQuery });
                                    }}
                                />
                            </Box>
                            {/* Search Pickup Point */}

                            {/* Delivery Note Number */}
                            <RHFTextField
                                label="Delivery Note Number"
                                name={`returns[${returnIndex}].dnote_no`}
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                id="dnoteNo"
                                disabled={!!order}
                            />
                            {/* Delivery Note Number */}

                            {/* Invoice No  */}
                            <RHFTextField
                                label="Invoice No"
                                name={`returns[${returnIndex}].invoice_no`}
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                id="invoiceNo"
                                disabled={!!order}
                            />
                            {/* Invoice No */}

                            {/* GRN No  */}
                            <RHFTextField
                                label="GRN Number"
                                name={`returns[${returnIndex}].grn_no`}
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                id="grnNo"
                            />
                            {/* GRN No */}

                            {/* Authorized By */}
                            <RHFTextField
                                label="Authorized By"
                                name={`returns[${returnIndex}].authorized_by`}
                                InputLabelProps={{ shrink: true, placeholder: "Full Name" }}
                                size="small"
                                id="grnNo"
                            />
                            {/* Authorized By */}

                        </Stack>
                        {/*  */}
                    </Grid>
                    {/* Left Pannel */}


                    {/* Right Pannel */}
                    <Grid item xs={12} md={9} sx={{ py: isMdDown ? 3 : 0 }}>
                        <Grid container>
                            {!isMdDown && (
                                <Grid item md={12} container justifyContent="flex-end" px={2} py={1}>
                                    <Button
                                        variant="text"
                                        startIcon={<Iconify icon={'eva:trash-2-outline'} />}
                                        onClick={() => {
                                            removeReturn(returnIndex);
                                        }}
                                        color="error"
                                        disabled={returnsFields.length <= 1}
                                        id="removeReturnutton"
                                    >
                                        Remove Return
                                    </Button>
                                </Grid>
                            )}

                            {/* Return Items */}
                            <Grid item md={12}>
                                <Grid container>
                                    {/* Return Items Title */}
                                    <Grid item xs={12} sx={{ px: 3, py: 0 }}>
                                        <Grid container>
                                            <Grid item xs={12} sm={4}>
                                                <LabelStyleSecond>
                                                    Return Items
                                                </LabelStyleSecond>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* Returns Items Title */}

                                    {/* Return Items */}
                                    {
                                        returnItemsFields.map((returnItemField, returnItemIndex) => (
                                            <ScheduleReturnItem
                                                key={returnItemField.id}
                                                returnItemField={returnItemField}
                                                returnItemsFields={returnItemsFields}
                                                returnIndex={returnIndex}
                                                returnItemIndex={returnItemIndex}
                                                handleRemoveReturnItems={() => handleRemoveReturnItems(returnItemIndex)}
                                                handleAddReturnItem={handleAddReturnItem}
                                            />
                                        ))
                                    }
                                    {/* Return Items */}
                                </Grid>
                                <Divider sx={{ borderStyle: 'dashed' }} />
                            </Grid>
                            {/* Return Items */}

                            {/* Authorization Documents */}
                            <Grid item md={12} sx={{ py: 2 }}>
                                <Stack direction="column" sx={{ mx: 2.7, my: 1 }}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                                        <LabelStyleSecond>
                                            Authorization Documents
                                        </LabelStyleSecond>
                                        {/*  Upload Authorization Documents */}
                                        <Button startIcon={<Iconify icon="mingcute:upload-3-fill" />}
                                            onClick={() => { setAuthReturnIndexUpload(returnIndex) }}
                                        >
                                            Upload Authorization Documents
                                        </Button>
                                        {/* Upload Authorization Documents */}
                                    </Stack>

                                    {values.returns[returnIndex].supportingDocuments && values.returns[returnIndex].supportingDocuments.length > 0 && (
                                        values.returns[returnIndex].supportingDocuments.map((supportingDocument, index) => (
                                            <Stack directon="column" key={index} spacing={1}>
                                                <LabelStyle>
                                                    {
                                                        `${ReturnDocumentTypes.find((returnDocumentType) => returnDocumentType.id === supportingDocument.document)?.name
                                                        ||
                                                        "Documents"
                                                        }`
                                                    }
                                                </LabelStyle>

                                                <MultiFilePreview
                                                    showPreview
                                                    files={supportingDocument.files}
                                                    onRemove={(file) => {
                                                        const existingSupportingDocuments = values.returns[returnIndex].supportingDocuments;
                                                        const remainingDocuments = []
                                                        existingSupportingDocuments.forEach((existingSupportingDocument) => {
                                                            if (existingSupportingDocument.document !== supportingDocument.document) {
                                                                remainingDocuments.push(existingSupportingDocument);
                                                            } else {
                                                                const obj = {
                                                                    ...existingSupportingDocument,
                                                                    files: existingSupportingDocument.files.filter((existingFile) => (
                                                                        file.preview !== existingFile.preview
                                                                    ))
                                                                }
                                                                if (obj.files.length >= 1) {
                                                                    remainingDocuments.push(obj);
                                                                }
                                                            }
                                                        });
                                                        setValue(`returns[${returnIndex}].supportingDocuments`, remainingDocuments);
                                                    }}
                                                />
                                            </Stack>
                                        ))
                                    )}

                                    {
                                        supportingDocumentsErrors && (
                                            <FormHelperText error> {supportingDocumentsErrors}</FormHelperText>
                                        )
                                    }
                                </Stack>
                                <Divider sx={{ borderStyle: 'dashed' }} />
                            </Grid>
                            {/* Authorization Documents */}

                            {/* Note */}
                            <Grid item md={12} sx={{ py: 2 }}>
                                <Stack direction="column" sx={{ mx: 2.7, mb: 1 }}>
                                    <LabelStyleSecond>
                                        Note (optional)
                                    </LabelStyleSecond>

                                    <Grid container md={12}>
                                        <Grid item md={4} container sx={{ minHeight: '170px', borderRadius: '10px' }}>
                                            <RHFTextField multiline rows={8} cols={10} placeholder="Type your note here"
                                                name={`returns[${returnIndex}].notes`}
                                                sx={{ backgroundColor: 'transparent', border: "none" }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>
                                <Divider sx={{ borderStyle: 'dashed' }} />
                            </Grid>
                            {/* Note */}

                            {/* Contact People
                            <Grid item md={12}>
                                <Grid container>
                                    Contact People Title
                                    <Grid item xs={12} sx={{ px: 3, py: 0 }}>
                                        <Grid container>
                                            <Grid item xs={12} sm={4}>
                                                <Typography variant="h6" sx={{ color: 'text.disabled' }}>
                                                    Contact People:
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    Contact People Title

                                    Contact People
                                    {
                                        returnContactPeopleFields.map(
                                            (returnContactPersonField, returnContactPersonIndex) => (
                                                <ScheduleReturnContactPerson
                                                    key={returnContactPersonField.id}
                                                    returnContactPeopleFields={returnContactPeopleFields}
                                                    returnContactPersonIndex={returnContactPersonIndex}
                                                    handleRemoveReturnContactPerson={() => { }}
                                                    appendReturnContactPerson={() => { }}
                                                />
                                            )
                                        )
                                    }
                                    Contact People
                                </Grid>
                            </Grid>
                            Contact People */}
                        </Grid>

                    </Grid>
                    {/* Right Pannel */}
                </Grid>
            </Card>
        </Grid>
    )
}