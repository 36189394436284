import { ListItemButton, Skeleton, Stack, Typography } from '@mui/material';

export default function SkeletonCustomerListing() {
    return (
        <ListItemButton>
            <Skeleton animation="wave" width="100%" style={{ marginBottom: 6 }}>
                <Stack direction="row">
                    <Typography
                        component="span"
                        variant="subtitle2"
                        sx={{ pr: 0.07 }}
                    >
                        <Skeleton animation="wave" />
                    </Typography>
                </Stack>

                <Typography variant="caption" sx={{ color: 'text.secondary', my: 0.5, fontWeight: 'fontWeightMedium' }}>
                    <Skeleton animation="wave" />
                </Typography>
            </Skeleton>
        </ListItemButton>

    )
}