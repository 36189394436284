import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, IconButton, Typography, CardContent } from '@mui/material';
// utils
import { fDate } from '../../../../../utils/formatTime';
import cssStyles from '../../../../../utils/cssStyles';
// components
import Image from '../../../../../components/Image';
import Iconify from '../../../../../components/Iconify';
import LightboxModal from '../../../../../components/LightboxModal';

// ----------------------------------------------------------------------

const CaptionStyle = styled(CardContent)(({ theme }) => ({
  ...cssStyles().bgBlur({ blur: 2, color: theme.palette.grey[900] }),
  bottom: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  justifyContent: 'space-between',
  color: theme.palette.common.white,
}));

// ----------------------------------------------------------------------

ReturnNoteImages.propTypes = {
  gallery: PropTypes.array.isRequired,
};

export default function ReturnNoteImages({ gallery }) {
  const [openLightbox, setOpenLightbox] = useState(false);

  const [selectedImage, setSelectedImage] = useState(0);

  const imagesLightbox = gallery.map((img) => img.preview);

  const handleOpenLightbox = (url) => {
    const selectedImage = imagesLightbox.findIndex((index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };
  return (
    <Box sx={{ mt: 5 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Return Note Document(s)
      </Typography>

      <Card sx={{ p: 3 }}>
        <Box
          sx={{
            display: 'grid',
            wrap: 'wrap',
            flexWrap: 'wrap',
            gap: 3,
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
            },
          }}
        >
          {gallery.map((image, index) => (
            <DocumentsItem key={index} image={image} onOpenLightbox={handleOpenLightbox} />
          ))}
        </Box>

        <LightboxModal
          images={imagesLightbox}
          mainSrc={imagesLightbox[selectedImage]}
          photoIndex={selectedImage}
          setPhotoIndex={setSelectedImage}
          isOpen={openLightbox}
          onCloseRequest={() => setOpenLightbox(false)}
        />
      </Card>
    </Box>
  );
}

// ----------------------------------------------------------------------

DocumentsItem.propTypes = {
  image: PropTypes.object,
  onOpenLightbox: PropTypes.func,
};

function DocumentsItem({ image, onOpenLightbox }) {
  const { preview, path } = image;
  // const { preview, path, postAt } = image;
  return (
    <Card sx={{ cursor: 'pointer', position: 'relative' }}>
      <Image alt="gallery image" ratio="1/1" src={preview} onClick={() => onOpenLightbox(preview)} />

      <CaptionStyle>
        <div>
          <Typography variant="subtitle1">{path}</Typography>
          {/* <Typography variant="body2" sx={{ opacity: 0.72 }}>
            {fDate(postAt)}
          </Typography> */}
        </div>
        {/* <IconButton color="inherit">
          <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
        </IconButton> */}
      </CaptionStyle>
    </Card>
  );
}
