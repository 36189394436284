import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Typography } from '@mui/material';
// import { makeStyles } from '@mui/styles';
import { Timeline, TimelineDot, TimelineItem, TimelineContent, 
    TimelineSeparator, TimelineConnector
} from '@mui/lab';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

// utils
import { fDate } from '../../../../utils/formatTime';


OrderTimelines.propTypes = {
    order: PropTypes.object
}

export default function OrderTimelines({ order, filterStatus }){
 

    return (
        <Timeline position="alternate">
            { order?.pickupDate && (
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                        {fDate(order.pickupDate)}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot   color={'warning'} />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent> Pickup Date</TimelineContent>
                </TimelineItem>
            )}
            { (order?.expectedDeliveryDate || order?.deliveryDate) && (
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                        {fDate(order.expectedDeliveryDate || order.deliveryDate) }
                    </TimelineOppositeContent>
                    <TimelineSeparator>    
                      <TimelineDot color={'info'} />  
                      { order?.actualDeliveryDate && ( <TimelineConnector />)}
                    </TimelineSeparator>
                    <TimelineContent>Expected Delivery Date</TimelineContent>
                </TimelineItem>
            )}
            { order?.actualDeliveryDate && (
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                      {fDate(order.actualDeliveryDate)}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot   color={'success'} />  
                    </TimelineSeparator>
                    <TimelineContent>Actual Delivery Date</TimelineContent>
                </TimelineItem>
            )}
        </Timeline>
    )
}
