// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import Iconify from '../../../components/Iconify';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  cardboardBox: getIcon('ic_cardboard_box'),
  dnote: getIcon('dnote'),
  track: getIcon('track'),
  schedule: getIcon('schedule'),
  scheduleReturn: getIcon('schedule_return'),
  invoicing: getIcon('invoicing'),
  myPickupRequests: getIcon('my_pickup_requests'),
  settings: getIcon('settings'),
  returnTracking: getIcon('return-tracking-fill'),
  returnManagement: getIcon('return-management'),
  trackDeliveries: getIcon('track-deliveries'),
  faqs: getIcon('faqs'),
  generateFinancialStatement: getIcon('financial-statement-icon'),
};

const navConfig = [
  {
    subheader: 'Schedule',
    items: [
      {
        title: 'Schedule a Pickup',
        path: PATH_DASHBOARD.tms.shippers.schedulePickup('formView'),
        icon: ICONS.schedule,
      },
      {
        title: 'Schedule a Return',
        path: PATH_DASHBOARD.tms.returns.scheduleReturns(null),
        icon: ICONS.scheduleReturn,
      },
    ],
  },
  {
    subheader: 'Tracking',
    items: [
      {
        title: 'Track My Orders',
        path: PATH_DASHBOARD.tms.deliveries.deliveryTracking,
        icon: ICONS.trackDeliveries,
      },
      {
        title: 'Track My Returns',
        path: PATH_DASHBOARD.tms.returns.deliveries,
        icon: ICONS.returnTracking,
      },
    ],
  },
  {
    subheader: 'Pickups',
    items: [
      {
        title: 'My Pickup Requests',
        path: PATH_DASHBOARD.tms.shippers.pickupRequest('kanban'),
        icon: ICONS.myPickupRequests,
      },
      {
        title: 'Pickup Schedule',
        path: PATH_DASHBOARD.tms.pickupSchedule,
        icon: ICONS.calendar,
      },
    ],
  },
  {
    subheader: 'Delivery Notes',
    items: [
      {
        title: 'Manage My Delivery Notes',
        path: PATH_DASHBOARD.tms.shippers.deliveryNotes,
        icon: ICONS.dnote,
      },
    ],
  },
  {
    subheader: 'Data Extraction',
    items: [
      {
        title: 'Document Scanning',
        path: PATH_DASHBOARD.tms.modelInferencing,
        icon: <Iconify icon="arcticons:document-scanner" width="0.8" height="0.8" />,
      },
    ],
  },
  {
    subheader: 'Returns',
    items: [
      {
        title: 'Manage My Returns',
        path: PATH_DASHBOARD.tms.returns.deliveriesManagement,
        icon: ICONS.returnManagement,
      },
    ],
  },
  {
    subheader: 'Billing',
    items: [
      {
        title: 'Statement of Account',
        path: PATH_DASHBOARD.tms.generateFinancialStatement,
        icon: ICONS.generateFinancialStatement,
      },
    ],
  },
  {
    subheader: 'Customers',
    items: [
      {
        title: 'My Customers',
        path: PATH_DASHBOARD.tms.customers.list,
        icon: ICONS.user,
      },
    ],
  },
  {
    subheader: 'Reports',
    items: [
      {
        title: 'Customer Analytics',
        icon: ICONS.analytics,
        path: PATH_DASHBOARD.tms.customerGraph,
      },
    ],
  },
  {
    subheader: 'Account Management',
    items: [
      {
        title: 'User',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'Create', path: PATH_DASHBOARD.user.new },
          { title: 'List', path: PATH_DASHBOARD.user.list },
        ],
      },
      {
        title: 'Account',
        path: PATH_DASHBOARD.user.account,
        icon: ICONS.settings,
      },
    ],
  },
  {
    subheader: 'Feedback',
    items: [
      {
        title: 'Feedback',
        path: PATH_DASHBOARD.tms.feedback,
        icon: ICONS.chat,
      },
    ],
  },
  {
    subheader: 'faqs',
    items: [
      {
        title: 'FAQs',
        path: PATH_DASHBOARD.tms.faqs,
        icon: ICONS.faqs,
      },
    ],
  },
];

export default navConfig;
