/**
 *
 * 
 * Actions & Reducers for shipper returns
 * 
 *  
*/
import * as changeCase from "change-case";
import dayjs from 'dayjs';
import { deserialize } from 'deserialize-json-api';
import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import { dispatch } from "../store";
import { fDateForPersistence } from "../../utils/formatTime";

export const RETURNS_PAGE_FROM_DATE_KEY = "RETURNS_PAGE_FROM_DATE_KEY"
export const RETURN_PAGE_TO_DATE_KEY = "RETURN_PAGE_TO_DATE_KEY"

// Initial state for the returns page
const initialState = {
    isLoading: false,
    error: null,
    returns: [],
    returnReasons: [],
    editReturns: {}
};


// Shipper Returns slice
const slice = createSlice({
    name: 'returns',
    initialState,
    reducers: {
        // Start Loading
        startLoading(state) {
            state.isLoading = true
        },
        // STOP LOADING
        stopLoading(state) {
            state.isLoading = false;
        },
        // Check for erros
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },
        // Fetch the return document references
        getReturnsSuccess(state, action) {
            state.isLoading = false
            state.returns = action.payload
        },
        // GET RETURNS Edit Success
        getEditReturnsSuccess(state, action) {
            state.isLoading = false
            state.editReturns = { ...action.payload }
        },
        // GET RETURN REASONS SUCCESS
        getReturnReasonsSuccess(state, action) {
            state.isLoading = false;
            state.returnReasons = action.payload.map((reason) => ({
                id: reason.id,
                label: reason.reason
            }));
        },
        // GET INVENTORIES SUCCESS
        getInventoriesSuccess(state, action) {
            state.isLoading = false;
            state.returnInventories = action.payload.map((returnInventory) => ({
                ...returnInventory
            }));
        },
        // Update cancellation success
        cancelReturnsSuccess(state, action) {
            state.isLoading = false;
            const existingReturns = [...state.returns];
            const newReturns = existingReturns.map((returnDelivery) => {
                if (returnDelivery.id.toString() === action.payload.returnDocReferenceId.toString()) {
                    returnDelivery.status = ["Cancelled"];
                }
                return returnDelivery;
            });
            state.returns = newReturns;
        }
    }
})

// Actions
export const {
    getEditReturnsSuccess,
    cancelReturnsSuccess
} = slice.actions

// Reducer for a shipper's returns
export default slice.reducer

export function getShipperReturns({ shipperId, fromDate, toDate }) {
    return async () => {
        dispatch(slice.actions.startLoading())
        try {
            let pathName = `/api/v1/shipper/${shipperId}/returns/docs`
            if (toDate && fromDate) {
                const pickupStartDate = dayjs(fromDate).format('YYYY-MM-DD');
                const pickupEndDate = dayjs(toDate).format('YYYY-MM-DD');
                pathName = `${pathName}?start_date=${pickupStartDate}&end_date=${pickupEndDate}`;
            } else {
                // minus seven days. Default to current date minus four days
                const pickupStartDate = dayjs(new Date(new Date().setDate((new Date().getDate() - 7)))).format('YYYY-MM-DD');
                const pickupEndDate = dayjs(new Date()).format('YYYY-MM-DD');
                pathName = `${pathName}?start_date=${pickupStartDate}&end_date=${pickupEndDate}`;
            }
            // Execute the get request
            const response = await axiosInstance.get(pathName)
            // Reshape the response
            const returns = response.data.data.map((r) => ({
                id: r?.attributes?.id || "",
                dnoteNumber: r?.attributes?.dnote_number || "",
                isPhysicallyPresent: r?.attributes?.is_physically_present || "",
                returnDate: r?.attributes?.return_date || "",
                shipperName: r?.attributes?.shipper_name || "",
                shipperID: r?.attributes?.shipper_id || "",
                orderID: r?.attributes?.order_id || "",
                customerID: r?.attributes?.customer_id || "",
                customerName: r?.attributes?.customer_name || "",
                waybillNumber: r?.attributes?.waybill_number || "",
                numberOfReturns: r?.attributes?.number_of_returns || "",
                townID: r?.attributes?.town_id || "",
                townName: r?.attributes?.town_name || "",
                status: [formatReturnStatuses(r.attributes.return_doc_status)],
                returnConfirmationImages: r?.attributes?.return_confirmation_images?.data || [],
                returnSupportingDocuments: r?.attributes?.return_supporting_documents || []
            }))
            // Dispatch the response
            dispatch(slice.actions.getReturnsSuccess(returns))

        } catch (err) {
            dispatch(slice.actions.hasError(err))
        }
    }
}
// submit returns
export function createReturns({ shipperId, payload }) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const pathName = `/api/v1/shipper/${shipperId}/returns`;
            const response = await axiosInstance.post(pathName, payload);
            dispatch(slice.actions.stopLoading());
            return response;
        } catch (err) {
            dispatch(slice.actions.hasError(err));
            throw new Error('There was problem scheduling your return request!');
        }
    }
}

// retrieves returns to edit
export function getEditReturns({ orderId, returnDocReferenceId }) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let pathName;
            if (typeof (orderId) === 'string' && !!orderId.trim().length) {
                pathName = `/api/v1/shipper/returns/previous_orders/${returnDocReferenceId}`;
            } else {
                pathName = `/api/v1/shipper/returns/${returnDocReferenceId}`;
            }
            const response = await axiosInstance.get(pathName);
            const result = deserialize(response.data).data;
            dispatch(getEditReturnsSuccess(result));
        } catch (err) {
            dispatch(slice.actions.hasError(err))
        }
    }
}
// response.data
export function formatReturnPayload(returnPayload) {
    const returns = {
        id: returnPayload.attributes.id,
        dnoteNumber: returnPayload.attributes.dnote_number,
        isPhysicallyPresent: returnPayload.attributes.is_physically_present,
        returnDate: returnPayload.attributes.return_date,
        reportDate: returnPayload.attributes.report_date,
        shipperName: returnPayload.attributes.shipper_name,
        shipperID: returnPayload.attributes.shipper_id,
        orderID: returnPayload.attributes.order_id,
        customerID: returnPayload.attributes.customer_id,
        customerName: returnPayload.attributes.customer_name,
        waybillNumber: returnPayload.attributes.waybill_number,
        numberOfReturns: returnPayload.attributes.number_of_returns,
        townID: returnPayload.attributes.town_id,
        approvedStatus: returnPayload.attributes.approved_status,
        townName: returnPayload.attributes.town_name,
        invoiceNo: returnPayload.attributes.invoice_no,
        grnNo: returnPayload.attributes.grn_no,
        status: [formatReturnStatuses(returnPayload.attributes.return_doc_status)],
        returnConfirmationImages: returnPayload?.attributes?.return_confirmation_images?.data || [],
        returnSupportingDocuments: returnPayload?.attributes?.return_supporting_documents || []
    };
    return returns;
}

// Action used to fetch a shipper's returns
export function getReturns({ fromDate, toDate, shipperID }) {
    return async () => {
        dispatch(slice.actions.startLoading())
        // Initialize the date regardless
        initReturnsDateRange()
        try {
            // Updte the from date if necessary
            if (fromDate !== undefined) {
                // Update the local storage from date
                setReturnPageFromDate(fromDate)
            }

            // Update the to date if necessary
            if (toDate !== undefined) {
                // Update the local storage to date
                setReturnPageToDate(toDate)
            }

            const __toDate = returnPageToDate()
            const __fromDate = returnPageFromDate()

            // Path to return docs
            const pathName = `/api/v1/shipper/${shipperID}/returns/docs?start_date=${__fromDate}&end_date=${__toDate}`
            // Execute the get request
            const response = await axiosInstance.get(pathName);
            const returns = response.data.data.map((returnPayload) => (
                formatReturnPayload(returnPayload)
            ));
            // Dispatch the response
            dispatch(slice.actions.getReturnsSuccess(returns))
        } catch (err) {
            console.error(err);
            dispatch(slice.actions.hasError(err))
        }
    }
}

export function initReturnsDateRange() {
    let fromDate = localStorage.getItem(RETURNS_PAGE_FROM_DATE_KEY)

    if (fromDate === undefined || fromDate === null) {
        // Set the from Date
        const _fromDate = new Date()
        const daysDifference = 7
        _fromDate.setDate(_fromDate.getDate() - daysDifference)
        fromDate = fDateForPersistence(_fromDate)
        setReturnPageFromDate(fromDate)
    }

    let toDate = localStorage.getItem(RETURN_PAGE_TO_DATE_KEY)

    if (toDate === undefined || toDate === null) {
        toDate = Date.now()
        setReturnPageToDate(fDateForPersistence(toDate))
    }
}

export function getReturnReasons() {
    return async () => {
        try {
            const pathName = `/api/v1/returns/reasons`;
            dispatch(slice.actions.startLoading());
            const response = await axiosInstance.get(pathName);
            const { data } = response;
            const receivedData = deserialize(data);
            dispatch(slice.actions.getReturnReasonsSuccess(receivedData.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function getInventories({ shipperId, searchVal }) {
    return async () => {
        try {
            const pathName = `/api/v1/shipper/inventories/${shipperId}?search_val=${searchVal}`;
            dispatch(slice.actions.startLoading());
            const response = await axiosInstance.get(pathName);
            const { data } = response;
            const receivedData = deserialize(data);
            dispatch(slice.actions.getInventoriesSuccess(receivedData.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

const createPayload = (returns) => (
    returns.map((returnOrder) => {
        const payload = {
            ...returnOrder,
            returns_items: returnOrder.returns_items.map((returnItem) => ({
                ...returnItem,
                item_description: {
                    ...returnItem.item_description,
                    description: returnItem.item_description.inputValue || returnItem.item_description.description
                },
                return_reason: {
                    ...returnItem.return_reason,
                    label: returnItem.return_reason.inputValue || returnItem.return_reason.label
                },
                units: changeCase.sentenceCase(returnItem.units.id)
            }))
        }
        if (returnOrder.notes) {
            payload.notes = [{ text: returnOrder.notes }];
        }
        return payload;
    })
)

export function scheduleReturns({ shipperId, returns }) {
    return async () => {
        try {
            dispatch(slice.actions.startLoading());
            const pathName = `/api/v1/shipper/returns`;
            const returnsPayload = createPayload(returns);
            const payload = { return: { shipper_id: shipperId, returns: returnsPayload } }
            const response = await axiosInstance.post(pathName, payload);
            dispatch(slice.actions.stopLoading());
            return response
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            throw new Error('There was problem scheduling your return request!');
        }
    }
}

export function updateScheduledReturns({ shipperId, data, returnDocReferenceId }) {
    return async () => {
        try {
            dispatch(slice.actions.startLoading());
            const pathName = `/api/v1/shipper/returns/${returnDocReferenceId}/edit`;
            const returnsPayload = createPayload(data.returns);
            const payload = { return: { shipper_id: shipperId, returns: returnsPayload } }
            const response = await axiosInstance.patch(pathName, payload);
            dispatch(slice.actions.stopLoading());
            return response
        } catch (error) {
            if (Array.isArray(error.errors) && !!error.errors.length) {
                throw new Error(error.errors[0]);
            } else {
                throw new Error('There was problem updating the returns!');
            }
        }
    }
}

export function uploadSupportingDocuments({ returnDocReferenceId, supportingDocuments }) {
    return async () => {
        try {
            const pathName = `/api/v1/returns/docs/${returnDocReferenceId}/supporting_documents`;
            const formDataAuthorizationFiles = new FormData()
            supportingDocuments.forEach((supportingDocument) => {
                const { files, document, return_supporting_document_id: returnSupportingId } = supportingDocument;
                files.forEach((file) => {
                    if (file instanceof File) {
                        formDataAuthorizationFiles.append(`supporting_documents[${document}][files][]`, file);
                    } else if (!(file instanceof File)) {
                        const json = JSON.stringify(file)
                        const blobFile = new Blob([json], { type: 'application/json' });
                        formDataAuthorizationFiles.append(`supporting_documents[${document}][files][]`, blobFile);
                    }
                });
                if (returnSupportingId) {
                    formDataAuthorizationFiles.append(`supporting_documents[${document}][return_supporting_document_id]`, returnSupportingId);
                }
            });
            return axiosInstance.post(pathName, formDataAuthorizationFiles, { headers: { 'content-type': 'multipart/form-data' } });
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
            throw new Error('There was problem upload authorization files!');
        }
    }
}

export function cancelReturnDelivery({ returnDocReferenceIdForDeletion }) {
    return async () => {
        try {
            dispatch(slice.actions.startLoading());
            const pathName = `/api/v1/shipper/returns/${returnDocReferenceIdForDeletion}/cancel`;
            const response = await axiosInstance.delete(pathName);
            const { return_doc_reference_id: returnDocReferenceId } = response.data.data;
            dispatch(cancelReturnsSuccess({ returnDocReferenceId }));
        } catch (error) {
            const errMessage = error.errors[0] || "There was problem cancelling your return delivery";
            throw new Error(errMessage);
        }
    }
}

// Fetch the return page from & to date
export const returnPageFromDate = () => {
    const fromDate = localStorage.getItem(RETURNS_PAGE_FROM_DATE_KEY)
    if (fromDate === undefined) {
        initReturnsDateRange()
        return localStorage.getItem(RETURNS_PAGE_FROM_DATE_KEY)
    }

    return fromDate
}

export const returnPageToDate = () => {
    const toDate = localStorage.getItem(RETURN_PAGE_TO_DATE_KEY)
    if (toDate === undefined) {
        initReturnsDateRange()
        return localStorage.getItem(RETURN_PAGE_TO_DATE_KEY)
    }

    return toDate
}

// Set the return page from date & to date
const setReturnPageFromDate = (fromDate) => localStorage.setItem(RETURNS_PAGE_FROM_DATE_KEY, fromDate)
const setReturnPageToDate = (toDate) => localStorage.setItem(RETURN_PAGE_TO_DATE_KEY, toDate)

/**
 * 
 * 
 * Function to format the return document references
 * 
 * 
*/

export function formatReturnStatuses(returnStatuses) {
    if (returnStatuses === undefined || returnStatuses.length === 0) return ""

    if (returnStatuses.length === 1) return returnStatuses[0]

    if (returnStatuses.includes("Received By Shipper")) return "Received By Shipper"

    if (returnStatuses.includes("Rejected")) return "Rejected"

    if (returnStatuses.includes("Completed")) return "Completed"

    if (returnStatuses.includes("Dispatched")) return "Dispatched"

    if (returnStatuses.includes("In-Office")) return "In-Office"

    return "Reported"
}
