export default function defaultEditReturn(returnOrder) {
    const defaultReturnOrder = {
        invoice_no: returnOrder.invoice_no,
        grn_no: returnOrder.grn_no,
        dnote_no: returnOrder.dnote_number,
        returns_items: returnOrder.return_items,
        authorized_by: returnOrder.authorized_by,
        pickup_point: {
            address: returnOrder.pickup_point.address,
            googlePlaceId: returnOrder.pickup_point.google_place_id,
            name: returnOrder.pickup_point.customer,
            id: returnOrder.pickup_point.id
        },
        notes: returnOrder.notes[0],
        supportingDocuments: returnOrder.supportingDocuments || []
    }
    if (returnOrder.previous_delivery) {
        defaultReturnOrder.order = {
            invoice_no: returnOrder.invoice_no,
            customer_name: returnOrder.pickup_point.customer,
            dnote_no: returnOrder.dnote_number,
            grn_no: returnOrder.grn_no,
            id: returnOrder.id
        }
    }
    return defaultReturnOrder;
}