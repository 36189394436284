import { createSlice } from '@reduxjs/toolkit';
// utils

import { deserialize } from 'deserialize-json-api';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'pickupSchedule',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload.map((event) => ({
        title: event.eventClass === 'repeat-event'? 'Regular Pickup' : 'Special Pickup',
        id: event.id,
        description: event.description,
        daysOfWeek: event.daysOfWeek,
        start: event.start,
        textColor: event.textColor

      }));
    },
   

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = state.events.filter((event) => event.id !== eventId);
      state.events = deleteEvent;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },


    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getEvents(styleTheme) {
  const theme = styleTheme
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/v1/pickup_schedule/events');
      const { data } = response;
      const events = deserialize(data).data.map((event) => ({
        id: event.id,
        description: event.towns_list,
        daysOfWeek: event.day,
        start: event.start,
        eventClass: event.event_class,
        textColor: event.event_class === 'repeat-event'? theme.palette.primary.dark : theme.palette.primary.light }))

      dispatch(slice.actions.getEventsSuccess(events));


    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}





