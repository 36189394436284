import * as Yup from 'yup';

export const NewReturnSchema = Yup.object().shape({
    returns: Yup.lazy(() => (
        Yup.array().of(
            Yup.object({
                pickup_point: Yup.object().shape({
                    address: Yup.string(),
                    googlePlaceId: Yup.string(),
                    name: Yup.string(),
                    id: Yup.mixed()
                }).nullable(true).required('Please select a pickup point'),
                invoice_no: Yup.string().nullable(true),
                grn_no: Yup.string().nullable(true),
                dnote_no: Yup.string().nullable(true),
                notes: Yup.string().nullable(true),
                authorized_by: Yup.string().nullable(true).required("Please fill in the name of the authorizer"),
                returns_items: Yup.lazy(() => (
                    Yup.array().of(
                        Yup.object({
                            quantity: Yup.number('Please fill in a whole number greater than zero')
                                .typeError('Please fill in a number greater than zero')
                                .required('Please fill in a whole number greater than zero')
                                .positive('Please fill in a whole number greater than zero')
                                .integer('Please fill in a whole number greater than zero'),
                            units: Yup.object().shape({
                                description: Yup.string(),
                                id: Yup.mixed()
                            }).nullable(true).required('Please select an unit'),
                            return_reason: Yup.object().shape({
                                id: Yup.mixed(),
                                label: Yup.string()
                            }).nullable(true).required('Please select a return reason'),
                            item_description: Yup.object().shape({
                                description: Yup.string(),
                                id: Yup.mixed()
                            }).nullable(true).required('Please select an item description')
                        })
                    )
                )),
                supportingDocuments: Yup.lazy(() => (
                    Yup.array().required('Please upload at least one ')
                        .min(1, 'Please upload at least one ')
                        .of(
                            Yup.object({
                                document: Yup.string().required("Please specify the document"),
                                files: Yup.array().required('Please upload at least one supporting file')
                                    .min(1, 'Please upload at least one supporting file')
                            })
                        )
                ))
            })
        )
    ))
})