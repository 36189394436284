import { styled } from '@mui/material/styles';
import { Chip } from '@mui/material';

const BetaChipStyle = styled(Chip)(() => ({
  color: '#637381',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  fontSize: '1 rem',
}));

export default BetaChipStyle;
