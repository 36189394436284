import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';

import { Box, Stack, AppBar, Toolbar, Grid} from '@mui/material';
import dayjs from 'dayjs';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import Banner from './Banner';
import NotificationsPopover from './NotificationsPopover';


// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
  bannerMessage: PropTypes.string,
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false, bannerMessage }) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive('up', 'lg');
  const mdUp = useResponsive('up', 'md');
  
  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          marginTop: 2,
          px: { lg: 5 },
        }}
      >
        <Grid container spacing={0.5}>
          <Grid item xs={12} display="flex" >
            {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}
            {!isDesktop && (
              <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
                <Iconify icon="eva:menu-2-fill" />
              </IconButtonAnimate>
            )}

            <Searchbar />

            <Box sx={{ flexGrow: 1 }} />

            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
              <LanguagePopover />
              {/* {mdUp &&  <ToolBarIconDelivery icon={'carbon:delivery-add'} path={PATH_DASHBOARD.tms.shippers.schedulePickup} />} */}
              {/* {mdUp && <ToolBarIconDelivery icon={'clarity:map-marker-outline-badged'} path={PATH_DASHBOARD.tms.deliveries.deliveryTracking}/>} */}
              {/* {mdUp &&  <ToolBarIconDelivery icon={'grommet-icons:notes'} path={PATH_DASHBOARD.tms.shippers.deliveryNotes}/>} */}

              <NotificationsPopover />
              {/* <ContactsPopover /> */}
              <AccountPopover />
            </Stack>
          </Grid>
          { bannerMessage && bannerMessage.trim().length !== 0  &&
              <Grid item xs={12}>
                <Stack sx={{ width: '100%' }}>
                  <Banner message = {bannerMessage}/>
                </Stack>
            </Grid>
          }   
        </Grid>
    </Toolbar>
    </RootStyle>
  );
}