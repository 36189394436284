import PropTypes from 'prop-types';
import { useState, useMemo } from 'react';
// @mui
import { Document as MyDocument, Page, pdfjs } from 'react-pdf';
import { styled } from '@mui/material/styles';
import { Box, Card, Typography, CardContent } from '@mui/material';
// utils
import cssStyles from '../../../../utils/cssStyles';
// components
import Image from '../../../../components/Image';
import { TableNoData } from '../../../../components/table';
import LightboxModal from '../../../../components/LightboxModal';

// import "../../dnote/details/DeliveryNoteImages.css";
// ----------------------------------------------------------------------

const CaptionStyle = styled(CardContent)(({ theme }) => ({
  ...cssStyles().bgBlur({ blur: 2, color: theme.palette.grey[900] }),
  bottom: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  justifyContent: 'space-between',
  color: theme.palette.common.white,
}));

// ----------------------------------------------------------------------

ReturnConfirmationImages.propTypes = {
  gallery: PropTypes.array.isRequired,
};

export default function ReturnConfirmationImages({ gallery }) {
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const imagesLightbox = gallery.map((img) => img.preview);
  const handleOpenLightbox = (url) => {
    const selectedImage = imagesLightbox.findIndex((index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  const isNotFound = useMemo(() => (
    !gallery.length
  ), [gallery]);

  return (
    <Box sx={{ mt: 5 }}>

        {/* if the gallery is empty */}
        {
          isNotFound && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <TableNoData isNotFound={isNotFound}
                title={`No Return confirmation Document Uploaded Yet`}
                description={'Return confirmation Documents will appear here once uploaded'}
              />
            </Box>
          )
        }

        {/* If the gallery is not empty */}
        {
          !isNotFound && (
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: "30px", columnGap: '30px' }}>
              {gallery.map((image, index) => (
                <DocumentsItem key={index} image={image} onOpenLightbox={handleOpenLightbox} />
              ))}
            </Box>
          )
        }

        <LightboxModal
          images={imagesLightbox}
          mainSrc={imagesLightbox[selectedImage]}
          photoIndex={selectedImage}
          setPhotoIndex={setSelectedImage}
          isOpen={openLightbox}
          onCloseRequest={() => setOpenLightbox(false)}
          setOpenLightbox={setOpenLightbox}
        />
    </Box>
  );
}

// ----------------------------------------------------------------------

DocumentsItem.propTypes = {
  image: PropTypes.object,
  onOpenLightbox: PropTypes.func,
};

function DocumentsItem({ image, onOpenLightbox }) {
  const [numPages, setNumPages] = useState(1);
  const { preview } = image;

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  return (
    <Card sx={{
      cursor: 'pointer', position: 'relative',
      p: 0, m: 0, width: 180, height: 180, borderRadius: 1.25, overflow: 'hidden',
      display: 'inline-flex', border: (theme) => `solid 1px ${theme.palette.divider}`
      }}
    >
      { preview.endsWith(".pdf") ?
        <MyDocument file={preview} onLoadSuccess={({ numPages }) => { setNumPages(numPages) }} className="smallFilesCard">
          <Page pageNumber={1} className="pdf-page" onClick={() => onOpenLightbox(preview)} />
        </MyDocument>
        :
        <Image alt="gallery image" ratio="1/1" src={preview} onClick={() => onOpenLightbox(preview)} />
      }
    </Card>
  );
}
