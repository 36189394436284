import dayjs from 'dayjs';
import { deserialize } from 'deserialize-json-api';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { dispatch } from '../store';

import { fDateCompact } from '../../utils/formatTime';

const initialState = {
  isLoading: false,
  error: null,
  isUpdated: false,
  sortBy: 'newestRequest',
  viewType: 'tableView',
  orderData: [],
  workSheets: [],
  singleOrder: [],
  pickupDateRange: [],
  isDownloadingSchedule: false,
  deletedOrdersData: [],
  softDeletedData: [],
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    // DOWNLOAD SCHEDULE
    startDownloadingSchedule(state) {
      state.isDownloadingSchedule = true;
    },

    stopDownloadingSchedule(state) {
      state.isDownloadingSchedule = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ORDERS
    getOrdersSuccess(state, action) {
      state.isLoading = false;
      state.orderData = state.isUpdated ? state.orderData : action.payload;
    },

    getDeletedOrdersSuccess(state, action) {
      state.isLoading = false;
      state.deletedOrdersData = state.isUpdated ? state.deletedOrdersData : action.payload;
    },

    // GET ORDER
    getOrderSuccess(state, action) {
      state.isLoading = false;
      const payload = action.payload.map((order) => ({
        id: order.id,
        customer: {
          address: order.customer_address,
          id: order.customer_id,
          name: order.customer_name,
          requireLpo: order.required_lpo
        },
        contactPerson: {
          name: order?.contact_person?.name || null,
          email: order?.contact_person?.email || null,
          phoneNumber: order?.contact_person?.phone_number || null
        },
        driverInformation: [
          {
            name: order?.driver?.data?.attributes?.name || null,
            phoneNumber: order?.driver?.data?.attributes?.phone_number || null,
          },
        ],
        vehicleInformation: [
          {
            makeAndModel: order?.vehicle?.data?.attributes?.make_and_model || null,
            maximumCarryCapacity: order?.vehicle?.data?.attributes?.maximum_carry_capacity || null,
            manufactureYear: order?.vehicle?.data?.attributes?.manufacture_year || null,
          },
        ],
        invoiceNo: order.invoice_no || '',
        lpoNo: order.lpo_no || '',
        lpoDate: order.lpo_date ? new Date(order.lpo_date) : null,
        dnoteNo: order.dnote_number || '',
        hasUnattainableLpoDate: order.has_unattainable_lpo_date,
        pickupDate: new Date(order.pickup_date),
        expectedDeliveryDate: new Date(order.expected_arrival_date),
        total: order.total,
        status: order.order_status[0] || 'scheduled',
        orderLines: order.orderline_items.map((orderLineItem) => ({
          quantity: orderLineItem.quantity,
          pickupPointId: orderLineItem.pickup_point_id,
          pickup_address: orderLineItem.pickup_address,
          item_id: orderLineItem.item_id,
          item_description: orderLineItem.item_description,
          weight: parseFloat(orderLineItem.weight) || 0,
          weight_unit: orderLineItem.weight_unit,
          id: orderLineItem.id,
        })),
        actualDeliveryDate: order?.actual_delivery_date?.created_at || '',
        hasDnoteImages: order?.has_dnote_images || false,
        hasMissingItems: order?.has_missing_items || false,
        hasReturns: order?.has_returns || false,
        orderNotes: order?.order_notes || [],
        isDraft: order?.is_draft || false,
        wasPrescheduled: order?.was_prescheduled || false,
      }));
      state.singleOrder = payload;
    },

    // GET Worksheets
    getWorksheetsSuccess(state, action) {
      state.isLoading = false;
      state.workSheets = action.payload;
    },

    // CREATE ORDER
    createOrderSuccess(state, action) {
      state.isLoading = false;
      state.isUpdated = true;
      state.orderData.push(action.payload);
    },

    // UPDATE ORDER
    updateOrderSuccess(state, action) {
      state.isLoading = false;
      state.isUpdated = true;
      state.orderData = state.orderData.map((order) => {
        if (order.orderId === action.payload.orderId) {
          return action.payload;
        }
        return order;
      });
    },

    deleteOrderSuccess(state, action) {
      state.isLoading = false;
      state.isUpdated = true;
      state.deletedOrdersData = [
        ...state.deletedOrdersData,
        state.orderData.find((order) => order.orderId === action.payload),
      ];
      state.orderData = state.orderData.filter((order) => order.orderId !== action.payload);
    },

    softDeleteOrderSuccess(state, action) {
      state.isLoading = false;
      state.isUpdated = true;
      state.softDeletedData = [
        ...state.softDeletedData,
        state.orderData.find((order) => order.orderId === action.payload),
      ];
      state.orderData = state.orderData.filter((order) => order.orderId !== action.payload);
    },

    // UPDATE WORKSHEET success
    updateWorksheetSuccess(state) {
      state.isLoading = false;
    },

    updateDraftOrders(state, action) {
      state.isLoading = false;
      state.orderData = state.orderData.filter((order) => order.orderId !== action.payload);
    },

    // RENAME WORKSHEET success
    renameWorksheetSuccess(state, action) {
      state.isLoading = false;
      const { pickupRequestId, updatedAttributes } = action.payload;
      state.workSheets = state.workSheets.map((workSheet) => {
        if (workSheet.id.toString() === pickupRequestId.toString()) {
          workSheet = {
            ...workSheet,
            ...updatedAttributes,
          };
        }
        return workSheet;
      });
    },

    // UPDATE EXISTING ORDER
    updateExistingOrderSuccess(state, action) {
      state.isUpdated = true;
      const oldOrder = state.orderData.find((order) => order.orderId === action.payload.orderId);
      const newOrder = { ...oldOrder, ...action.payload };
      state.orderData = state.orderData.map((order) => {
        if (order.orderId === action.payload.orderId) {
          return newOrder;
        }
        return order;
      });
    },

    // SORT
    sortByDeliveries(state, action) {
      state.sortBy = action.payload;
    },
    // Change viewType
    changeViewType(state, action) {
      state.viewType = action.payload.viewType;
    },


  },

});


// Reducer
export default slice.reducer;

// Actions
export const {
  openModal,
  closeModal,
  selectEvent,
  sortByDeliveries,
  changeViewType,
  getOrdersSuccess,
  getOrderSuccess,
  renameWorksheetSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getOrders({
  shipperId,
  filterPickupDateFrom,
  searchVal,
  filterPickupDateEnd,
  isDraft = false,
  deleted = false,
}) {
  return async () => {
    try {
      let pathName = `/api/v1/pickup_request/${shipperId}`;

      if (searchVal && searchVal.trim().length > 1) {
        pathName = `${pathName}?search_val=${searchVal}`;
      } else {
        const fromDate = dayjs(filterPickupDateFrom).format('YYYY-MM-DD');
        const endDate = dayjs(filterPickupDateEnd).format('YYYY-MM-DD');
        pathName = `${pathName}?pickup_start_date=${fromDate}&pickup_end_date=${endDate}`;
      }

      if (isDraft) {
        pathName = `${pathName}&is_draft=${isDraft}`;
      }

      if (deleted) {
        pathName = `${pathName}&deleted=${deleted}`;
      }

      dispatch(slice.actions.startLoading());
      const response = await axios.get(pathName);
      const { data } = response;
      const receivedData = deserialize(data).data.map((order) => ({
        customer: {
          name: order.customer_name,
          id: order.customer_id,
          address: order.customer_address,
          town_name: order.town_name,
          google_place_id: order.google_place_id,
        },
        numberOfItems: order.total,
        pickupDate: order.created_at,
        deliveryDate: order.expected_arrival_date,
        orderId: order.id.toString(),
        invoiceNo: order.invoice_no,
        lpoNo: order.lpo_no,
        dnoteNo: order.dnote_number,
        total: order.total,
        status: order.order_status[0] || 'scheduled',
        lpoDate: order.lpo_date,
        hasUnattainableLpoDate: order.has_unattainable_lpo_date,
        expectedArrivalDate: order.expected_arrival_date,
        orderlineItems: order.orderline_items,
        pickupDriversInformation: deserialize(order.pickup_information.pickup_drivers, { transformKeys: 'camelCase' })
          .data,
        vehiclesInformation: deserialize(order.pickup_information.pickup_transporters, { transformKeys: 'camelCase' })
          .data,
        contactPerson: {
          name: order?.contact_person?.name || null,
          email: order?.contact_person?.email || null,
          phoneNumber: order?.contact_person?.phone_number || null,
        },
        driverInformation: [
          {
            name: order?.driver?.data?.attributes?.name || null,
            phoneNumber: order?.driver?.data?.attributes?.phone_number || null,
          },
        ],
        vehicleInformation: [
          {
            makeAndModel: order?.vehicle?.data?.attributes?.make_and_model || null,
            maximumCarryCapacity: order?.vehicle?.data?.attributes?.maximum_carry_capacity || null,
            manufactureYear: order?.vehicle?.data?.attributes?.manufacture_year || null,
          },
        ],
        actualDeliveryDate: order?.actual_delivery_date?.created_at || '',
        hasDnoteImages: order?.has_dnote_images || false,
        hasMissingItems: order?.has_missing_items || false,
        hasReturns: order?.has_returns || false,
        orderNotes: order?.order_notes || [],
        totalWeightKg: order.total_weight_kg,
      }));
      if (deleted) {
        dispatch(slice.actions.getDeletedOrdersSuccess(receivedData));
      } else {
        dispatch(slice.actions.getOrdersSuccess(receivedData));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// get order but with a different date filter
export function getShipperOrders({ shipperId, fromDate, toDate, searchVal, currentPage = null, pageSize = null }) {
  return async () => {
    try {
      let pathName = `/api/v1/pickup_request/${shipperId}`;

      if (toDate && fromDate) {
        const pickupStartDate = dayjs(fromDate).format('YYYY-MM-DD');
        const pickupEndDate = dayjs(toDate).format('YYYY-MM-DD');
        pathName = `${pathName}?pickup_start_date=${pickupStartDate}&pickup_end_date=${pickupEndDate}`;
      } else {
        // minus seven days. Default to current date minus four days
        const pickupStartDate = dayjs(new Date(new Date().setDate(new Date().getDate() - 7))).format('YYYY-MM-DD');
        const pickupEndDate = dayjs(new Date()).format('YYYY-MM-DD');
        pathName = `${pathName}?pickup_start_date=${pickupStartDate}&pickup_end_date=${pickupEndDate}`;
      }

      // add the searchVal if the searchVal is not an empty string
      if (searchVal && searchVal.trim().length > 0) {
        pathName = `${pathName}&search_val=${searchVal}`;
      }

      if (currentPage) {
        pathName = `${pathName}&current_page=${currentPage}`;
      }

      if (pageSize) {
        pathName = `${pathName}&page_size=${pageSize}`;
      }

      dispatch(slice.actions.startLoading());
      const response = await axios.get(pathName);
      const { data } = response;

      const receivedData = deserialize(data).data.map((order) => ({
        customer: {
          name: order.customer_name,
          id: order.customer_id,
          address: order.customer_address,
        },
        numberOfItems: order.total,
        pickupDate: order.created_at,
        deliveryDate: order.expected_arrival_date,
        orderId: order.id.toString(),
        invoiceNo: order.invoice_no,
        lpoNo: order.lpo_no,
        dnoteNo: order.dnote_number,
        total: order.total,
        status: order.order_status[0] || 'scheduled',
        lpoDate: order.lpo_date,
        hasUnattainableLpoDate: order.has_unattainable_lpo_date,
        orderlineItems: order.orderline_items,
        pickupDriversInformation: deserialize(order.pickup_information.pickup_drivers, { transformKeys: 'camelCase' })
          .data,
        vehiclesInformation: deserialize(order.pickup_information.pickup_transporters, { transformKeys: 'camelCase' })
          .data,
        contactPerson: {
          name: order?.contact_person?.name || null,
          email: order?.contact_person?.email || null,
          phoneNumber: order?.contact_person?.phone_number || null,
        },
        driverInformation: [
          {
            name: order?.driver?.data?.attributes?.name || null,
            phoneNumber: order?.driver?.data?.attributes?.phone_number || null,
          },
        ],
        vehicleInformation: [
          {
            makeAndModel: order?.vehicle?.data?.attributes?.make_and_model || null,
            maximumCarryCapacity: order?.vehicle?.data?.attributes?.maximum_carry_capacity || null,
            manufactureYear: order?.vehicle?.data?.attributes?.manufacture_year || null,
          },
        ],
        actualDeliveryDate: order?.actual_delivery_date?.created_at || '',
        hasDnoteImages: order?.has_dnote_images || false,
        hasMissingItems: order?.has_missing_items || false,
        hasReturns: order?.has_returns || false,
        orderNotes: order?.order_notes || [],
        totalWeightKg: order.total_weight_kg,
      }));

      dispatch(slice.actions.getOrdersSuccess(receivedData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrder({ orderId }) {
  return async () => {
    try {
      const pathName = `/api/v1/order/${orderId}`;
      dispatch(slice.actions.startLoading());
      const response = await axios.get(pathName);
      const receivedData = response.data;
      const order = deserialize(receivedData).data;
      dispatch(slice.actions.getOrderSuccess(order));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// create orders
export function createOrders({ shipperId, orders }) {
  return async () => {
    try {
      const payload = formulateOrdersPayload({ shipperId, orders })

      dispatch(slice.actions.startLoading());
      const pathName = `/api/v1/pickup_request?skipDropoffPointTest=${true}`;
      const response = await axios.post(pathName, payload);
      dispatch(slice.actions.stopLoading());
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error('There was problem scheduling your pickup!');
    }
  };
}

// check for duplicates
export function checkOrdersDuplicates({ shipperId, orders }) {
  return async () => {
    try {
      const payload = formulateOrdersPayload({ shipperId, orders });
      dispatch(slice.actions.startLoading());
      const pathName = `/shipper_app/api/v1/pickup_request/${shipperId}/check_duplicates`;
      const response = await axios.post(pathName, payload);
      dispatch(slice.actions.stopLoading());
      return response.data.duplicate_validations;
    } catch (err) {
      dispatch(slice.actions.hasError(err));
    }
  }
}

function formulateOrdersPayload({ shipperId, orders }) {
  const pickupDatesList = getPickupDatesList(orders);
  const uniquePickupDatesList = [...new Set(pickupDatesList)];
  const pickupDate = pickupDatesList.find((date) => date !== '') || '';
  const list = [];
  uniquePickupDatesList.forEach((el) => {
    const invalidDate = el.toString().includes('Invalid Date');
    if (invalidDate) {
      list.push('');
    } else {
      list.push(el);
    }
  });
  const payload = {
    pickup_request: {
      shipperId,
      pickupDate,
      pickupDatesList: list,
      hasDraft: hasDrafts(orders),
      createdFrom: 'shipper',
      dropoffPoints: orders.map((order) => ({
        specialInstructions: order.specialInstructions,
        customerId: order.customer.id,
        customerName: order.customer.name,
        expectedArrivalDate: order.expectedDeliveryDate,
        pickupDate: order.pickupDate,
        invoiceNo: order.invoiceNo,
        lpoNo: order.lpoNo,
        lpoDate: order.lpoDate,
        dnoteNo: order.dnoteNo,
        total: order.total,
        specialInstructions: order.specialInstructions,
        contactPersonIds: [],
        isDraft: order.isDraft || false,
        wasPrescheduled: order.wasPrescheduled || false,
        backdated: order.backdated || false,
        shareTrackingLink: order.shareTrackingLink,
        sharingOption: order.sharingOption,
        sharingEmail: order.sharingEmail,
        sharingPhoneNo: order.sharingPhoneNo,
        orderLineItems: order.orderLines.map((orderLine) => ({
          itemId: orderLine.item_id,
          quantity: orderLine.quantity,
          weight: orderLine.weight,
          weightUnit: orderLine.weight_unit,
          isEach: orderLine.isEach,
          pickupPointId: orderLine.pickupPointId,
        })),
      })),
    },
  };
  return payload
}

function getPickupDatesList(orders) {
  if (orders.length === 0) {
    return [];
  }
  const pickupDatesList = orders.map((order) => order.pickupDate);
  return pickupDatesList;
}

function hasDrafts(orders) {
  if (orders.length === 0) {
    return false;
  }
  const hasDraft = orders.some((order) => order.isDraft);
  return hasDraft;
}

// create new package
export function createPackage(newPackage) {
  return async () => {
    
    try {
      const pathName = '/api/v1/items';
      const payload = {
        items: { items: [newPackage] },
      };
      dispatch(slice.actions.startLoading());
      const response = await axios.post(pathName, payload);
      dispatch(slice.actions.stopLoading());
      return response;
    } catch (err) {
      dispatch(slice.actions.hasError(err));
      throw new Error();
    }
  };
}

// create customer
export function createCustomer(newCustomer) {
  return async () => {
    try {
      const payload = {
        customer: { ...newCustomer },
      };
      const pathName = '/api/v1/customers';
      dispatch(slice.actions.startLoading());
      const response = await axios.post(pathName, payload);
      dispatch(slice.actions.stopLoading());
      return response;
    } catch (err) {
      dispatch(slice.actions.hasError(err));
      throw new Error();
    }
  };
}

// edit customer
export function editCustomer(newCustomer) {
  return async () => {
    try {
      const payload = {
        customer: { ...newCustomer },
      };
      const pathName = '/api/v1/customers';
      dispatch(slice.actions.startLoading());
      const response = await axios.patch(pathName, payload);
      dispatch(slice.actions.stopLoading());
      return response;
    } catch (err) {
      dispatch(slice.actions.hasError(err));
      throw new Error();
    }
  };
}

export function createPickupPoint(newPickupPoint) {
  return async () => {
    try {
      const payload = {
        pickup_point: { ...newPickupPoint },
      };
      const pathName = '/api/v1/shipper/pickup_points/new';
      dispatch(slice.actions.startLoading());
      const response = await axios.post(pathName, payload);
      dispatch(slice.actions.stopLoading());
      return response;
    } catch (err) {
      dispatch(slice.actions.hasError(err));
      throw new Error();
    }
  };
}


// update order
export function updateOrder(updatedOrder) {
  return async () => {
    try {
      dispatch(slice.actions.updateOrderSuccess(updatedOrder));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error();
    }
  };
}

// update orders
export function updateOrders(payload) {
  return async () => {
    try {
      const path = `/api/v1/orders`;
      dispatch(slice.actions.startLoading());
      const response = await axios.patch(path, { order: payload.orders[0], shipperId: payload.shipperId });
      dispatch(slice.actions.stopLoading());
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error();
    }
  };
}

export function approveDraft(payload, orderId) {
  return async () => {
    try {
      const path = `/api/v1/orders/${orderId}/approve`;
      dispatch(slice.actions.startLoading());
      const response = await axios.patch(path, { order: payload.orders[0] });
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.updateDraftOrders(orderId));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error();
    }
  };
}

// edit worksheet
export function updateWorksheet(payload) {
  return async () => {
    try {
      const path = `/api/v1/orders`;
      dispatch(slice.actions.startLoading());
      let promises;
      if (payload.length >= 1) {
        promises = payload.map((order) => {
          const body = { order };
          return axios.patch(path, body);
        });
      }
      dispatch(slice.actions.updateWorksheetSuccess());
      return Promise.all(promises);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error();
    }
  };
}

export function getWorksheets({ shipperId, filterPickupDateFrom, filterPickupDateEnd, worksheetName }) {
  return async () => {
    try {
      const fromDate = dayjs(filterPickupDateFrom).format('YYYY-MM-DD');
      const endDate = dayjs(filterPickupDateEnd).format('YYYY-MM-DD');
      let pathName = `/api/v1/shipper/${shipperId}/worksheets`;
      if (worksheetName && worksheetName.trim().length >= 1) {
        pathName = `${pathName}?search_val=${worksheetName}`;
      } else {
        pathName = `${pathName}?pickup_start_date=${fromDate}&pickup_end_date=${endDate}`;
      }
      dispatch(slice.actions.startLoading());
      const response = await axios.get(pathName);
      const workSheets = deserialize(response.data, { transformKeys: 'camelCase' }).data;
      dispatch(slice.actions.getWorksheetsSuccess(workSheets));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function downloadPickupSchedule() {
  return async () => {
    try {
      const payload = {};
      dispatch(slice.actions.startDownloadingSchedule());
      const pathName = `/api/v1/pickup_schedule/pdf`;
      const response = await axios.post(pathName, payload, { responseType: 'blob' }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = url;
        link.setAttribute('download', 'pickup_schedule.pdf');
        link.click();
        link.remove();
      });
      dispatch(slice.actions.stopDownloadingSchedule());
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error('There was problem downloading your Pickup schedule!');
    }
  };
}

export function renameWorksheet(pickupRequestId, payload) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const pathName = `/api/v1/pickup_requests/${pickupRequestId}/worksheet`;
      const response = await axios.patch(pathName, payload);
      const updatedAttributes = deserialize(response.data, { transformKeys: 'camelCase' }).data;
      dispatch(slice.actions.renameWorksheetSuccess({ updatedAttributes, pickupRequestId }));
      return updatedAttributes;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error('Something went wrong when updating the worksheet!');
    }
  };
}

// Endpoint for cancelling orders
export function deleteOrder(orderId) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const pathName = `/api/v1/orders/${orderId}/shipper`;
      await axios.delete(pathName);
      dispatch(slice.actions.deleteOrderSuccess(orderId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error('Something went wrong when cancelling your order!');
    }
  };
}

// Endpoint for Deleting orders
export const softDeleteOrder = (orderId) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    const path = `/api/v1/orders/${orderId}/shipper`;
    await axios.delete(path);
    dispatch(slice.actions.softDeleteOrderSuccess(orderId));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw new Error('Something went wrong when trying to delete the order');
  }
};


export function getOrdersPrintout({ format, orders = [], companyName = '' }) {
  return async () => {
    try {
      const payload = {
        orders,
      };
      dispatch(slice.actions.startDownloadingSchedule());
      const pathName = `/api/v1/orders/printout?file_format=${format}`;
      const response = await axios.post(pathName, payload, { responseType: 'blob' }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = url;

        link.setAttribute('download', `${printoutName(orders, companyName)}.${resolveExtension(format)}`);
        link.click();
        link.remove();
      });
      dispatch(slice.actions.stopDownloadingSchedule());
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error('There was problem downloading your Order printout!');
    }
  };
}

export function getOrdersTemplatePrintout({ format = 'xlsx', companyName = '' }) {
  return async () => {
    try {
      dispatch(slice.actions.startDownloadingSchedule());
      const pathName = `/api/v1/orders/template`;
      const response = await axios.get(pathName, { responseType: 'blob' }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = url;
        link.setAttribute('download', `${generateExcelSheetName(companyName)}.${format}`);
        link.click();
        link.remove();
      });
      dispatch(slice.actions.stopDownloadingSchedule());
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error('There was problem downloading your Pickup Sheet template!');
    }
  };
}

const generateExcelSheetName = (companyName = '') => {
  const companyAcronym =
    companyName
      ?.match(/\b(\w)/g)
      ?.join('')
      ?.toUpperCase() || '';
  return `PickupSheet_Template_${companyAcronym}`;
};

function printoutName(orders, companyName) {
  return uniqueDocumentName(orders, companyName);
}

function uniqueDocumentName(orders, companyName = '') {
  const pickupDate = Date.now();
  const ordersName = orders.length < 5 ? orders.join('_') : '';
  const docName = orders.length > 1 ? 'Orders' : 'Order';
  const companyAcronym =
    companyName
      ?.match(/\b(\w)/g)
      ?.join('')
      ?.toUpperCase() || '';

  const pickup = pickupDate ? fDateCompact(pickupDate) : '';
  return `${docName}_${ordersName}_${companyAcronym}_${pickup}`;
}

function resolveExtension(format = 'pdf') {
  switch (format) {
    case 'pdf':
      return 'pdf';
    case 'excel':
      return 'xlsx';
    case 'word':
      return 'docx';
    case 'csv':
      return 'csv';
    default:
      return 'pdf';
  }
}
