import { createSlice } from '@reduxjs/toolkit';
import { fDateForPersistence } from '../../utils/formatTime';
// utils
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  error: null,
  pdfBlob: null,
  statementDocName: '',
};

const slice = createSlice({
  name: 'financialStatement',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    setPdfBlob(state, action) {
      state.pdfBlob = action.payload; // Action to set PDF blob
    },
    setStatementName(state, action) {
      state.statementDocName = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, stopLoading } = slice.actions;

export function generateAccountStatement({ shipperId, asToDate, shipperName }) {
  return async () => {
    try {
      const formatedDate = fDateForPersistence(asToDate);
      dispatch(slice.actions.startLoading());
      const pathName = `/shipper_app/api/v1/xero_account_statements/${shipperId}/outstanding_invoices?as_to_date=${formatedDate}`;
      const response = await axios.get(pathName, { responseType: 'blob' });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const dateString = fDateForPersistence(asToDate);
      const documentName = `${generateDocName(shipperName, dateString)}.pdf`;
      // Dispatch the Blob to Redux store
      dispatch(slice.actions.setPdfBlob(blob));
      dispatch(slice.actions.setStatementName(documentName));

      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      console.error(error);
      throw new Error('There was a problem downloading Financial Statement');
    }
  };
}

// helper methods

function generateDocName(shipperName = '', dateString) {
  const companyAcronym =
    shipperName
      ?.match(/\b(\w)/g)
      ?.join('')
      ?.toUpperCase() || '';
  return `Financial_Statement_${shipperName}-${dateString}`;
}
