import { createSlice } from '@reduxjs/toolkit';
import { deserialize } from 'deserialize-json-api';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  error: null,
  sortBy: 'newest',
  users: [],
  user: undefined,
  rolesPolicies: [],
};

const slice = createSlice({
  name: 'shipperUsers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },
    getUserSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },
    getRolePolicesSuccess(state, action) {
      state.isLoading = false;
      state.rolesPolicies = action.payload;
    },
    deleteUserSuccess(state, action) {
      state.isLoading = false;
      state.users = state.users.filter((user) => user.id !== action.payload);
    },
  },
});

export const { getRolePolicesSuccess, getUsersSuccess, getUserSuccess, deleteUserSuccess, stopLoading } = slice.actions;

// Reducer
export default slice.reducer;

export function getRolesPolices() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const pathName = '/api/v1/shipper/roles/policies';
      const response = await axios.get(pathName);
      const roles = deserialize(response.data, { transformKeys: 'camelCase' }).data;
      dispatch(slice.actions.getRolePolicesSuccess(roles));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getShipperUsers(shipperId, isShipper) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const pathName = `/shipper_app/api/v1/shippers/${shipperId}/users`;
      const response = await axios.get(pathName);
      const users = deserialize(response.data, { transformKeys: 'camelCase' }).data;
      dispatch(slice.actions.getUsersSuccess(users));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getShipperUser(userId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const pathName = `/shipper_app/api/v1/users/${userId}`;
      const response = await axios.get(pathName);
      const user = deserialize(response.data, { transformKeys: 'camelCase' }).data;
      dispatch(slice.actions.getUserSuccess(user));
    } catch (error) {
      console.log(error);
    }
  };
}

export function addShipperUsers(shipperUser) {
  return async () => {
    try {
      const pathName = `/shipper_app/api/v1/users`;
      const payload = {
        shipper_users: shipperUser,
      };
      const response = await axios.post(pathName, payload);
      const user = deserialize(response.data, { transformKeys: 'camelCase' }).data;
      return user;
    } catch (error) {
      throw new Error(error.errors[0]);
    }
  };
}

export function uploadProfilePicture(pictureBlob, userId) {
  return async () => {
    try {
      const pathName = `/api/v1/profile_picture/${userId}`;
      const formDataPic = new FormData();
      formDataPic.append('profile_picture', pictureBlob);
      const response = await axios.post(pathName, formDataPic, { headers: { 'content-type': 'multipart/form-data' } });
      return response;
    } catch (error) {
      console.log(error);
      throw new Error(error.errors[0]);
    }
  };
}

export function editShipperUsers(shipperUser, userId) {
  return async () => {
    try {
      const pathName = `/shipper_app/api/v1/users/${userId}`;
      const payload = {
        shipper_users: shipperUser,
      };
      const response = await axios.patch(pathName, payload);
      const user = deserialize(response.data, { transformKeys: 'camelCase' }).data;
      return user;
    } catch (error) {
      throw new Error(error.errors[0]);
    }
  };
}

export function deleteShipperUser(userId) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const pathName = `/shipper_app/api/v1/users/${userId}`;
      await axios.delete(pathName);
      dispatch(slice.actions.deleteUserSuccess(userId));
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      throw new Error(error.errors[0]);
    }
  };
}
