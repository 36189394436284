/**
 * 
 * Return status timeline
 * 
*/

import { createSlice } from "@reduxjs/toolkit"
import { dispatch } from "../store";
import axiosInstance from "../../utils/axios";

const initialState = {
    isLoading: false,
    error: null,
    returnDocumentReferenceStatusTimeline: [],
}

// Reducers
const slice = createSlice({
    name: 'returnDocumentReferenceStatusTimeline',
    initialState,

    reducers: {
        // Start Loading
        startLoading(state) {
            state.isLoading = true
        },

        // Check for erros
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        getReturnDocReferenceStatusTimelineSuccess(state, action) {
            state.isLoading = false
            state.returnDocumentReferenceStatusTimeline = action.payload
        }
    }
})

export default slice.reducer;

// Network call to fetch a return document reference's status timeline
export function getReturnDocReferenceStatusTimeline({ returnDocumentReferenceID }) {
    return async () => {
        dispatch(slice.actions.startLoading())
        try {
            // Path used to fetch a return document reference's status timeline
            const pathName = `/api/v1/returns/docs/${returnDocumentReferenceID}/status_timeline`
            // Fetch the return document reference's status timeline
            const response = await axiosInstance.get(pathName)
            // Fetch the status timelines
            const statusTimeline = response.data.status_timelines.map((s) => ({
                statusID: s.status_id,
                status: s.return_status,
                returnDocReferenceID: s.return_doc_reference_id,
                timeLineID: s.status_timeline_id,
                updateTime: s.update_time
            }))

            dispatch(slice.actions.getReturnDocReferenceStatusTimelineSuccess(statusTimeline))
        } catch (err) {
            dispatch(slice.actions.hasError(err))
        }
    }
}