import { createSlice } from "@reduxjs/toolkit"
import axiosInstance from "../../utils/axios"
import { dispatch } from "../store"

/**
 * 
 *  Fetch a return reference document's return items
 * 
*/
const initialState = {
    isLoading: false,
    error: null,
    returnItems: []
}


// Actions and reducers
const slice = createSlice({
    name: 'returnItems',
    initialState,
    reducers: {
        // Start Loading
        startLoading(state) {
            state.isLoading = true
        },

        // Check for erros
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // Fetch the return document references
        getReturnItemsSuccess(state, action) {
            state.isLoading = false
            state.returnItems = action.payload
        }
    }
})

export default slice.reducer;

export function getReturnDocumentReferenceReturnItems({ returnDocumentReferenceID }) {
    return async () => {
        dispatch(slice.actions.startLoading())
        try {
            const pathName = `/api/v1/returns/docs/${returnDocumentReferenceID}/return_items`
            // Get the associated return items
            const response = await axiosInstance.get(pathName)
            // Set the state for the return items
            const returnItems = response.data.data.map((r) => ({
                id: r?.attributes?.id || "",
                itemDescription: r?.attributes?.extra_details || "",
                quantity: r?.attributes?.quantity || 0,
                units: r?.attributes?.units || [],
                createdAt: r?.attributes?.created_at || "",
                hasMisfits: r?.attributes?.has_misfits || "",
                hasRejects: r?.attributes?.has_rejects || "",
                orderID: r?.attributes?.order_id || "",
                reason: r?.attributes?.return_reason?.data?.attributes?.reason || "",
                reason_id: r?.attributes?.return_reason?.data?.attributes?.id || "",
                firstRecepientType: r?.attributes?.returns_first_recepient_type?.data?.attributes?.first_recepient_type || "",
                firstRecepientID: r?.attributes?.returns_first_recepient_type?.data?.attributes?.id || "",
                status: r?.attributes?.returns_status?.data?.attributes?.return_status || "",
                statusID: r?.attributes?.returns_status?.data?.attributes?.id || "",
                returnNotes: r?.attributes?.return_note?.data || "",
            }))
            // Dispatch the return items action
            dispatch(slice.actions.getReturnItemsSuccess(returnItems))
        } catch (err) {
            dispatch(slice.actions.hasError(err))
        }
    }
}
