import PropTypes from 'prop-types';
import { Card, CardHeader, Button, Stack, Divider } from '@mui/material';

// components
import Iconify from '../../../../components/Iconify';

RepresentativeCard.propTypes = {
  onConfirmBack: PropTypes.func,
  onConfirmReturn: PropTypes.func,
};

export default function RepresentativeCard({ onConfirmBack, onConfirmReturn }) {
  return (
    <Card sx={{ p: 3 }}>
      <CardHeader
        title={'All your items from your delivery to Naivas Likoni will be held for collection by your representative.'}
        subheader={
          'Are you sure? This action cannot be undone. Please confirm your decision by clicking on the button below.'
        }
        sx={{
          '& .MuiCardHeader-action': {
            alignSelf: 'center',
          },
        }}
      />

      <Divider sx={{ my: 3 }} />

      <Stack direction="row" spacing={2} alignItems="flex-end" sx={{ flexGrow: 1 }}>
        <Button
          fullWidth
          variant="contained"
          color="warning"
          onClick={onConfirmBack}
          endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
        >
          Back
        </Button>

        <Button
          fullWidth
          variant="contained"
          onClick={onConfirmReturn}
          endIcon={<Iconify icon={'eva:close-circle-fill'} />}
        >
          Yes
        </Button>
      </Stack>
    </Card>
  );
}
