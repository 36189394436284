// This is the notifications slice. It is used to store the notifications in the redux store.
// These are the current notifications functions:
// 1. Add notifications to the store
// 2. Reduce unread notifications number when a notification is read

import { createSlice } from '@reduxjs/toolkit';
import { deserialize } from 'deserialize-json-api';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  notificationsData: [],
  isUdpated: false,
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.notificationsData = state.isUdpated ? state.notificationsData : action.payload;
    },
    // ADD NOTIFICATION
    addNotificationSuccess(state, action) {
      state.isUdpated = true;
      const currentNotificationID = action.payload.id;
      if (currentNotificationID === 'notification_draft_orders_count') {
        const ifExists = state.notificationsData.find((item) => item.id === currentNotificationID);
        if (!ifExists) {
          state.notificationsData.unshift(action.payload);
        }
      } else {
        state.notificationsData.unshift(action.payload);
      }
    },
    markAllAsReadSuccess(state) {
      state.notificationsData = state.notificationsData
        .map((item) => ({ ...item, isUnRead: false, isNew: false }))
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;


export function addNotification(notification) {
  return async () => {
    try {
      dispatch(slice.actions.addNotificationSuccess(notification));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function markAllasRead() {
  return async () => {
    try {
      dispatch(slice.actions.markAllAsReadSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDraftNotifications(shipperId) {
  return async () => {
    try {
      const pathName = `/api/v1/notifications/${shipperId}`;
      const response = await axios.get(pathName);
      const notifications = deserialize(response.data, { transformKeys: 'camelCase' });
      const orders = notifications?.data[0]?.pickupRequests?.length || 0;
      if (orders > 0) {
        const newNotification = {
          id: `notification_draft_orders_count`,
          title: 'Draft orders',
          description: `You have ${orders} draft orders, due for pickup this week, that are pending confirmation.`,
          avatar: null,
          type: 'order_draft',
          createdAt: new Date().toString(),
          isNew: true,
          isUnRead: true,
        };
        dispatch(slice.actions.addNotificationSuccess(newNotification));
      }
    } catch (error) {
      console.log(error);
    }
  };
}

