import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';

ContactPersonInfo.propTypes = {
    order: PropTypes.object
}

export default function ContactPersonInfo({ order }) {
    return (
        order?.contactPerson ? (
            <Stack direction="row" spacing={6} sx={{ p: 1, pt: 3 }}>
                <Stack direction="column">
                    <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                        Name
                    </Typography>
                    <Typography variant="subtitle1">
                        {order?.contactPerson?.name || "-"}
                    </Typography>
                </Stack>

                <Stack direction="column">
                    <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                        Phone No
                    </Typography>
                    <Typography variant="subtitle1">
                        {order?.contactPerson?.phoneNumber || "-"}
                    </Typography>
                </Stack>

                <Stack direction="column">
                    <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                        Email
                    </Typography>
                    <Typography variant="subtitle1">
                        {order?.contactPerson?.email || "-"}
                    </Typography>
                </Stack>
            </Stack>
        ) : (
            <Stack direction="column" sx={{ p: 1, pt: 3 }}>
                <Typography variant="subtitle1">
                    There is no contact person information for this order.
                </Typography>
            </Stack>
        )
    );
}
