import _mock from './_mock';

export const _orders2 = [
  // order from skip dnote image path that is currently in transit
  {
    orderLines: [
      {
        weight: 32,
        quantity: 23,
        item_id: 1,
        pickup_point_address: '',
        item_name: 'Small Box',
        weight_unit: 'kg',
      },
    ],
    customer: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11',
      name: 'Naivas Likoni',
      address: 'Naivas Supermarket - Likoni, Near Likoni Ferry, Nyerere Ave, Mombasa, Kenya',
    },
    customerId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11',
    pickupDate: 'Thu Feb 16 2023 14:47:19 GMT+0300 (East Africa Time)',
    deliveryDate: 'Thu Feb 18 2023 14:47:19 GMT+0300 (East Africa Time)',
    shipperId: '61',
    pickupTime: '2023-02-16T09:00:00.461Z',
    lpoNo: '2302',
    lpoDate: '2023-02-18T11:47:19.228Z',
    contactPersonId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
    contactPerson: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
      name: 'Deja Brady',
      email: 'milo.farrell@hotmail.com',
      department: 'accounting',
      branch: 'Nikolaus - Leuschke',
      phoneNumber: '(+254) 723-102-818',
      role: 'backend developer',
    },
    orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7c36',
    status: 'In transit',
    // has no dnote status since no dnote was entered
    driverInformation: {
      phone_no: '(+254)722-989-417',
      id_no: '13538275',
      avatar: _mock.image.avatar('3'),
      name: _mock.name.fullName(1),
    },
    vehicleInformation: {
      registration_no: 'KBK 797Q',
    },
    dummy: true
  },
  {
    orderLines: [
      {
        weight: 32,
        quantity: 23,
        item_id: 1,
        pickup_point_address: '',
        item_name: 'Medium Box',
        weight_unit: 'kg',
      },
    ],
    customer: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11',
      name: 'Naivas Likoni',
      address: 'Naivas Supermarket - Likoni, Near Likoni Ferry, Nyerere Ave, Mombasa, Kenya',
    },
    customerId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11',
    pickupDate: 'Thu Feb 16 2023 14:47:19 GMT+0300 (East Africa Time)',
    deliveryDate: 'Thu Feb 18 2023 14:47:19 GMT+0300 (East Africa Time)',
    shipperId: '61',
    pickupTime: '2023-02-16T09:00:00.461Z',
    lpoNo: '2132',
    lpoDate: '2023-02-18T11:47:19.228Z',
    contactPersonId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
    contactPerson: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
      name: 'Deja Brady',
      email: 'milo.farrell@hotmail.com',
      department: 'accounting',
      branch: 'Nikolaus - Leuschke',
      phoneNumber: '(+254) 723-102-818',
      role: 'backend developer',
    },
    orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2as7b36',
    status: 'In transit',
    // has no dnote status since no dnote was entered
    driverInformation: {
      phone_no: '(+254)722-989-417',
      id_no: '13538275',
      avatar: _mock.image.avatar('3'),
      name: _mock.name.fullName(1),
    },
    vehicleInformation: {
      registration_no: 'KBK 797Q',
    },
    dummy: true
  },
  {
    orderLines: [
      {
        weight: 32,
        quantity: 23,
        item_id: 1,
        pickup_point_address: '',
        item_name: 'Big Box',
        weight_unit: 'kg',
      },
    ],
    customer: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11',
      name: 'Naivas Likoni',
      address: 'Naivas Supermarket - Likoni, Near Likoni Ferry, Nyerere Ave, Mombasa, Kenya',
    },
    customerId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11',
    pickupDate: 'Thu Feb 16 2023 14:47:19 GMT+0300 (East Africa Time)',
    deliveryDate: 'Thu Feb 18 2023 14:47:19 GMT+0300 (East Africa Time)',
    shipperId: '61',
    pickupTime: '2023-02-16T09:00:00.461Z',
    lpoNo: '22432',
    lpoDate: '2023-02-18T11:47:19.228Z',
    contactPersonId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
    contactPerson: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
      name: 'Deja Brady',
      email: 'milo.farrell@hotmail.com',
      department: 'accounting',
      branch: 'Nikolaus - Leuschke',
      phoneNumber: '(+254) 723-102-818',
      role: 'backend developer',
    },
    orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80o2d7c56',
    status: 'In transit',
    // has no dnote status since no dnote was entered
    driverInformation: {
      phone_no: '(+254)722-989-417',
      id_no: '13538275',
      avatar: _mock.image.avatar('3'),
      name: _mock.name.fullName(1),
    },
    vehicleInformation: {
      registration_no: 'KBK 797Q',
    },
    dummy: true
  },
  {
    orderLines: [
      {
        weight: 32,
        quantity: 23,
        item_id: 1,
        pickup_point_address: '',
        item_name: 'Big Box',
        weight_unit: 'kg',
      },
    ],
    customer: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11',
      name: 'Naivas Westside',
      address: 'Westside Mall, 20100, Kenyatta Avenue, West Road Junction, Nakuru, Kenya',
    },
    customerId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11',
    pickupDate: 'Thu Mar 07 2023 14:47:19 GMT+0300 (East Africa Time)',
    deliveryDate: 'Thu Mar 09 2023 14:47:19 GMT+0300 (East Africa Time)',
    shipperId: '61',
    pickupTime: '2023-03-07T09:00:00.461Z',
    lpoNo: '21332',
    lpoDate: '2023-03-10T11:47:19.228Z',
    contactPersonId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
    contactPerson: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
      name: 'Deja Brady',
      email: 'milo.farrell@hotmail.com',
      department: 'accounting',
      branch: 'Nikolaus - Leuschke',
      phoneNumber: '(+254) 723-102-818',
      role: 'backend developer',
    },
    orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7f43',
    status: 'In transit',
    // has no dnote status since no dnote was entered
    driverInformation: {
      phone_no: '(+254)722-989-417',
      id_no: '13538275',
      avatar: _mock.image.avatar('3'),
      name: _mock.name.fullName(1),
    },
    vehicleInformation: {
      registration_no: 'KBK 797Q',
    },
    dummy: true
  },

  // order from skip dnote image path that is currently scheduled,
  // Notice the lack of driver and vehicle information as they are not yet assigned
  {
    orderLines: [
      {
        weight: 32,
        quantity: 23,
        item_id: 1,
        pickup_point_address: '',
        item_name: 'Big Box', // This should be added even for items in the dropdown
        weight_unit: 'kg',
      },
    ],
    customer: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11',
      name: 'Naivas Likoni',
      address: 'Naivas Supermarket - Likoni, Near Likoni Ferry, Nyerere Ave, Mombasa, Kenya',
    },
    customerId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11',
    pickupDate: 'Thu Feb 16 2023 14:47:19 GMT+0300 (East Africa Time)',
    deliveryDate: 'Thu Feb 18 2023 14:47:19 GMT+0300 (East Africa Time)',
    shipperId: '61',
    pickupTime: '2023-02-16T09:00:00.461Z',
    lpoNo: '2332',
    lpoDate: '2023-02-18T11:47:19.228Z',
    contactPersonId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
    contactPerson: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
      name: 'Deja Brady',
      email: 'milo.farrell@hotmail.com',
      department: 'accounting',
      branch: 'Nikolaus - Leuschke',
      phoneNumber: '(+254) 723-102-818',
      role: 'backend developer',
    },
    orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b37',
    status: 'scheduled',
    dummy: true
    // has no dnote status since trip has not started and no dnote was entered
  },

  // order from dnote image path that has been completed
  // notice that it has its hasDnoteItems set to false
  {
    orderLines: [
      {
        weight: 2,
        quantity: 1,
        item_id: 2,
        item_name: 'Small Box', // This should be added even for items in the dropdown
        pickup_point_address: '',
        weight_unit: 'kg',
      },
    ],
    customer: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
      name: 'Majid Nyali',
      address: 'Carrefour City Mall, Nyali, Malindi Rd, Mombasa, Kenya',
    },
    customerId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
    pickupDate: 'Thu Feb 27 2023 12:34:45 GMT+0300 (East Africa Time)',
    deliveryDate: 'Thu Feb 29 2023 12:34:45 GMT+0300 (East Africa Time)',
    shipperId: '61',
    pickupTime: '2023-02-27T09:00:00.461Z',
    lpoNo: '21390',
    lpoDate: '2023-02-28T09:34:45.321Z',
    contactPersonId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
    contactPerson: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
      name: 'Reece Chung',
      email: 'letha_lubowitz24@yahoo.com',
      department: 'management',
      branch: 'Grimes Inc',
      phoneNumber: '(+254) 790-607-879',
      role: 'ux designer',
    },
    orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b31',
    status: 'completed',
    dnoteStatus: 'transit',
    dnoteLines: [
      {
        inventoryId: '2',
        unit: 'PCS',
        quantity: 32,
        itemCode: '',
        itemDescription: 'CRISPS - HEROES - CHEESE & ONION 36 X 40G PKT', // This should be added even for items in the dropdown
      },
    ],
    dnoteNo: 'S0150923',
    hasDnoteItems: true,
    driverInformation: {
      phone_no: '(+254)722-989-417',
      id_no: '13538275',
      avatar: _mock.image.avatar('4'),
      name: _mock.name.fullName(4),
    },
    vehicleInformation: {
      registration_no: 'KBQ 891V',
    },
    dummy: true
  },
  {
    orderLines: [
      {
        weight: 2,
        quantity: 1,
        item_id: 2,
        item_name: 'Medium Box', // This should be added even for items in the dropdown
        pickup_point_address: '',
        weight_unit: 'kg',
      },
    ],
    customer: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
      name: 'Season Energy',
      address: 'Season Energy Limited, Molem Next Shell, Kenya',
    },
    customerId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
    pickupDate: 'Thu Feb 23 2023 12:35:46 GMT+0300 (East Africa Time)',
    deliveryDate: 'Thu Feb 25 2023 12:35:46 GMT+0300 (East Africa Time)',
    shipperId: '61',
    pickupTime: '2023-02-25T09:00:00.461Z',
    lpoNo: '23412456',
    lpoDate: '2023-02-26T09:35:46.917Z',
    contactPersonId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
    contactPerson: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
      name: 'Deja Brady',
      email: 'milo.farrell@hotmail.com',
      department: 'accounting',
      branch: 'Nikolaus - Leuschke',
      phoneNumber: '(+254) 723-102-818',
      role: 'backend developer',
    },
    orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b32',
    status: 'completed',
    dnoteStatus: 'received',
    images: [
      {
        path: 'Signed Delivery note',
        preview: 'https://i.postimg.cc/ZRjv17qP/IMG-20230104-123732-551.jpg',
      },
    ],
    dnoteNo: 'S0150917',
    hasDnoteItems: false,
    driverInformation: {
      phone_no: '(+254)722-989-417',
      id_no: '13538275',
      avatar: _mock.image.avatar('1'),
      name: _mock.name.fullName(2),
    },
    vehicleInformation: {
      registration_no: 'KDH 997Q',
    },
    dummy: true
  },
  {
    orderLines: [
      {
        weight: 2,
        quantity: 1,
        item_id: 2,
        item_name: 'Big Box', // This should be added even for items in the dropdown
        pickup_point_address: '',
        weight_unit: 'kg',
      },
    ],
    customer: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
      name: 'Naivas Likoni',
      address: 'Naivas Supermarket - Likoni, Near Likoni Ferry, Nyerere Ave, Mombasa, Kenya',
    },
    customerId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
    pickupDate: 'Thu Feb 21 2023 12:35:46 GMT+0300 (East Africa Time)',
    deliveryDate: 'Thu Feb 22 2023 12:35:46 GMT+0300 (East Africa Time)',
    shipperId: '61',
    pickupTime: '2023-02-21T09:00:00.461Z',
    lpoNo: '2390456',
    lpoDate: '2023-02-23T09:35:46.917Z',
    contactPersonId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
    contactPerson: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
      name: 'Deja Brady',
      email: 'milo.farrell@hotmail.com',
      department: 'accounting',
      branch: 'Nikolaus - Leuschke',
      phoneNumber: '(+254) 723-102-818',
      role: 'backend developer',
    },
    orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7s32',
    status: 'completed',
    dnoteStatus: 'received',
    images: [
      {
        path: 'Signed Delivery note',
        preview: 'https://i.postimg.cc/ZRjv17qP/IMG-20230104-123732-551.jpg',
      },
    ],
    dnoteNo: 'S0150917',
    hasDnoteItems: false,
    driverInformation: {
      phone_no: '(+254)722-989-417',
      id_no: '13538275',
      avatar: _mock.image.avatar('1'),
      name: _mock.name.fullName(2),
    },
    vehicleInformation: {
      registration_no: 'KDH 997Q',
    },
    dummy: true
  },
  {
    orderLines: [
      {
        weight: 2,
        quantity: 1,
        item_id: 2,
        item_name: 'Big Box', // This should be added even for items in the dropdown
        pickup_point_address: '',
        weight_unit: 'kg',
      },
    ],
    customer: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
      name: 'Naivas Embu Pearl Centre',
      address: 'Naivas Pearl Centre(Embu), Pearl centre, Embu, Kenya',
    },
    customerId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
    pickupDate: 'Thu Feb 18 2023 12:35:46 GMT+0300 (East Africa Time)',
    deliveryDate: 'Thu Feb 20 2023 12:35:46 GMT+0300 (East Africa Time)',
    shipperId: '61',
    pickupTime: '2023-02-18T09:00:00.461Z',
    lpoNo: '2391456',
    lpoDate: '2023-02-21T09:35:46.917Z',
    contactPersonId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
    contactPerson: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
      name: 'Deja Brady',
      email: 'milo.farrell@hotmail.com',
      department: 'accounting',
      branch: 'Nikolaus - Leuschke',
      phoneNumber: '(+254) 723-102-818',
      role: 'backend developer',
    },
    orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7l32',
    status: 'completed',
    dnoteStatus: 'received',
    images: [
      {
        path: 'Signed Delivery note',
        preview: 'https://i.postimg.cc/ZRjv17qP/IMG-20230104-123732-551.jpg',
      },
    ],
    dnoteNo: 'S0150917',
    hasDnoteItems: false,
    driverInformation: {
      phone_no: '(+254)722-989-417',
      id_no: '13538275',
      avatar: _mock.image.avatar('1'),
      name: _mock.name.fullName(2),
    },
    vehicleInformation: {
      registration_no: 'KDH 997Q',
    },
    dummy: true
  },
  {
    orderLines: [
      {
        weight: 2,
        quantity: 1,
        item_id: 2,
        item_name: 'Small Box', // This should be added even for items in the dropdown
        pickup_point_address: '',
        weight_unit: 'kg',
      },
    ],
    customer: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
      name: 'Total Narumoru',
      address: 'Total Naro Moru Service Station, A2, Naro Moru, Kenya',
    },
    customerId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
    pickupDate: 'Thu Feb 14 2023 12:35:46 GMT+0300 (East Africa Time)',
    deliveryDate: 'Thu Feb 16 2023 12:35:46 GMT+0300 (East Africa Time)',
    shipperId: '61',
    pickupTime: '2023-02-14T09:00:00.461Z',
    lpoNo: '234456',
    lpoDate: '2023-02-18T09:35:46.917Z',
    contactPersonId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
    contactPerson: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
      name: 'Deja Brady',
      email: 'milo.farrell@hotmail.com',
      department: 'accounting',
      branch: 'Nikolaus - Leuschke',
      phoneNumber: '(+254) 723-102-818',
      role: 'backend developer',
    },
    orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7z32',
    status: 'completed',
    dnoteStatus: 'received',
    images: [
      {
        path: 'Signed Delivery note',
        preview: 'https://i.postimg.cc/ZRjv17qP/IMG-20230104-123732-551.jpg',
      },
    ],
    dnoteNo: 'S0150917',
    hasDnoteItems: false,
    driverInformation: {
      phone_no: '(+254)722-989-417',
      id_no: '13538275',
      avatar: _mock.image.avatar('1'),
      name: _mock.name.fullName(2),
    },
    vehicleInformation: {
      registration_no: 'KDH 997Q',
    },
    dummy: true
  },

  // order from dnote list path that is being picked up
  // Notice that it has its hasDnoteItems set to true
  {
    orderLines: [
      {
        weight: 2,
        quantity: 1,
        item_id: 2,
        item_name: 'Small Box', // This should be added even for items in the dropdown
        pickup_point_address: '',
        weight_unit: 'kg',
      },
    ],
    customer: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
      name: 'Naivas Nakuru DownTown',
      address: 'Naivas Supermarket Nakuru Downtown, BENKO FRESH &JUICY, Oginga Odinga Ave, Nakuru, Kenya',
    },
    customerId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
    pickupDate: 'Thu Mar 08 2023 12:34:45 GMT+0300 (East Africa Time)',
    deliveryDate: 'Thu Mar 10 2023 12:34:45 GMT+0300 (East Africa Time)',
    shipperId: '61',
    pickupTime: '2023-03-10T09:00:00.461Z',
    lpoNo: '21312',
    lpoDate: '2023-03-11T09:34:45.321Z',
    contactPersonId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
    contactPerson: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
      name: 'Reece Chung',
      email: 'letha_lubowitz24@yahoo.com',
      department: 'management',
      branch: 'Grimes Inc',
      phoneNumber: '(+254) 790-607-879',
      role: 'ux designer',
    },
    orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7z31',
    status: 'picked',
    dnoteStatus: 'transit',
    dnoteLines: [
      {
        inventoryId: '2',
        unit: 'PCS',
        quantity: 32,
        itemCode: '',
        itemDescription: 'CRISPS - HEROES - CHEESE & ONION 36 X 40G PKT', // This should be added even for items in the dropdown
      },
    ],
    dnoteNo: 'S0150923',
    hasDnoteItems: true,
    driverInformation: {
      phone_no: '(+254)722-989-417',
      id_no: '13538275',
      avatar: _mock.image.avatar('4'),
      name: _mock.name.fullName(4),
    },
    vehicleInformation: {
      registration_no: 'KBQ 891V',
    },
    dummy: true
  },
  {
    orderLines: [
      {
        weight: 2,
        quantity: 1,
        item_id: 2,
        item_name: 'Small Box', // This should be added even for items in the dropdown
        pickup_point_address: '',
        weight_unit: 'kg',
      },
    ],
    customer: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
      name: 'Majid Nyali',
      address: 'Carrefour City Mall, Nyali, Malindi Rd, Mombasa, Kenya',
    },
    customerId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
    pickupDate: 'Thu Feb 16 2023 12:34:45 GMT+0300 (East Africa Time)',
    deliveryDate: 'Thu Feb 18 2023 12:34:45 GMT+0300 (East Africa Time)',
    shipperId: '61',
    pickupTime: '2023-02-16T09:00:00.461Z',
    lpoNo: '21331',
    lpoDate: '2023-02-18T09:34:45.321Z',
    contactPersonId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
    contactPerson: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
      name: 'Reece Chung',
      email: 'letha_lubowitz24@yahoo.com',
      department: 'management',
      branch: 'Grimes Inc',
      phoneNumber: '(+254) 790-607-879',
      role: 'ux designer',
    },
    orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7tre4',
    status: 'picked',
    dnoteStatus: 'transit',
    dnoteLines: [
      {
        inventoryId: '2',
        unit: 'PCS',
        quantity: 32,
        itemCode: '',
        itemDescription: 'CRISPS - HEROES - CHEESE & ONION 36 X 40G PKT', // This should be added even for items in the dropdown
      },
    ],
    dnoteNo: 'S0150923',
    hasDnoteItems: true,
    driverInformation: {
      phone_no: '(+254)722-989-417',
      id_no: '13538275',
      avatar: _mock.image.avatar('4'),
      name: _mock.name.fullName(4),
    },
    vehicleInformation: {
      registration_no: 'KBQ 891V',
    },
    dummy: true
  },
];
