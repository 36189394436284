import PropTypes from 'prop-types';
import { useState } from 'react';
import { Card, Grid, Typography, Stack, Divider, Tab } from '@mui/material';
import { TabList, TabPanel, TabContext } from '@mui/lab';
// hooks
import { useTheme } from '@mui/material/styles';

import OrderTimelines from '../tracking-deliveries/OrderTimelines';
import OrderDetails from '../tracking-deliveries/OrderDetails';
import DriverInformation from '../tracking-deliveries/DriverInformation';
import ContactPersonInfo from '../tracking-deliveries/ContactPersonInfo';
import Label from '../../../../components/Label';
// hooks

// utils
import { fDate } from '../../../../utils/formatTime';

TrackDeliveryCard.propTypes = {
  order: PropTypes.object,
};
export default function TrackDeliveryCard({ order }) {
  const theme = useTheme();
  const [openedTabs, setOpenedTabs] = useState({});

  const onTabChange = (e, value, orderId) => {
    setOpenedTabs({
      ...openedTabs,
      [orderId]: { value: `${value}` },
    });
  };

  return (
    <Card sx={{ p: 3 }}>
      <Grid container spacing={2}>
        {/* Customer */}
        <Grid item xs={12} md={5}>
          <Stack direction="column">
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Customer
            </Typography>

            <Typography variant="subtitle1">{order.customer.name}</Typography>

            <Typography variant="subtitle1" sx={{ color: 'primary.main', my: 0.5, fontWeight: 'fontWeightMedium' }}>
              {order.customer.address}
            </Typography>
          </Stack>
        </Grid>
        {/* Customer */}

        {/* Order Id */}
        <Grid item xs={12} sm={6} md={3}>
          <Stack>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Order ID
            </Typography>

            <Typography variant="subtitle1">{order.orderId.slice(-10)}</Typography>
          </Stack>
        </Grid>
        {/* Order Id */}

        {/* LPO NO */}
        <Grid item xs={6} sm={6} md={2}>
          <Stack>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              LPO No
            </Typography>
            <Typography variant="subtitle1">{order.lpoNo}</Typography>
          </Stack>
        </Grid>
        {/* LPO NO */}

        {/* LPO Expiry Date */}
        <Grid item xs={6} sm={6} md={2}>
          <Stack>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              LPO Expiry Date
            </Typography>
            <Typography variant="subtitle1">{fDate(order.lpoDate)}</Typography>
          </Stack>
        </Grid>
      </Grid>

      <Divider sx={{ my: 3 }} />

      <Grid container spacing={2}>
        {/* Return Reason */}
        <Grid item xs={12} sm={6} md={4}>
          <Stack>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Reason
            </Typography>

            <Typography variant="subtitle1">{order.returnReason}</Typography>
          </Stack>
        </Grid>
      </Grid>

      <Divider sx={{ my: 3 }} />

      <Grid container sx={{ pt: 1 }}>
        {/* OrderTimeline */}
        <Grid item md={10}>
          <OrderTimelines order={order} />
        </Grid>
        {/* OrderTimeline */}

        {/* Label */}
        <Grid item md={2}>
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={
              (order.status === 'scheduled' && 'default') ||
              (order.status === 'picked' && 'warning') ||
              (order.status === 'In transit' && 'info') ||
              (order.status === 'At Outlet' && 'info') ||
              (order.status === 'completed' && 'primary') ||
              'default'
            }
            sx={{ textTransform: 'capitalize', height: 32, width: 92 }}
          >
            {order.status}
          </Label>
        </Grid>
        {/* Label */}
      </Grid>

      <Stack direction="column">
        <TabContext value={openedTabs[order.orderId]?.value || '1'}>
          <TabList onChange={(e, value) => onTabChange(e, value, order.orderId)}>
            <Tab disableRipple value="1" label="Contact Person Information" />
            <Tab disableRipple value="2" label="Driver Information" />
            <Tab disableRipple value="3" label="Order Details" />
          </TabList>

          <Divider />

          {/* Driver Information */}
          <TabPanel value="2">
            <DriverInformation order={order} />
          </TabPanel>
          {/* Driver Information */}

          {/* OrderDetails */}
          <TabPanel value="3">
            <OrderDetails order={order} />
          </TabPanel>
          {/* OrderDetails */}

          {/* ContactPerson */}
          <TabPanel value="1">
            <ContactPersonInfo order={order} />
          </TabPanel>
          {/* ContactPerson */}
        </TabContext>
      </Stack>
    </Card>
  );
}
