import { Button, Snackbar } from '@mui/material';
import { useState } from 'react';

export default function PwaSnackBar() {
  const vertical = 'bottom';
  const horizontal = 'center';
  const [refreshing, setRefreshing] = useState(false);
  const [registration, setRegistration] = useState(null);
  const [updateExists, setUpdateExists] = useState(false);

  // Store the SW registration so we can send it a message
  // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
  // To alert the user there is an update they need to refresh for
  const updateAvailable = (event) => {
    console.log('Update available');
    setRegistration(event.detail);
    setUpdateExists(true);
  };

  // Called when the user accepts the update
  const refreshApp = () => {
    setUpdateExists(false);
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!registration || !registration.waiting) return;
    // send message to SW to skip the waiting and activate the new SW
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    // LOCAL_STORAGE.clear()
    // window.localStorage.clear();
  };

  // Listen for our custom event from the SW registration
  document?.addEventListener('swUpdated', updateAvailable, {
    once: true,
  });

  // Prevent multiple refreshes
  navigator?.serviceWorker?.addEventListener('controllerchange', () => {
    if (refreshing) return;
    setRefreshing(true);
    // Here the actual reload of the page occurs
    window.location.reload();
  });

  const handleUpdate = () => {
    refreshApp();
  };

  return (
    <Snackbar
      open={updateExists}
      autoHideDuration={6000}
      message="A new update is available!"
      anchorOrigin={{ vertical, horizontal }}
      action={
        <Button color="inherit" size="small" onClick={handleUpdate}>
          Update
        </Button>
      }
      sx={{ bottom: { xs: 50, sm: 50 } }}
    />
  );
}
