import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
// utils
import axios from '../../utils/axios';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  if (!Array.isArray(array)) {
    return {};
  }
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: null,
  board: {
    cards: {},
    columns: {},
    columnOrder: [],
    cardsObj: {},
  },
};

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    clearError(state) {
      state.error = null;
    },

    // GET BOARD
    getBoardSuccess(state, action) {
      state.isLoading = false;
      const board = action.payload?.attributes?.pickup_requests?.data || {};
      const pickupRequests = board;
      const cards = pickupRequests;
      console.log('pickupRequests');
      
      const cardsObj = objFromArray(cards);

      const columns = [
        {
          name: 'Scheduled',
          cardIds: [],
          id: 0,
          info: 'This column has pickup requests whose orders have the following statuses: Scheduled',
        },
        {
          name: 'Ongoing',
          cardIds: [],
          id: 1,
          info: 'This column has pickup requests whose orders have the following statuses: At pickup-up(arrived), Onloading, Picked, In transit, At drop-off(arrived), At drop-off(Receiver unavailable), Offloading',
        },
        {
          name: 'Completed',
          cardIds: [],
          id: 2,
          info: 'This column has pickup requests whose orders have the following statuses: Offloaded, Offloaded(paperwork left at the outlet), Asked to redeliver, Returns, Missing items, Completed(soft-paperwork), Completed',
        },
      ];

      // sort out pickup requests into columns
      if (cards.length > 0) {
        cards?.forEach((card) => {
          const statusesForPickupRequest = card.attributes.summarized_order_statuses || [];

          // Scheduled
          if (statusesForPickupRequest.length > 0) {
            if (statusesForPickupRequest.includes('Scheduled')) {
              columns[0].cardIds.push(card.id);
            }

            // Ongoing
            if (
              statusesForPickupRequest.includes('At pickup-up(arrived)') ||
              statusesForPickupRequest.includes('Onloading') ||
              statusesForPickupRequest.includes('Picked') ||
              statusesForPickupRequest.includes('In transit') ||
              statusesForPickupRequest.includes('At drop-off(arrived)') ||
              statusesForPickupRequest.includes('At drop-off(Receiver unavailable)') ||
              statusesForPickupRequest.includes('Offloading')
            ) {
              columns[1].cardIds.push(card.id);
            }

            // Completed
            if (
              statusesForPickupRequest.includes('Offloaded') ||
              statusesForPickupRequest.includes('Offloaded(paperwork left at the outlet)') ||
              statusesForPickupRequest.includes('Asked to redeliver') ||
              statusesForPickupRequest.includes('Returns') ||
              statusesForPickupRequest.includes('Missing items') ||
              statusesForPickupRequest.includes('Completed(soft-paperwork)') ||
              statusesForPickupRequest.includes('Completed') ||
              statusesForPickupRequest.includes('Rejected(double order)') ||
              statusesForPickupRequest.includes('Rejected(not ordered)') ||
              statusesForPickupRequest.includes('Rejected(wrong items delivered)')
            ) {
              columns[2].cardIds.push(card.id);
            }
          }
        });
      }

      const columnOrder = [
        {
          name: 'Scheduled',
          cardIds: [],
          id: 0,
          columns: board[0],
        },
        {
          name: 'In transit',
          cardIds: [],
          id: 1,
          columns: board[1],
        },
        {
          name: 'Completed',
          cardIds: [],
          id: 2,
          columns: board[2],
        },
      ];

      state.board = {
        cards,
        columns,
        columnOrder,
        cardsObj,
      };

    },

    // CREATE NEW COLUMN
    createColumnSuccess(state, action) {
      const newColumn = action.payload;
      state.isLoading = false;
      state.board.columns = {
        ...state.board.columns,
        [newColumn.id]: newColumn,
      };
      state.board.columnOrder.push(newColumn.id);
    },

    persistCard(state, action) {
      const columns = action.payload;
      state.board.columns = columns;
    },

    persistColumn(state, action) {
      state.board.columnOrder = action.payload;
    },

    addTask(state, action) {
      const { card, columnId } = action.payload;

      state.board.cards[card.id] = card;
      state.board.columns[columnId].cardIds.push(card.id);
    },

    deleteTask(state, action) {
      const { cardId, columnId } = action.payload;

      state.board.columns[columnId].cardIds = state.board.columns[columnId].cardIds.filter((id) => id !== cardId);
      state.board.cards = omit(state.board.cards, [cardId]);
    },

    // UPDATE COLUMN
    updateColumnSuccess(state, action) {
      const column = action.payload;

      state.isLoading = false;
      state.board.columns[column.id] = column;
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      const { columnId } = action.payload;
      const deletedColumn = state.board.columns[columnId];

      state.isLoading = false;
      state.board.columns = omit(state.board.columns, [columnId]);
      state.board.cards = omit(state.board.cards, [...deletedColumn.cardIds]);
      state.board.columnOrder = state.board.columnOrder.filter((c) => c !== columnId);
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getBoard(shipperId = '', filterPickupDateFrom = '', filterPickupDateEnd = '') {
  return async () => {
    const oldestAllowedDate = new Date(new Date().setDate(new Date().getDate() - 60));

    if (filterPickupDateFrom !== '' && filterPickupDateFrom.toISOString() < oldestAllowedDate.toISOString()) {
      dispatch(slice.actions.hasError("Please select a date within 2 months from today's date."));
      return;
    }

    dispatch(slice.actions.startLoading());
    const startDate = filterPickupDateFrom ? filterPickupDateFrom.toISOString().slice(0, 10) : '';
    const endDate = filterPickupDateEnd ? filterPickupDateEnd.toISOString().slice(0, 10) : '';
    const url =
      startDate === '' || endDate === ''
        ? `/api/v1/orders/shipper_grouped?shipper_id=${shipperId}`
        : `/api/v1/orders/shipper_grouped?shipper_id=${shipperId}&start_date=${startDate}&end_date=${endDate}`;
    try {
      const response = await axios.get(url);
      const res = response?.data?.data[0] || {};
      dispatch(slice.actions.getBoardSuccess(res));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createColumn(newColumn) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/kanban/columns/new', newColumn);
      dispatch(slice.actions.createColumnSuccess(response.data.column));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateColumn(columnId, updateColumn) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/kanban/columns/update', {
        columnId,
        updateColumn,
      });
      dispatch(slice.actions.updateColumnSuccess(response.data.column));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteColumn(columnId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/kanban/columns/delete', { columnId });
      dispatch(slice.actions.deleteColumnSuccess({ columnId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function persistColumn(newColumnOrder) {
  return () => {
    dispatch(slice.actions.persistColumn(newColumnOrder));
  };
}

// ----------------------------------------------------------------------

export function persistCard(columns) {
  return () => {
    dispatch(slice.actions.persistCard(columns));
  };
}

// ----------------------------------------------------------------------

export function addTask({ card, columnId }) {
  return () => {
    dispatch(slice.actions.addTask({ card, columnId }));
  };
}

// ----------------------------------------------------------------------

export function deleteTask({ cardId, columnId }) {
  return (dispatch) => {
    dispatch(slice.actions.deleteTask({ cardId, columnId }));
  };
}

export function clearError() {
  return (dispatch) => {
    dispatch(slice.actions.clearError());
  };
}
