import { add } from 'date-fns';
import _mock from './_mock';
import { randomInArray } from './funcs';

// ----------------------------------------------------------------------
const sampleItems = [
  {
    id: 1,
    description: 'CRISPS - HEROES -  CHEESE & ONION 36 X 40G PKT',
    quantity: 10,
    units: 'OUTERS',
    status: 'Delivered',
    missingQuantity: 0,
    returnedQuantity: 0,
    damagedQuantity: 0,
  },
  {
    id: 2,
    description: 'CRISPS-SALT & VINEGAR 24 X 100 GMS PKT',
    quantity: 6,
    units: 'OUTERS',
    status: 'Delivered',
    missingQuantity: 0,
    returnedQuantity: 0,
    damagedQuantity: 0,
  },
  {
    id: 3,
    description: 'CRISPS-SALTED 6 X 400 GMS PKT',
    quantity: 4,
    units: 'OUTERS',
    status: 'Delivered',
    missingQuantity: 0,
    returnedQuantity: 0,
    damagedQuantity: 0,
  },
];
// -----------------------------------------------------

const sampleImages = [
  {
    id: 1,
    title: 'S0150923 P1',
    postAt: _mock.time(0),
    imageUrl: 'https://i.postimg.cc/ZRjv17qP/IMG-20230104-123732-551.jpg',
  },
];

export const _dnotes = [...Array(20)].map((_, index) => ({
  id: _mock.id(index),
  orderNumber: `${17048 + index}`,
  pickupDate: add(new Date(), { days: index, hours: index }),
  deliveryDate: add(new Date(), { days: index + 15, hours: index }),
  status: randomInArray(['transit', 'destination', 'office', 'received']),
  lpoNo: 'PO13844072',
  dnoteNo: 'S0150923',
  From: {
    id: _mock.id(index),
    name: _mock.name.fullName(index),
    address: _mock.address.fullAddress(index),
    company: _mock.company(index),
    email: _mock.email(index),
    phone: _mock.phoneNumber(index),
  },
  customer: {
    id: _mock.id(index + 1),
    name: _mock.name.fullName(index + 1),
    address: _mock.address.fullAddress(index + 1),
    company: _mock.company(index + 1),
    email: _mock.email(index + 1),
    phone: _mock.phoneNumber(index + 1),
  },
  items: sampleItems,
  images: sampleImages,
}));
