import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';

const isDev = process.env.NODE_ENV === 'development';

const analytics = isDev
  ? Analytics({
      app: 'demo-shipper.senga.co',
      plugins: [
        segmentPlugin({
          writeKey: process.env.REACT_APP_SEGMENT_ANALYTICS_ID,
        }),
      ],
    })
  : Analytics({
      app: 'shipper.senga.co',
      plugins: [
        segmentPlugin({
          writeKey: process.env.REACT_APP_SEGMENT_ANALYTICS_ID,
        }),
      ],
    });

export default analytics;
