import { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, CardHeader, Button, Stack, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// utils
import { fDateTime } from '../../../../utils/formatTime';
// components
import Iconify from '../../../../components/Iconify';
import ReturnCard from './ReturnCard';
import ConfirmReturnCard from './ConfirmReturnCard';
import RepresentativeCard from './RepresentativeCard';

const returnOrder = {
  orderLines: [
    {
      weight: 32,
      quantity: 23,
      item_id: 1,
      pickup_point_address: '',
      item_name: 'Big Box',
      weight_unit: 'kg',
    },
  ],
  customer: {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11',
    name: 'Naivas Likoni',
    address: 'Naivas Supermarket - Likoni, Near Likoni Ferry, Nyerere Ave, Mombasa, Kenya',
  },
  customerId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11',
  pickupDate: 'Thu Feb 16 2023 14:47:19 GMT+0300 (East Africa Time)',
  deliveryDate: 'Thu Feb 18 2023 14:47:19 GMT+0300 (East Africa Time)',
  shipperId: '61',
  pickupTime: '2023-02-16T09:00:00.461Z',
  lpoNo: '2332',
  lpoDate: '2023-02-18T11:47:19.228Z',
  contactPersonId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
  contactPerson: {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
    name: 'Salim Farah',
    email: 'salim.farah@gmail.com',
    department: 'accounting',
    branch: 'Nikolaus - Leuschke',
    phoneNumber: '(+254) 723-102-818',
    role: 'backend developer',
  },
  orderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b36',
  status: 'At Outlet',
  hasReturns: true,
  returnReason: 'Oversupply of items',
  // has no dnote status since no dnote was entered
  driverInformation: {
    phone_no: '(+254)722-989-417',
    id_no: '28538275',
    name: 'George Mbugua',
  },
  vehicleInformation: {
    registration_no: 'KBK 797Q',
  },
};

ReturnDecisionCard.propTypes = {
  showReturnCustomerModal: PropTypes.func,
  hideReturnDecisionCard: PropTypes.func,
};

export default function ReturnDecisionCard({ showReturnCustomerModal, hideReturnDecisionCard }) {
  const [cardToShow, setCardToShow] = useState(1);

  const confirmBack = () => {
    setCardToShow(1);
  };
  const showConfirmCard = () => {
    setCardToShow(2);
  };
  const showRepresentativeCard = () => {
    setCardToShow(3);
  };

  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.background.senga_light;
  const error = theme.palette.error.main;

  return (
    <Card sx={{ p: 3 }} style={{ backgroundColor: `${PRIMARY_MAIN}`, border: `2px solid ${error}` }}>
      <CardHeader
        title={'URGENT: Your delivery to Naivas Likoni requires your attention'}
        subheader={
          'It is marked for returned with the reason: Oversupply of items. \n You may need to get in touch with your contact person at the outlet before making a decision. The contact details are provided below. '
        }
        sx={{
          '& .MuiCardHeader-action': {
            alignSelf: 'center',
          },
        }}
      />

      <CardHeader subheader={`Posted: ${fDateTime(new Date())}`} />
      <CardHeader
        subheader={
          'A default decision of returning the items to your premises will be made if the decision window of 20 minutes expires. You can change the default decision in your settings.'
        }
      />

      <Divider sx={{ my: 3 }} />
      <Grid>
        {cardToShow === 1 && <ReturnCard order={returnOrder} />}
        {cardToShow === 2 && (
          <ConfirmReturnCard onConfirmBack={confirmBack} onConfirmReturn={showReturnCustomerModal} />
        )}
        {cardToShow === 3 && (
          <RepresentativeCard onConfirmBack={confirmBack} onConfirmReturn={hideReturnDecisionCard} />
        )}{' '}
      </Grid>
      <Divider sx={{ my: 3 }} />
      {cardToShow === 1 && (
        <Stack direction="row" spacing={2} alignItems="flex-end" sx={{ flexGrow: 1 }}>
          <Button
            fullWidth
            variant="contained"
            endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
            onClick={showRepresentativeCard}
          >
            {/* LEAVE ITEMS FOR MY REPRESENTATIVE TO COLLECT* */}
            Leave items for my representative to collect
          </Button>

          <Button
            fullWidth
            variant="contained"
            color="error"
            endIcon={<Iconify icon={'eva:close-circle-fill'} />}
            onClick={showConfirmCard}
          >
            Return items to my premises
          </Button>
        </Stack>
      )}
    </Card>
  );
}
