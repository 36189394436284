import { useMemo, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSnackbar } from 'notistack';

// hooks
import { useSearchParams, useNavigate } from 'react-router-dom';
// hooks

// @mui
import { Container, Box } from "@mui/material";
import { LoadingButton } from '@mui/lab';
// @mui
// components
import Page from "../../components/Page";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import Iconify from "../../components/Iconify";
import { FormProvider } from "../../components/hook-form";
// components
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// routes

// custom hooks
import useSettings from "../../hooks/useSettings";
import { useSelector, useDispatch } from "../../redux/store";
import useAuth from "../../hooks/useAuth";
import {
    getReturnReasons, getEditReturns,
    getEditReturnsSuccess, uploadSupportingDocuments,
    updateScheduledReturns
} from "../../redux/slices/returns";
import ScheduleReturnMultiple from "../../sections/@dashboard/tms/returns/schedule/ScheduleReturnMultiple";
// custom hooks

// helpers
import { NewReturnSchema } from "../../sections/@dashboard/tms/returns/schedule/utils/schema";
import { defaultEditReturn } from "../../sections/@dashboard/tms/returns/schedule/utils";

ScheduleReturnsEdit.propTypes = {}
export default function ScheduleReturnsEdit() {
    const { themeStretch } = useSettings();
    const { user } = useAuth();
    const shipperId = user?.shipperId;
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [queryParameters] = useSearchParams();
    const orderId = queryParameters.get('orderId');
    const returnDocReferenceId = queryParameters.get('returnDocReferenceId');
    const { editReturns } = useSelector((state) => state.returns);
    const { isLoading } = useSelector((state) => state.returns);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getEditReturns({ orderId, returnDocReferenceId }));
    }, [dispatch, orderId, returnDocReferenceId]);

    const defaultValues = useMemo(() => {
        if (!Object.keys(editReturns).length) return {}

        return { shipperId, returns: [defaultEditReturn(editReturns)] }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editReturns, shipperId, orderId, returnDocReferenceId]);

    const methods = useForm({
        resolver: yupResolver(NewReturnSchema),
        defaultValues
    });

    const { reset, handleSubmit } = methods;

    useEffect(() => (
        () => {
            reset()
            dispatch(getEditReturnsSuccess({}))
        }
    ), [dispatch, reset]);

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset, orderId]);


    useEffect(() => {
        dispatch(getReturnReasons());
    }, [dispatch]);

    const onSubmit = async (data) => {
        try {
            await dispatch(updateScheduledReturns({ data, shipperId, returnDocReferenceId }));
            await Promise.all([
                dispatch(uploadSupportingDocuments({ returnDocReferenceId, supportingDocuments: data.returns[0].supportingDocuments }))
            ]);
            enqueueSnackbar('Your changes have been saved successfully');
            navigate(PATH_DASHBOARD.tms.returns.deliveries);
        } catch (err) {
            console.log(err);
            enqueueSnackbar(err.message, { variant: 'error' });
        }
    }

    return (
        <Page title="Edit Returns">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <HeaderBreadcrumbs
                        heading="Edit Returns"
                        links={[
                            { name: 'Dashboard', href: PATH_DASHBOARD.root },
                            { name: 'Edit Returns' }
                        ]}
                        action={
                            <Box>
                                <LoadingButton
                                    variant="contained"
                                    type="submit"
                                    loading={isLoading}
                                    startIcon={<Iconify icon={'mdi:truck-fast-outline'} />}
                                    sx={{ textTransform: 'none' }}
                                >
                                    Save Changes
                                </LoadingButton>
                            </Box>
                        }
                    />
                    <ScheduleReturnMultiple editMode />
                </FormProvider>
            </Container>
        </Page>
    )
}