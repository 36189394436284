import PropTypes from 'prop-types';
import {useState} from 'react'
import { styled } from '@mui/material/styles';
import { Alert, IconButton, Collapse} from '@mui/material';
import Iconify from '../../../components/Iconify';


const StyledAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main, // your custom color
  color: theme.palette.primary.contrastText,
  fontSize: 15,
  fontWeight: 700

 }));
Banner.propTypes = {
  message: PropTypes.string
};
export default function Banner({message}) {
  const [open, setOpen] = useState(true)
  
  return (
    <Collapse in={open}>
      <StyledAlert 
        icon={ <Iconify icon="material-symbols:campaign"  color="primary.contrastText" fontSize="inherit" />}
        action={
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              > <Iconify icon ='eva:close-fill' fontSize="inherit" />
              </IconButton>
            }
          >
          {/* <AlertTitle sx={{ fontSize: '0.875rem'}}>Info</AlertTitle> */}
          {message}
      </StyledAlert>
    
    </Collapse>
    
  );
}
