import { useState } from 'react';
import PropTypes from 'prop-types';
import * as changeCase from "change-case";
// import { capitalCase } from 'change-case';
import { deserialize } from "deserialize-json-api";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, InputAdornment, Popper, Typography, Stack, Paper, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
// @mui
// utils
import axios from '../../../../../utils/axios';
// utils
// components
import InputStyle from '../../../../../components/InputStyle';
import Iconify from '../../../../../components/Iconify';
import { SkeletonCustomer } from '../../../../../components/skeleton';
// hooks
import useAuth from '../../../../../hooks/useAuth';

const PopperStyle = styled((props) => <Popper placement="bottom-start" {...props} />)({
    width: '280px !important',
});

let timeout;

CustomersSearch.propTypes = {
    label: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    focusstretch: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    field: PropTypes.object,
    error: PropTypes.object,
    openAddNewCustomerDialog: PropTypes.func.isRequired
}

export default function CustomersSearch({ label = "dropoff point", width = 230, focusstretch = 0, openAddNewCustomerDialog, field, error }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const auth = useAuth();
    const { user } = auth;

    // if searchResults is zero and the searchQ
    const isNotFound = searchResults.length < 1 && !isLoading && searchQuery.trim().length >= 1;

    const handleChangeSearch = async (value) => {
        try {
            setSearchQuery(value.trim());
            if (value && value.trim().length > 0) {
                const pathName = `/api/v1/customers/search?search_val=${value}&shipper_id=${user?.shipperId}`;
                // search for customer
                clearTimeout(timeout);
                setIsLoading(true);
                timeout = setTimeout(async () => {
                    const response = await axios.get(pathName);
                    const result = deserialize(response.data);
                    if (result.data.length > 0) {

                        setSearchResults(
                            result.data.map((customer) => {
                                const place = deserialize(customer.place).data
                                return {
                                    id: customer.id,
                                    name: customer.name,
                                    address: place.address,
                                    googlePlaceId: place.google_place_id,
                                    requireLpo: customer.require_lpo
                                }
                            })
                        );
                    } else {
                        setSearchResults([]);
                    }
                    setIsLoading(false);
                }, 400);
            } else {
                // if the value is empty
                setSearchResults([]);
                setIsLoading(false);
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Autocomplete
            {...field}
            autoHighlight
            popupIcon={null}
            PopperComponent={PopperStyle}
            options={searchResults}
            filterOptions={(x) => x}
            onInputChange={(event, value) => handleChangeSearch(value)}
            getOptionLabel={(customer) => customer?.name || ""}
            noOptionsText={
                <SearchNotFound searchQuery={searchQuery}
                    initialText={`Search for ${changeCase.capitalCase(label)}`}
                    isNotFound={isNotFound}
                    openAddNewCustomerDialog={openAddNewCustomerDialog} label={label}
                />
            }
            isOptionEqualToValue={(option, value) => (option.id.toString() === value.id.toString())}
            onChange={(event, newValue) => field.onChange(newValue)}
            renderInput={(params) => (
                <InputStyle
                    {...params}
                    stretchStart={width}
                    focusstretch={focusstretch}
                    label={label}
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
                            </InputAdornment>
                        ),
                        size: "small"
                    }}
                />
            )}
            renderOption={(props, customer, { inputValue }) => {
                const { name, address } = customer;
                const matches = match(name, inputValue);
                const parts = parse(name, matches);
                return (
                    <li {...props} key={customer.id}>
                        {
                            isLoading ? (
                                [...Array(5)].map((row, index) => (
                                    <SkeletonCustomer key={index} />
                                ))
                            ) : (
                                <Stack direction="column">
                                    <Stack direction="row" spacing={0.1}>
                                        {parts.map((part, index) => (
                                            <Typography
                                                key={index}
                                                component="span"
                                                variant="subtitle2"
                                                color={part.highlight ? 'primary' : 'textPrimary'}
                                                sx={{ pr: 0.07 }}
                                            >
                                                {part.text}
                                            </Typography>

                                        ))}
                                    </Stack>

                                    <Typography variant="caption" sx={{ color: 'text.secondary', my: 0.5, fontWeight: 'fontWeightMedium' }}>
                                        {address}
                                    </Typography>
                                </Stack>
                            )
                        }
                    </li>
                )
            }}
        />
    )
}
RHFCustomersSearch.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    focusstretch: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    openAddNewCustomerDialog: PropTypes.func
}
export function RHFCustomersSearch({ label = "dropoff point", width = 230, focusstretch = 0, name, openAddNewCustomerDialog, ...other }) {
    const methods = useFormContext();
    const { control } = methods;
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <CustomersSearch width={width} focusstretch={focusstretch}
                    label={label} field={field} error={error} {...other}
                    openAddNewCustomerDialog={openAddNewCustomerDialog}
                />
            )}
        />
    )
}

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
    searchQuery: PropTypes.string,
    initialText: PropTypes.string,
    openAddNewCustomerDialog: PropTypes.func.isRequired,
    isNotFound: PropTypes.bool,
    label: PropTypes.string
};

//  No results found for &nbsp;

function SearchNotFound({ isNotFound, label = "dropoff point", searchQuery = '',
    initialText = "Please enter keywords", openAddNewCustomerDialog, ...other
}) {
    return isNotFound ? (
        <Paper {...other}>
            <Typography gutterBottom align="center" variant="subtitle1">
                Not found
            </Typography>
            <Typography variant="body2" align="center">
                We don’t have this {changeCase.noCase(label)} yet &nbsp;
                <strong>&quot;{searchQuery}&quot;</strong>. Please check typos or click to add {changeCase.noCase(label)} to the database.
            </Typography>
            <Stack direction="row" justifyContent="center" alignItems="center">
                <Button onClick={() => {
                    openAddNewCustomerDialog(searchQuery);
                }}>
                    Add {changeCase.capitalCase(label)}
                </Button>
            </Stack>
        </Paper>
    ) : (
        <Typography variant="body2">
            {initialText}
        </Typography>
    );
}