import { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import Lottie from 'react-lottie';
import { Document, Page, pdfjs } from '@react-pdf/renderer';
// @mui
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { fDateForPersistence } from '../../utils/formatTime';
// import { styled } from '@mui/material/styles';
import {
  Container,
  Card,
  Grid,
  Typography,
  List,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  Stack,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
// @hooks
import useSettings from '../../hooks/useSettings';
import { useDispatch, useSelector } from '../../redux/store';
import useAuth from '../../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import PageComponent from '../../components/Page';
import Iconify from '../../components/Iconify';
import EmptyContent from '../../components/EmptyContent';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { generateAccountStatement } from '../../redux/slices/accountStatement';
import financialStatementIcon from '../../assets/financial_statement.json';

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(8, 2),
}));

export default function AccountStatement() {
  const { themeStretch } = useSettings();

  const defaultOptions = useMemo(
    () => ({
      loop: true,
      autoplay: true,
      animationData: financialStatementIcon,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }),
    []
  );

  const lottieRef = useRef(null);

  const { user } = useAuth();
  const shipperId = user?.shipperId;
  const shipperName = user?.companyName;

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [asToDate, setAsAtDate] = useState(() =>
    queryParams.get('asToDate') ? new Date(queryParams.get('asToDate')) : new Date()
  );
  const dispatch = useDispatch();
  const { isLoading, pdfBlob, statementDocName } = useSelector((state) => state.financialStatement);

  const onGenerateAccountStatement = useCallback(() => {
    dispatch(generateAccountStatement({ asToDate, shipperId, shipperName }));
  }, [shipperId, asToDate, shipperName]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const asToDate = queryParams.get('asToDate') ? new Date(queryParams.get('asToDate')) : new Date();
    dispatch(generateAccountStatement({ asToDate, shipperId, shipperName }));
  }, [shipperId, shipperName]);

  const renderPDF = () => {
    if (pdfBlob) {
      const url = window.URL.createObjectURL(pdfBlob);
      return (
        <embed
          src={URL.createObjectURL(pdfBlob)}
          type="application/pdf"
          width="100%"
          height="100%"
          style={{ flexGrow: 1, border: 'none' }}
        />
      );
    } else {
      return (
        <RootStyle>
          <Lottie options={defaultOptions} height={100} width={100} ref={lottieRef} />
          <Typography variant="h5" sx={{ textTransform: 'capitalize' }} gutterBottom>
            Statement of Account
          </Typography>
        </RootStyle>
      );
    }
  };

  const downloadFinancialStatement = useCallback(() => {
    const url = window.URL.createObjectURL(pdfBlob);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = url;
    link.setAttribute('download', statementDocName);
    link.click();
    link.remove();
  }, [pdfBlob, statementDocName]);

  const onDatePickerChange = useCallback(
    (newValue) => {
      const params = new URLSearchParams(location.search);
      const dateString = fDateForPersistence(newValue);
      params.set('asToDate', dateString);
      navigate(`${location.pathname}?${params.toString()}`, { replace: true });
      setAsAtDate(newValue);
    },
    [setAsAtDate, location]
  );

  return (
    <PageComponent title="Statement of Account">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Statement of Account"
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Statement of Account' }]}
        />
        <Grid container spacing={2}>
          {/* The filter bar for generating the financial statement */}
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 2 }}>
              <Typography variant="h5">Generate Statement of Account</Typography>

              <Grid container sx={{ mt: 2 }} spacing={1}>
                {/* Statement Type */}
                <Grid item xs={6} md={2}>
                  <TextField select fullWidth label="Statement Type" value={1} onChange={(e) => {}} disabled>
                    {[
                      { label: 'Outstanding', id: 1 },
                      { label: 'Activity', id: 2 },
                    ].map((statementType, index) => (
                      <MenuItem
                        value={statementType.id}
                        onClick={() => {}}
                        key={index}
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {statementType.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* As At Date */}
                <Grid item xs={6} md={4}>
                  <DatePicker
                    label="As At"
                    format="dd/MM/yyyy"
                    value={asToDate}
                    onChange={onDatePickerChange}
                    slotProps={{ textField: { fullWidth: true } }}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}
                >
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    loading={isLoading}
                    startIcon={<Iconify icon={'material-symbols:manage-history'} />}
                    onClick={onGenerateAccountStatement}
                  >
                    Regenerate Statement of Account
                  </LoadingButton>

                  <LoadingButton
                    variant="outlined"
                    color="primary"
                    disabled={!pdfBlob}
                    onClick={downloadFinancialStatement}
                    startIcon={<Iconify icon={'eva:download-outline'} />}
                  >
                    Download Statement
                  </LoadingButton>
                  {/* </Stack> */}
                </Grid>
              </Grid>
            </Card>
          </Grid>
          {/* The filter bar for generating the financial statement */}

          {/* View the pdf generated financial statement */}
          <Grid item xs={12} md={12}>
            <Card
              sx={{
                height: 'calc(100vh - 200px)' /* Adjust based on your header + navbar height */,
                overflowY: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {isLoading ? (
                <CircularProgress />
              ) : (
                renderPDF() // Render PDF if blob exists
              )}
            </Card>
          </Grid>
          {/* View the pdf generated financial statement */}
        </Grid>
      </Container>
    </PageComponent>
  );
}
