import Lottie from 'react-lottie';

// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Container, Typography } from '@mui/material';

// hooks
import useResponsive from '../../hooks/useResponsive';

// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';

// sections
import { LoginForm } from '../../sections/auth/login';

// Animation
import truckAnimation from '../../assets/truck.json';

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.senga_light,
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const HeaderStyleSmall = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'center',
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 700,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const defaultOptions = {
  loop: true,
  autoplay: true,
  // here is where we will declare lottie animation
  // "animation" is what we imported before animationData: animation,
  animationData: truckAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

// ----------------------------------------------------------------------
export default function Login() {
  const mdUp = useResponsive('up', 'md');
  const mdDown = useResponsive('down', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        {mdUp && (
          <HeaderStyle>
            <Logo version="beta" />
          </HeaderStyle>
        )}

        {mdDown && (
          <HeaderStyleSmall>
            <Logo version="beta" />
          </HeaderStyleSmall>
        )}

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 20, mb: 2 }}>
              Logistics built for how you work. Welcome to a new world.
            </Typography>
            <Typography variant="h5" sx={{ color: 'text.secondary', px: 5, mt: 1, mb: 1 }}>
              Ship Happy. Ship Senga.
            </Typography>
            <Lottie options={defaultOptions} height={480} width={480} />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                {mdUp && (
                  <>
                    <Typography variant="h4" gutterBottom sx={{ px: 20 }}>
                      Hello Again
                    </Typography>
                    <Typography sx={{ color: 'text.secondary', px: 8 }}>
                      Please enter your details and start shipping
                    </Typography>
                  </>
                )}

                {mdDown && (
                  <>
                    <Typography
                      variant="h4"
                      sx={{ mb: '3em', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      Hello Again
                    </Typography>

                    <Typography sx={{ color: 'text.secondary' }}>
                      Please enter your details and start shipping
                    </Typography>
                  </>
                )}
              </Box>
            </Stack>

            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
