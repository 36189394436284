import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextareaAutosize } from '@mui/material';

// ----------------------------------------------------------------------

RHFTextArea.propTypes = {
  name: PropTypes.string,
  rules: PropTypes.object
};

export default function RHFTextArea({ name, ...other }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextareaAutosize
          {...field}
          fullWidth
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          style={{ fontFamily: 'CircularStd, "pen Sans Semibold", "Arial Unicode MS Bold"' }}
          // style={{ fontFamily: 'CircularStd, "pen Sans Semibold", "Arial Unicode MS Bold"' }}
          error={!!error}
          helperText={error?.message}
          {...other}
        />
      )}
    />
  );
}
