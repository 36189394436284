import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
  rules: PropTypes.object,
  errorShow: PropTypes.bool
};

export default function RHFTextField({ name, errorShow = false, inputRef = '', ...other }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          helperText={error?.message}
          error={errorShow || !!error}
          inputRef={inputRef}
          {...other}
        />
      )}
    />
  );
}
