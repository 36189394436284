// @mui
import { Container } from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

// components
import Page from '../../components/Page';
import { PATH_DASHBOARD } from '../../routes/paths';
import Faqs from '../Faqs';
// ----------------------------------------------------------------------

export default function FAQs() {

  const { themeStretch } = useSettings();


  return (
    <Page title="FAQs">
      <Container maxWidth={themeStretch ? false : 'xl'}>
      <HeaderBreadcrumbs
            heading="FAQs"
            links={[
                { name: 'Dashboard', href: PATH_DASHBOARD.root },{ name: 'FAQs' }
            ]}
            /> 
          <Faqs/>
      </Container>
    </Page>
  );
}
