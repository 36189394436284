import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import orderReducer from './slices/order';
import dnoteReducer from './slices/dnote';
import notificationReducer from './slices/notification';
import decisionReducer from './slices/decision';
import returnsReducer from './slices/returns';
import activeReturnDocumentReducer from './slices/activeReturnDocument';
import returnItemsReducer from './slices/returnItems'
import returnDocumentReferenceStatusTimelineReducer from './slices/returnStatusTimeline'
import shipperAnalyticsReducer from './slices/shipperAnalytics';
import authReducer from './slices/auth';
import shipperUsers from './slices/shipper_users'
import pickupScheduleReducer from './slices/pickupSchedule';
import bannerReducer from './slices/banner';
import guestOrdersReducer from './slices/guestOrders'
import salesRepOrdersReducer from './slices/salesRepOrders'
import modelInferencingReducer from './slices/modelInferencing';
import shipperCustomers from './slices/customers';


// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

// const dnotePersistConfig = {
//   key: 'dnote',
//   storage,
//   keyPrefix: 'redux-',
// };

// const orderPersistConfig = {
//   key: 'order',
//   storage,
//   keyPrefix: 'redux-',
// };

const notificationPersistConfig = {
  key: 'notification',
  storage,
  keyPrefix: 'redux-',
};

const decisionPersistConfig = {
  key: 'decision',
  storage,
  keyPrefix: 'redux-',
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  order: orderReducer,
  dnote: dnoteReducer,
  notification: persistReducer(notificationPersistConfig, notificationReducer),
  // order: persistReducer(orderPersistConfig, orderReducer),
  // dnote: persistReducer(dnotePersistConfig, dnoteReducer),
  product: persistReducer(productPersistConfig, productReducer),
  decision: persistReducer(decisionPersistConfig, decisionReducer),
  // Returns Reducer
  returns: returnsReducer,
  activeReturnDocumentReference: activeReturnDocumentReducer,
  returnItems: returnItemsReducer,
  returnDocumentReferenceStatusTimeline: returnDocumentReferenceStatusTimelineReducer,
  // Customer Tree Reducer
  shipperAnalytics: shipperAnalyticsReducer,
  pickupSchedule: pickupScheduleReducer,
  banner: bannerReducer,
  guestOrders: guestOrdersReducer,
  salesRepOrders: salesRepOrdersReducer,
  modelInferencing: modelInferencingReducer,
  // auth reducer
  auth: authReducer,
  shipperUsers,
  shipperCustomers
});

export { rootPersistConfig, rootReducer };
