import PropTypes from 'prop-types';
import { useState, useMemo } from 'react';
// @mui
import { useSnackbar } from 'notistack';
import { Document as MyDocument, Page, pdfjs } from 'react-pdf';
import { styled } from '@mui/material/styles';
import { Box, Card, Typography, Tooltip, IconButton, CircularProgress, CardContent } from '@mui/material';
// utils
import cssStyles from '../../../../utils/cssStyles';
// components
import Image from '../../../../components/Image';
import { TableNoData } from '../../../../components/table';
import LightboxModal from '../../../../components/LightboxModal';
import Iconify from '../../../../components/Iconify'
import { HOST_API } from '../../../../config';
import useAuth from '../../../../hooks/useAuth'
import moment from 'moment'
import { downloadDocuments } from '../../../../redux/slices/download_documents'

// import "../../dnote/details/DeliveryNoteImages.css";
// ----------------------------------------------------------------------

const CaptionStyle = styled(CardContent)(({ theme }) => ({
    ...cssStyles().bgBlur({ blur: 2, color: theme.palette.grey[900] }),
    bottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    justifyContent: 'space-between',
    color: theme.palette.common.white,
}));

// ----------------------------------------------------------------------

ReturnSupportingDocuments.propTypes = {
    gallery: PropTypes.array.isRequired,
    document: PropTypes.string.isRequired,
    refNo: PropTypes.string.isRequired
};

export default function ReturnSupportingDocuments({ gallery, document, refNo }) {
    const [openLightbox, setOpenLightbox] = useState(false);
    const [selectedImage, setSelectedImage] = useState(0);
    const imagesLightbox = gallery.map((g) => (`${HOST_API}${g?.url}`));
    
    const handleOpenLightbox = (url) => {
        const currentSelectedImage = imagesLightbox.findIndex((imagesLightboxUrl) => imagesLightboxUrl === url);
        setOpenLightbox(true);
        setSelectedImage(currentSelectedImage);
    };

    const isNotFound = useMemo(() => (
        !gallery.length
    ), [gallery]);

    return (
        <Box>
            {/* (${refNo}) */}
            <Typography paragraph variant="h6" sx={{ color: 'text.disabled' }}>
                {`${document} ${(refNo && !!refNo.length) ? `(${refNo})` : ""}`}
            </Typography>

            {/* if the gallery is empty */}
            {
                isNotFound && (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <TableNoData isNotFound={isNotFound}
                            title={`No Authorization Files Uploaded Yet`}
                            description={'Authorization Files will appear here once uploaded'}
                        />
                    </Box>
                )
            }
            {/* If the gallery is not empty */}

            {
                !isNotFound && (
                    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: "40px" }}>
                        {gallery.map((image, index) => (
                            <DocumentsItem key={index} image={{ preview: image }} documentType={document} onOpenLightbox={handleOpenLightbox} />
                        ))}
                    </Box>
                )
            }

            <LightboxModal
                images={imagesLightbox}
                mainSrc={imagesLightbox[selectedImage]}
                photoIndex={selectedImage}
                setPhotoIndex={setSelectedImage}
                isOpen={openLightbox}
                onCloseRequest={() => setOpenLightbox(false)}
                setOpenLightbox={setOpenLightbox}
            />
        </Box>
    );
}

// ----------------------------------------------------------------------

DocumentsItem.propTypes = {
    image: PropTypes.object,
    onOpenLightbox: PropTypes.func,
};

function DocumentsItem({ image, onOpenLightbox, documentType }) {
    // eslint-disable-next-line no-unused-vars
    const [numPages, setNumPages] = useState(1);
    const { preview } = image;
    const [loading, setLoading] = useState(false)
    const auth = useAuth();
    const { user, formatShipper } = auth;
    const { enqueueSnackbar } = useSnackbar();
  
    const handleDownload = async (url) => {
        setLoading(true);
        await downloadDocuments(url, user, documentType, setLoading)
        setLoading(false);
    }

    const previewWithHostUrl = `${HOST_API}${preview?.url}`;

    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
    return (
        <Card
            sx={{
                cursor: 'pointer', position: 'relative',
                p: 0, m: 0, width: 300, height: 300, borderRadius: 1.25, overflow: 'hidden',
                display: 'inline-flex', border: (theme) => `solid 1px ${theme.palette.divider}`
            }}
        >
            {preview?.url.endsWith(".pdf") ?
                <MyDocument file={previewWithHostUrl} onLoadSuccess={({ numPages }) => { setNumPages(numPages) }} className="smallFilesCard">
                    <Page pageNumber={1} className="pdf-page" onClick={() => onOpenLightbox(previewWithHostUrl)}
                        height={200} width={200}
                    />
                </MyDocument>
                :
                <Image alt="gallery image" ratio="1/1" src={previewWithHostUrl} onClick={() => onOpenLightbox(previewWithHostUrl)} />
            }

            <CaptionStyle sx={{ width: '100%', overflow: 'hidden', height: 70 }}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                    <Tooltip title="Download Document">
                        <IconButton onClick={() => handleDownload(previewWithHostUrl)}>
                        {loading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : <Iconify icon={'eva:download-fill'} color="white"/>}
                        </IconButton>
                    </Tooltip>
                </Box>
            </CaptionStyle>
        </Card>
    );
}
