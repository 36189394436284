import _mock from './_mock';

const customersNames = [
    { name:"Majid Nyali", address: "Carrefour City Mall, Nyali, Malindi Rd, Mombasa, Kenya", id: 9, townName: "Mombasa" },
    { name: "Chandarana Diani", address: "Chandarana Supermarkets Ltd, Ukunda Diani Beach rd baharini Plaza, Msambweni, Kenya", id: 14, townName: "Mombasa" },
    { name: "Naivas Embu Pearl Centre", address: "Naivas Pearl Centre(Embu), Pearl centre, Embu, Kenya", id: 17, townName: "Embu" },
    { name: "Soc Maua", address: "Total Makutano Service Station, C91, Meru, Kenya", id: 23, townName: "Mau" },
    { name: "Total Narumoru", address: "Total Naro Moru Service Station, A2, Naro Moru, Kenya", id: 25, townName: "Narumoru" },
    { name: "Naivas Embu", address: "Naivas Supermarket- Embu, Kubukubu Road Moi Stadium, Embu, Kenya", id: 26, townName: "Embu" },
    { name: "Total Mtito Andei", address: "Total Mtito Andei Service Station, Mtito Andei, Kenya", id: 15, townName: "Mtito Andei" },
    { name: "Naivas Westside", address: "Westside Mall, 20100, Kenyatta Avenue, West Road Junction, Nakuru, Kenya", id: 18, townName: "Nakuru" },
    { name: "Total Kerugoya", address: "Total Kerugoya Service Station, C74 Kerugoya Road, Kerugoya, Kenya", id: 22, townName: "Kerugoya" },
    { name: "Naivas Nakuru DownTown", address: "Naivas Supermarket Nakuru Downtown, BENKO FRESH &JUICY, Oginga Odinga Ave, Nakuru, Kenya", id: 13, townName: "Nakuru"  },
    { name: "Naivas Likoni", address: "Naivas Supermarket - Likoni, Near Likoni Ferry, Nyerere Ave, Mombasa, Kenya", id: 44, townName: "Mombasa" },
    { name: "Majid Diani", address: "Carrefour Diani, Centre Point Mall, Diani Beach Road, Ukunda, Kenya", id: 45, townName: "Ukunda" },
    { name: "Total New Mumias", address: "Total New Mumias Service Station, Mumias - Bungoma Rd, Mumias, Kenya", id: 37, townName: "Mumias" },
    { name: "Season Energy", address: "Season Energy Limited, Molem Next Shell, Kenya", id: 33, townName: "Kisumu"  }
] 

export const _customers = customersNames.map((customer, index)=>({
    townName: customer.townName,
    id: _mock.id(index),
    name: customer.name,
    address: customer.address
}));