import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Visibility } from '@mui/icons-material';
import { TableRow, TableCell, Chip, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { DialogAnimate } from '../../../../components/animate';
// utils
import { fDate } from '../../../../utils/formatTime';
// components
import { returnStatusColor } from '../../../../utils/getReturnStatusColour';
import "./ReturnItemRow.css"

// ----------------------------------------------------------------------

ReturnItemRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
};

export default function ReturnItemRow({ row, selected }) {
  const { id, createdAt, quantity, reason, itemDescription, units, status, returnNotes } = row;
  const [open, setOpen] = useState(false);
  const [otherReasons, setOtherReason] = useState("");

  const handleClickOpen = (reason) => {
    setOpen(true);
    setOtherReason(reason)
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell align="left">{`R${id}`}</TableCell>
        <TableCell align="left">{itemDescription}</TableCell>
        <TableCell align="left">{quantity}</TableCell>
        <TableCell align="left">{units}</TableCell>
        <TableCell align="left"><span className='other_reasons_column'>{reason} {reason === 'Other reasons' ? <Visibility onClick={() => {handleClickOpen(returnNotes)}} /> : ""}</span> </TableCell>
        <TableCell align="left">{fDate(createdAt)}</TableCell>
        <TableCell>
          <Chip label={status} color={returnStatusColor(status)} />
        </TableCell>
      </TableRow>
      <DialogAnimate open={open} fullWidth maxWidth="xs" onClose={handleClose} >
        <DialogTitle>Return reason notes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            { otherReasons.length > 0 ? otherReasons.map((otherReason, index) => (
              <div key={index}>{ otherReason.attributes.content }</div>
            )) 
            :
            <div>Oops! We encountered an issue. It seems the return reason might have been deleted. Please check and try again.</div>
          }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </DialogAnimate>
    </>
  );
}
