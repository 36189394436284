import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from "react-hook-form";
// import parse from 'autosuggest-highlight/parse';
// import match from 'autosuggest-highlight/match';
import { deserialize } from "deserialize-json-api";
import { useNavigate } from 'react-router-dom';

// @mui
import { Autocomplete, InputAdornment, Popper, Typography, Stack, Paper, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
// @mui
// utils
import axios from '../../../../../../utils/axios';
// utils
// components
import Iconify from '../../../../../../components/Iconify';
import InputStyle from '../../../../../../components/InputStyle';
import { SkeletonCustomer } from '../../../../../../components/skeleton';
import { PATH_DASHBOARD } from '../../../../../../routes/paths';
// components
// hooks
import useAuth from "../../../../../../hooks/useAuth";

const PopperStyle = styled((props) => <Popper placement="bottom-start" {...props} />)({
    width: '280px !important',
});

let timeout;

OrderSearch.propTypes = {
    label: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    focusstretch: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    field: PropTypes.object,
    error: PropTypes.object
}
export function OrderSearch({
    label, width = 230, focusstretch = 0, field, error
}) {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const auth = useAuth();
    const { user } = auth;

    useEffect(() => {
        if (field.value) {
            setSearchResults([field.value]);
        }
    }, [field, field.value]);

    const handleChangeSearch = async (value) => {
        try {
            const shipperId = user?.shipperId;
            setSearchQuery(value.trim());
            if (value && value.trim().length > 0) {
                const searchVal = value.trim();
                const pathName = `/api/v1/orders/${shipperId}/search?search_val=${searchVal}&page=1&per_page=70`;
                clearTimeout(timeout);
                setIsLoading(true);
                timeout = setTimeout(async () => {
                    const response = await axios.get(pathName);
                    const result = deserialize(response.data);
                    if (result.data.length > 0) {
                        setSearchResults(
                            result.data.map((order) => (
                                {
                                    invoice_no: order.invoice_no,
                                    customer_name: order.customer_name,
                                    customer_id: order.customer_id,
                                    customer_address: order.customer_address,
                                    dnote_number: order.dnote_number,
                                    id: order.id
                                }
                            ))
                        )
                    } else {
                        setSearchResults([]);
                    }
                    setIsLoading(false);
                })
            } else {
                setSearchResults([]);
                setIsLoading(false);
            }
        } catch (err) {
            console.error(err);
        }
    }

    const isNotFound = searchResults.length < 1 && !isLoading && searchQuery.trim().length >= 1;

    return (
        <Autocomplete
            {...field}
            autoHighlight
            popupIcon={null}
            PopperComponent={PopperStyle}
            options={searchResults}
            filterOptions={(x) => x}
            onInputChange={(event, value) => handleChangeSearch(value)}
            getOptionLabel={(order) => `${order?.customer_name} Order ID - ${order?.id}` || ""}
            noOptionsText={
                <SearchNotFound searchQuery={searchQuery} initialText={"Search for Previous Deliveries"} isNotFound={isNotFound} />
            }
            isOptionEqualToValue={(option, value) => (option.id.toString() === value.id.toString())}
            onChange={(event, newValue) => field.onChange(newValue)}
            renderInput={(params) => (
                <InputStyle
                    {...params}
                    stretchStart={width}
                    focusstretch={focusstretch}
                    label={label}
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
                            </InputAdornment>
                        ),
                        size: "small"
                    }}
                />
            )}
            renderOption={(props, order) => {
                const { customer_name: customerName, dnote_number: dnoteNumber, invoice_no: invoiceNo, id: orderId } = order;
                // const matches = match(name, inputValue);
                // const parts = parse(name, matches);
                return (
                    <li {...props} key={orderId}>
                        {
                            isLoading ? (
                                [...Array(5)].map((row, index) => (
                                    <SkeletonCustomer key={index} />
                                ))
                            ) : (
                                <Stack direction="column">
                                    <Stack direction="row" spacing={0.1}>
                                        <Typography
                                            component="span"
                                            variant="subtitle2"
                                            sx={{ pr: 0.07 }}
                                        >
                                            {customerName}
                                        </Typography>
                                    </Stack>

                                    <Typography variant="caption" sx={{ color: 'text.secondary', my: 0.5, fontWeight: 'fontWeightMedium' }}>
                                        Order ID - {orderId}
                                    </Typography>

                                    <Typography variant="caption" sx={{ color: 'text.secondary', my: 0.5, fontWeight: 'fontWeightMedium' }}>
                                        Dnote Number - {dnoteNumber}
                                    </Typography>
                                    <Typography variant="caption" sx={{ color: 'text.secondary', my: 0.5, fontWeight: 'fontWeightMedium' }}>
                                        Invoice Number - {invoiceNo}
                                    </Typography>
                                </Stack>
                            )
                        }
                    </li>
                )
            }}
        />
    )
}
RHFOrderSearch.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    focusstretch: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
}

export function RHFOrderSearch({
    label, width = 230, focusstretch = 0, name, ...other
}) {
    const methods = useFormContext();
    const { control } = methods;
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <OrderSearch width={width} focusstretch={focusstretch}
                    label={label} field={field} error={error} {...other}
                />
            )}
        />
    )
}


SearchNotFound.propTypes = {
    searchQuery: PropTypes.string,
    initialText: PropTypes.string,
    openAddNewCustomerDialog: PropTypes.func.isRequired,
    isNotFound: PropTypes.bool
};

//  No results found for &nbsp;

function SearchNotFound({ isNotFound, searchQuery = '', initialText = "Please enter keywords", ...other }) {
    const navigate = useNavigate();
    return isNotFound ? (
        <Paper {...other}>
            <Typography gutterBottom align="center" variant="subtitle1">
                Not found
            </Typography>
            <Typography variant="body2" align="center">
                We don’t have this order yet &nbsp;
                <strong>&quot;{searchQuery}&quot;</strong>. Please check typos.
            </Typography>
            <Stack direction="row" justifyContent="center" alignItems="center">
                <Button onClick={() => {
                    navigate(PATH_DASHBOARD.tms.shippers.schedulePickup('formView'))
                }}>
                    Schedule a Pickup
                </Button>
            </Stack>
        </Paper>
    ) : (
        <Typography variant="body2">
            {initialText}
        </Typography>
    );
}