import PropTypes from 'prop-types';

// @mui
import { Grid, Button } from '@mui/material';
// @mui
// hooks
// import useResponsive from '../../../../../hooks/useResponsive';
import useAuth from '../../../../../hooks/useAuth';
// hooks
// component
import { RHFTextField } from "../../../../../components/hook-form";
import Iconify from '../../../../../components/Iconify';
import { RHFAutocompleteFreeSolo } from '../../../../../components/hook-form/RHFAutocompleteFreeSolo';
import { RHFInventoriesSolo } from './hook-form/RHFInventories'
import { useSelector } from '../../../../../redux/store';
// component

const units = [
    { id: "pcs", label: "Pcs" },
    { id: "Carton", label: "Carton" },
    { id: "Bale", label: "Bale" },
    { id: "Bag", label: "Bag" },
    { id: "Kgs", label: "Kgs" }
]
ScheduleReturnItem.propTypes = {
    returnIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    returnItemIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleRemoveReturnItems: PropTypes.func.isRequired,
    returnItemsFields: PropTypes.array.isRequired,
    handleAddReturnItem: PropTypes.func.isRequired
}
export default function ScheduleReturnItem({ returnIndex, returnItemIndex,
    handleRemoveReturnItems, returnItemsFields, handleAddReturnItem
}) {
    const { user } = useAuth();
    const shipperId = user?.shipperId;
    // const isMobile = useResponsive('down', 'sm');

    const { returnReasons } = useSelector((state) => state.returns);

    const isLastReturnItem = returnItemsFields.length === (returnItemIndex + 1);

    return (
        <Grid item xs={12}>
            <Grid container sx={{ px: 3, py: 2 }} spacing={2}>
                {/* Item Description */}
                <Grid item xs={12} sm={4}>
                    <RHFInventoriesSolo
                        name={`returns[${returnIndex}].returns_items[${returnItemIndex}].item_description`}
                        label="Item Description"
                        width={'100%'}
                        focusstretch={0}
                        shipperId={shipperId}
                    />
                </Grid>
                {/* Item Description */}

                {/* Quantity */}
                <Grid item xs={6} sm={2}>
                    <RHFTextField
                        label="Quantity"
                        size="small"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        name={`returns[${returnIndex}].returns_items[${returnItemIndex}].quantity`}
                        id="quantityInput"
                    />
                </Grid>
                {/* Quantity */}

                {/* Units */}
                <Grid item xs={6} sm={2}>
                    <RHFAutocompleteFreeSolo
                        label="Units"
                        name={`returns[${returnIndex}].returns_items[${returnItemIndex}].units`}
                        width={'100%'}
                        focusstretch={0}
                        options={units}
                    />
                </Grid>
                {/* Units */}

                {/* Return Reason */}
                <Grid item xs={6} sm={4}>
                    <RHFAutocompleteFreeSolo
                        name={`returns[${returnIndex}].returns_items[${returnItemIndex}].return_reason`}
                        label="Return Reason"
                        width={'100%'}
                        focusstretch={0}
                        options={returnReasons}
                    />
                </Grid>
                {/* Return Reason */}

                {/* Delete button */}
                {/* sx={{ pt: '6px', pl: '3px', pr: '4px' }} */}
                <Grid item xs={12} container justifyContent={isLastReturnItem ? "space-between" : "flex-end"}>

                    {/* Add Return Item */}
                    {
                        isLastReturnItem && (
                            <Grid item>
                                <Button
                                    startIcon={<Iconify icon="eva:plus-fill" />}
                                    onClick={() => handleAddReturnItem()}
                                    id="addOrderlineButton"
                                >
                                    Add Return Item
                                </Button>
                            </Grid>
                        )
                    }
                    {/* Add Return Item */}

                    {/* Remove Return Item */}
                    <Grid item>
                        <Button
                            variant="text"
                            startIcon={<Iconify icon={'eva:trash-2-outline'} />}
                            onClick={() => {
                                handleRemoveReturnItems(returnItemIndex);
                            }}
                            color="error"
                            disabled={returnItemsFields.length <= 1}
                            id="removeReturnItemButton"
                        >
                            Remove Return Item
                        </Button>
                    </Grid>
                    {/* Remove Return Item */}

                </Grid>
            </Grid>
        </Grid>
    )
}