
import { useState, useEffect } from 'react';

import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import { useDispatch, useSelector } from '../../redux/store';
import { getBannerMessages } from '../../redux/slices/banner';

// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';

// config
import { HEADER, NAVBAR } from '../../config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';

import analyticsGa from '../../utils/analytics_ga';
import useAuth from '../../hooks/useAuth';
import { urlB64ToUint8Array } from '../../utils/urlB64ToUint8Array';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

async function subscribePush(serviceWorkerReg) {
  let subscription = await serviceWorkerReg.pushManager.getSubscription();
  if (subscription === null) {
    subscription = await serviceWorkerReg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlB64ToUint8Array('BO0TfNg4YtiXSobhzaWp5axyxt3K11VNPsC5fOko2SZ__qwhjXsz1WKKYPBmnBcQnbaNmoDM3BeQDmqW8E1stNw='),
    });
  }
  return subscription;
}

function arrayBufferToHexString(buffer) {
  const byteArray = new Uint8Array(buffer);
  return btoa(String.fromCharCode.apply(null, byteArray));
}

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const dispatch = useDispatch();

  const { collapseClick, isCollapse } = useCollapseDrawer();


  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const { user } = useAuth();

  const { bannerMsgs } = useSelector((state) => state.banner)

  const [msgIndex, setMsgIndex] = useState(0)

  useEffect(() => {
    // Track the initial pageview
    analyticsGa.page();

    // Start a timer when the component mounts
    const startTime = new Date().getTime();

    // Define a function to track the session length
    return () => {
      const endTime = new Date().getTime();
      const sessionLengthInSeconds = Math.floor((endTime - startTime) / 1000);

      // Send the session length event to Google Analytics
      analyticsGa.track('session_length', {
        sessionLength: sessionLengthInSeconds,
        userEmail: user?.email || '',
        shipperId: user?.shipperId || '',
        shipperNo: user?.shipperNo || '',
      });
    };
  }, [user]);

  useEffect(() => {
    window.navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
      subscribePush(serviceWorkerRegistration).then((subscription) => {
        const path = "/api/v1/notification_subscriptions";
        const p256dh = arrayBufferToHexString(subscription.getKey("p256dh"));
        const auth = arrayBufferToHexString(subscription.getKey("auth"));
        const {expirationTime} = subscription;
        const payload = {
          endpoint: subscription.endpoint,
          expirationTime: subscription.expirationTime,
          p256dh, auth
        }
        axios.post(path, payload).catch((error) => {
          console.error(error);
        });
      });
    });
  }, []);



  useEffect(() => {
    dispatch(getBannerMessages(user.shipperId));
    const interval = setInterval(() => {
      dispatch(getBannerMessages(user.shipperId));
    }, 60000);
    return () => clearInterval(interval);
  }, [dispatch, user]);

  useEffect(() => {
    const shuffleBannerMsg = () => {
      if (bannerMsgs.length > 0) {
        setMsgIndex(currentIndex => (currentIndex + 1) % bannerMsgs.length)
      }
    };
    // shuffleBannerMsg();
    const intervalID = setInterval(shuffleBannerMsg, 30000);
    return () => clearInterval(intervalID);
  }, [bannerMsgs])


  const verticalLayout = themeLayout === 'vertical';

  if (verticalLayout) {
    return (
      <>
        {/* <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} /> */}

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >

      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} bannerMessage={bannerMsgs.length > 0 ? bannerMsgs[msgIndex].message : ''} />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
    </Box>
  );
}