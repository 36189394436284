import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import ReturnItemView from '../pages/dashboard/ReturnItemView';
import ReturnNote from '../sections/@dashboard/tms/returns/details';
import ScheduleReturnsEdit from '../pages/dashboard/ScheduleReturnsEdit';
import FAQs from '../pages/dashboard/FAQs';


// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'login-video', element: <LoginVideo/> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'request-sent', element: <RequestSent /> },

      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },
        { path: 'shipper/new', element: <OnboardShipper /> },
        { path: 'schedule-pickup/:view', element: <SchedulePickup /> },
        { path: 'schedule-pickup/:orderId/edit', element: <SchedulePickupEdit /> },
        { path: 'invoicing', element: <Invoicing /> },
        { path: 'worksheet/:pickupDate', element: <Worksheet /> },
        { path: 'pickup-requests/:view', element: <PickupRequests /> },
        { path: 'customers/list', element: <ShipperCustomers /> },
        {
          path: 'returns',
          children: [
            { element: <Returns />, index: true },
            { path: 'deliveries-tracking', element: <ReturnDeliveries /> },
            { path: 'schedule', element: <ScheduleReturnsPage /> },
            { path: 'edit', element: <ScheduleReturnsEdit /> },
            { path: 'deliveries-management', element: <ReturnDeliveriesManagement/> },
            // { path: 'waybills', element: <Waybills /> },
            { path: 'items', element: <ReturnItems /> },
            { path: ':id', element: <ReturnNoteDetails /> },
            { path: ':id/view', element: <ReturnItemView /> },
            { path: ':id/note', element: <ReturnNote /> },
          ],
        },
        { path: 'packaging/new', element: <PackagingView /> },
        { path: 'billing-info', element: <BillingInfo /> },
        { path: 'invoice-list', element: <InvoiceList /> },
        { path: 'delivery-notes', element: <DeliveryNotes /> },
        { path: 'generate-delivery-note/:orderId', element: <GenerateDeliveryNotes /> },
        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductEdit /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },
        {
          path: 'dnote',
          children: [
            { path: ':id', element: <DeliveryNoteDetails /> },
            { path: ':id/edit', element: <DnoteEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        {
          path: 'deliveries-tracking',
          element: <DeliveriesTracking />,
        },
        {
          path: 'delivery-tracking',
          children: [{ path: ':id', element: <DeliveryTracking /> }],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <ShipperProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':id/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
            { path: 'settings', element: <UserSettings /> },
          ],
        },
        {
          path: 'model-inferencing',
          element: <ModelInferencing />,
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        {
          path: 'customer-graph',
          element: <CustomerGraph />,
        },
        {
          path: 'feedback',
          element: <Feedback />,
        },
        {
          path: 'faqs',
          element: <FAQs/>,
        },
        {
          path: 'pickup-schedule',
          element: <PickupSchedule />,
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      // element: <MainLayout />,
      children: [
        // { element: <HomePage />, index: true },
        {
          index: true,
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'home', element: <HomePage /> },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'shipper-welcome', element: <ShipperWelcome /> },
        { path: 'acquisition', element: <Acquisition /> },
        { path: 'track-deliveries/:token', element: <GuestOrdersDeliveryView /> },
        { path: 'track-delivery/:token', element: < GuestOrderDeliveryView /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Transportation Management System

// Dashboard
const Invoicing = Loadable(lazy(() => import('../pages/dashboard/Invoicing')));
const Returns = Loadable(lazy(() => import('../pages/dashboard/Returns')));
const ShipperCustomers = Loadable(lazy(() => import('../pages/dashboard/shipperCustomers/shipperCustomers')));
const ReturnDeliveries = Loadable(lazy(() => import('../pages/dashboard/ReturnDeliveries')));
const ReturnDeliveriesManagement = Loadable(lazy(()=> import('../pages/dashboard/ReturnDeliveriesManagement')));
const ScheduleReturnsPage = Loadable(lazy(() => import('../pages/dashboard/ScheduleReturnsPage')));
const ReturnNoteDetails = Loadable(lazy(() => import('../pages/dashboard/ReturnNoteDetails')));
const ReturnItems = Loadable(lazy(() => import('../pages/dashboard/ReturnItems')));
const OnboardShipper = Loadable(lazy(() => import('../pages/dashboard/OnboardShipper')));
const SchedulePickup = Loadable(lazy(() => import('../pages/dashboard/SchedulePickup')));
const SchedulePickupEdit = Loadable(lazy(() => import('../pages/dashboard/SchedulePickupEdit')));
const PackagingView = Loadable(lazy(() => import('../pages/dashboard/Packaging')));
const BillingInfo = Loadable(lazy(() => import('../pages/dashboard/BillingInfo')));
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const DeliveriesTracking = Loadable(lazy(() => import('../pages/dashboard/DeliveriesTracking')));
const DeliveryTracking = Loadable(lazy(() => import('../pages/dashboard/DeliveryTracking')));
const DeliveryNotes = Loadable(lazy(() => import('../pages/dashboard/DeliveryNotes')));
const GenerateDeliveryNotes = Loadable(lazy(() => import('../pages/dashboard/GenerateDnote')));
const CustomerGraph = Loadable(lazy(() => import('../pages/dashboard/CustomerGraph')));
const Feedback = Loadable(lazy(() => import('../pages/dashboard/Feedback')));
const Worksheet = Loadable(lazy(() => import('../pages/dashboard/Worksheet')));
const PickupRequests = Loadable(lazy(() => import('../pages/dashboard/PickupRequests')));

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const LoginVideo = Loadable(lazy(() => import('../pages/auth/LoginVideo')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const RequestSent = Loadable(lazy(() => import('../pages/auth/RequestSent')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceProductEdit = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductEdit')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// INVOICE
// const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// DELIVERY NOTE
const DeliveryNoteDetails = Loadable(lazy(() => import('../pages/dashboard/DeliveryNoteDetails')));
const DnoteEdit = Loadable(lazy(() => import('../pages/dashboard/DnoteEdit')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// USER
// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const UserSettings = Loadable(lazy(() => import('../pages/dashboard/UserSettings')));
const ShipperProfile = Loadable(lazy(() => import('../pages/dashboard/ShipperProfile')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const ShipperWelcome = Loadable(lazy(() => import('../pages/ShipperWelcome')));
const Acquisition = Loadable(lazy(() => import('../pages/onboarding/Acquisition')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
const ModelInferencing = Loadable(lazy(() => import('../pages/dashboard/ModelInferencing')))
const PickupSchedule = Loadable(lazy(() => import('../pages/dashboard/PickupSchedule')))
const GuestOrdersDeliveryView = Loadable(lazy(() => import('../pages/dashboard/GuestOrdersDeliveryView')))
const GuestOrderDeliveryView = Loadable(lazy(() => import('../pages/dashboard/GuestOrderDeliveryView')))
