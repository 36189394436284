import _mock from './_mock';

const phoneNos = [
  "(+254) 704-310-871",
  "(+254) 714-941-879",
  "(+254) 723-102-818",
  "(+254) 710-901-810",
  "(+254) 790-607-879",
  "(+254) 789-609-889"
]

export const _contactPeoples = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  email: _mock.email(index),
  phone: phoneNos[index]
}));