import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Grid,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
  Chip,
} from '@mui/material';

import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
// utils
import { fDate } from '../../../../../utils/formatTime';

// components
import Label from '../../../../../components/Label';
import Image from '../../../../../components/Image';
import Scrollbar from '../../../../../components/Scrollbar';
//
// import ++ from './ReturnNoteToolbar';
import ReturnNoteImages from './ReturnNoteImages';
import { returnStatusColor } from '../../../../../utils/getReturnStatusColour';



// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

ReturnNote.propTypes = {
  rnote: PropTypes.object,
};

// const From = {
//   name: 'Tropical Heat Limited',
//   address: 'P.O. Box 44804–00100, Tropical Heat Way, Redhill, Limuru, Kenya',
//   phone: ' +254 717 206 010',
// };

export default function ReturnNote() {
  const theme = useTheme();

  const { returnItems } = useSelector(state => state.returnItems)

  const { activeReturnDocumentReference } = useSelector(state => state.activeReturnDocumentReference)  
  
  // const { status, deliveryDate, images, outlets, rnoteNo } = rnote;

  return (
    <>
      {/* <ReturnNoteToolbar dnote={dnote} /> */}

      <Card sx={{ pt: 5, px: 5 }}>
        <Grid container>
          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Image disabledEffect visibleByDefault alt="logo" src="/logo/logo_full.png" sx={{ maxWidth: 120 }} />
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: { sm: 'right' } }}>
              {activeReturnDocumentReference?.status.map((s) => (
                <Chip key={s} label={s} color={returnStatusColor(s)} />
              ))}
              {/* <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color={
                  (status === 'Approved' && 'success') ||
                  (status === 'rejected' && 'warning') ||
                  (status === 'Completed' && 'info') ||
                  'default'
                }
                sx={{ textTransform: 'uppercase', mb: 1 }}
              >
                {activeReturnDocumentReference?.status}
              </Label> */}

              <Typography variant="h6">{`RNOTE-${activeReturnDocumentReference?.id}`}</Typography>
            </Box>
          </Grid>

          {/* <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Pickup from
            </Typography>
            <Typography variant="body2">{From.name}</Typography>
            <Typography variant="body2">{From.address}</Typography>
            <Typography variant="body2">Phone: {From.phone}</Typography>
          </Grid> */}

          {/* <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Delivered to
            </Typography>
            <Typography variant="body2">{customer.name}</Typography>
            <Typography variant="body2">{customer.address}</Typography>
          </Grid> */}

          {/* <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Pickup Date
            </Typography>
            <Typography variant="body2">{fDate(pickupDate)}</Typography>
          </Grid> */}

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Delivery date
            </Typography>
            <Typography variant="body2">{fDate(activeReturnDocumentReference?.returnDate)}</Typography>
          </Grid>

          {/* <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              REQ./L.P.O NO
            </Typography>
            <Typography variant="body2">{lpoNo}</Typography>
          </Grid> */}

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              R/NOTE NO
            </Typography>
            <Typography variant="body2">RD-{activeReturnDocumentReference?.id}</Typography>
          </Grid>
        </Grid>

        {/* {outlets.map((outlet, index) => (
          <div key={index}> */}
        <Grid item xs={12} sm={6} sx={{ mb: 5, mt: 5 }}>
          <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
            OUTLET/CUSTOMER:
          </Typography>
          <Typography variant="body1">{activeReturnDocumentReference?.customerName}</Typography>
          <Typography variant="body1">{activeReturnDocumentReference?.townName}</Typography>
        </Grid>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 960 }}>
            <Table>
              <TableHead
                sx={{
                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                  '& th': { backgroundColor: 'transparent' },
                }}
              >
                <TableRow>
                  <TableCell width={40}>#</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">Qty</TableCell>
                  <TableCell align="right">Units</TableCell>
                  <TableCell align="right">Reason</TableCell>
                  <TableCell align="right">Date Reported</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {returnItems.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell align="left">
                      <Box sx={{ maxWidth: 560 }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                          {row.itemDescription}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">{row.quantity}</TableCell>
                    <TableCell align="right">{row.units}</TableCell>
                    <TableCell align="right">{row.reason}</TableCell>
                    <TableCell align="right">{fDate(row.createdAt)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Divider sx={{ mt: 5 }} />
        {/* </div>
        ))} */}

        {/*

        {!hasDnoteItems && (
          
        )} */}
        {/* <Grid container>
          <Grid item xs={12} md={12}>
            <ReturnNoteImages gallery={images} />,
          </Grid>
        </Grid> */}

        <Grid container>
          <Grid item xs={12} md={9} sx={{ py: 3 }}>
            <Typography variant="subtitle2">NOTES</Typography>
            <Typography variant="body2">Thank you for shipping with Senga. We appreciate your business.</Typography>
          </Grid>
          <Grid item xs={12} md={3} sx={{ py: 3, textAlign: 'right' }}>
            <Typography variant="subtitle2">Have a Question?</Typography>
            <Typography variant="body2">ops@senga.co</Typography>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
