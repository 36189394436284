import { createSlice } from '@reduxjs/toolkit';
import { deserialize } from 'deserialize-json-api';
import dayjs from 'dayjs';
// utils
import axios from '../../utils/axios';
import { HOST_API } from '../../config';
import { dispatch } from '../store';


const initialState = {
  isLoading: false,
  error: null,
  isUdpated: false,
  viewType: 'cardView',
  sortBy: 'newest',
  inferenceResultsData: [],
  singleInferenceResult: [],
};

// The is Updated field is only used to force the table to refresh when a dnote is updated,
// when making server calls for getting dnotes, this field will not be required since the data will be fetched from the server

const slice = createSlice({
  name: 'modelInferencing',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET INFERENCING RESULTS
    getModelInferencesSuccess(state, action) {
      state.isLoading = false;
      state.inferenceResultsData = action.payload;
    },

    // GET INFERENCING RESULT
    getInferecingResultSuccess(state, action) {
      state.isLoading = false;
      state.singleInferenceResult = action.payload;
    },
    // SORT BY INFERENCIN RESULTS
    sortByInferencingResults(state, action) {
      state.sortBy = action.payload;
    },
    changeViewType(state, action) {
      state.viewType = action.payload.viewType;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent, sortByDnotes, changeViewType, } = slice.actions;

export function getModelInferences({shipperId, filterInferencingDateFrom, filterInferencingDateEnd, searchVal}) {
  return async () => {
    try {
      const fromDate = dayjs(filterInferencingDateFrom).format('YYYY-MM-DD');
      const endDate = dayjs(filterInferencingDateEnd).format('YYYY-MM-DD');
      let pathName = `/shipper_app/api/v1/model_inferences/${shipperId}`
      if (searchVal && searchVal.trim().length >= 1) {
        pathName = `${pathName}?search_val=${searchVal}`;
      } else {
        pathName = `${pathName}?start_date=${fromDate}&end_date=${endDate}`;
      }

      dispatch(slice.actions.startLoading());
      const response = await axios.get(pathName);
      const modelInferences = deserialize(response.data, { transformKeys: "camelCase" });
      const formattedModelInferences = modelInferences.data.map((modelInference) => ({
          id: modelInference.id,  
          modelResults: modelInference.result,
          transformedResults: modelInference.transformedResult,
          image: {
            preview: `${HOST_API}${modelInference.inferencingImages}`,
            path: "Inferencing Results",
        },  
      }));
      dispatch(slice.actions.getModelInferencesSuccess(formattedModelInferences));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function inferenceUploadedDocuments(uploadedDocuments, shipperId) {
  // eslint-disable-next-line consistent-return
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const path = `shipper_app/api/v1/model_inferencing/${shipperId}`;   
      const formDataUpload = new FormData();
      uploadedDocuments.forEach((uploadedDoc) =>  formDataUpload.append('uploaded_docs[]', uploadedDoc) )
      dispatch(slice.actions.startLoading());
      const response = await axios.post(path, formDataUpload, { headers: { 'content-type': 'multipart/form-data' } });
      dispatch(slice.actions.stopLoading());
      return response
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));    
      throw new Error(); 
    }
  };
}
