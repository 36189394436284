import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
// useCallback, useEffect,
import { useMemo, useRef } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
//
// import Image from './Image';
// Animation
import smilingOrange from '../assets/hi-orange.json';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(8, 2),
}));

// ----------------------------------------------------------------------

EmptyContent.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string,
  description: PropTypes.node,
};

export default function EmptyContent({ title, description, ...other }) {
  const defaultOptions = useMemo(() => ({
    loop: true,
    autoplay: true,
    animationData: smilingOrange,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }), []);

  const lottieRef = useRef(null);


  return (
    <RootStyle {...other}>
      <Lottie options={defaultOptions} height={160} width={160} ref={lottieRef} />

      <Typography variant="h5" sx={{ textTransform: 'capitalize' }} gutterBottom>
        {title}
      </Typography>

      {description && (
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {description}
        </Typography>
      )}
    </RootStyle>
  );
}
