import PropTypes from 'prop-types';
import { Stack, Grid, TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';
import useTable, { emptyRows } from '../../../../hooks/useTable';
// components
import Scrollbar from '../../../../components/Scrollbar';
import { TableHeadCustom, TableNoData, TableEmptyRows } from '../../../../components/table';
// components
OrderDetails.propTypes = {
  order: PropTypes.object,
};
export default function OrderDetails({ order }) {
  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} sm={12} md={12}>
        <Stack direction="column" spacing={2} sx={{ pt: 2 }}>
          <OrderDetailsTable order={order} />
        </Stack>
      </Grid>
    </Grid>
  );
}

const TABLE_HEAD = [
  { id: 'packageName', label: 'Item Name', align: 'left' },
  { id: 'quantity', label: 'Quantity', align: 'left' },
  { id: 'weight', label: 'Total Weight', align: 'left' },
];

// eslint-disable-next-line react/prop-types
function OrderDetailsTable({ order: orderData }) {
  // eslint-disable-next-line react/prop-types
  const orderlineItems = orderData?.orderlineItems || [];
  //
  const { dense, page, order, orderBy, rowsPerPage, selected, onSelectRow, onSelectAllRows, onSort } = useTable({
    defaultOrderBy: 'date',
    defaultRowPerPage: 25,
  });

  const denseHeight = dense ? 56 : 76;
  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
        <Table size={dense ? 'small' : 'medium'}>
          <TableHeadCustom
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={orderlineItems.length}
            numSelected={selected.length}
            onSort={onSort}
            onSelectAllRows={(checked) => {
              onSelectAllRows(
                checked,
                /** Fix */
                orderlineItems.map((row) => row.orderId)
              );
            }}
          />

          <TableBody>
            {orderlineItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <OrderLineTableRow
                key={index}
                row={row}
                selected={selected.includes(row.orderId)}
                onSelectRow={() => onSelectRow(row.orderId)}
                // onViewRow={() => handleViewRow(row.orderId)}
              />
            ))}

            <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, orderlineItems.length)} />

            <TableNoData isNotFound={false} />
          </TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}

function OrderLineTableRow({ row }) {
  // eslint-disable-next-line react/prop-types
  const { package_name: packageName, quantity, total_weight: totalWeight, weight_unit: weightUnit } = row;
  return (
    <TableRow hover>
      {<TableCell align="left">{packageName}</TableCell>}
      <TableCell align="left">{quantity}</TableCell>
      <TableCell align="left">
        {totalWeight} {weightUnit}
      </TableCell>
    </TableRow>
  );
}
